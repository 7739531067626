import React, { useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { Link } from "react-router-dom";
import Dashboard from "../../../../Components/Icons/Dasboard";
import { useFormik } from "formik";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import * as yup from "yup";
import { NuInput, NuMobileNumber, NuTextArea, NuUpload } from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import useFetchMetaStar from "../../../../Components/Hooks/useFetchMetaStar";
import { TextMediumBase, TextMediumSM } from "../../../../Components/Text/MediumText";
import Attachement from "../../../../Components/Utils/CustomAttrachmentupload";
import { useToast } from "../../../../Components/Others/ToastServices";
import { CountryMIN } from "../../../../assets/CountryMini";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];

function CreateLeads() {
  const {value:Source ,getData:refSource} = useFetchMataData(`/dropdown/dropdown-details/`,'lead_Source')
  const {value:Industry ,getData:refIndustry} = useFetchMataData(`/dropdown/dropdown-details/`,'Industry_leads')
  const {value:CoversionProbablity ,getData:refCoversionProbablity} = useFetchMataData(`/dropdown/dropdown-details/`,'Lead_CoversionProbablity')
  const {value:CompanySize ,getData:refCompanySize} = useFetchMataData(`/dropdown/dropdown-details/`,'CompanySize_leads')
  const {value:LeadsRatings} = useFetchMetaStar(`/dropdown/dropdown-details/`,'leadsRating')
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState([]);
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  
  const [createNew, setCreateNew] = useState({
    industry: "",
    companyAddress: "",
    companySize: "",
    contactInfo: "",
    conversionProbabilities: "",
    description: "",
    leadOwner: "",
    leadSource: "",
    name: "",
    ratings: "",
    comments: "",
    country:"",
    city:"",
    postalCode:"",
    state:"",
    address1:"",
    address2:"",
    email: "",
    mobile: "",
    countryCode: "",
    contactPersonName:""
    
  });

  const [selectedFile, setSelectedFile] = useState(null);

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        // let fileExtention = fileName.split(".").reverse()[0];
        // if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        // } else {
        //   let x = URL.createObjectURL(item);
        //   tempArr.push(x);
        // }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      name: yup.string().required('Name is required!'),
      industry: yup.string().required('Industry is required!'),
      email: yup.string().required('Email is required!'),
      mobile: yup.string().required('Mobile is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      setLoading(true); 
      let formData = new FormData();
      formData.append("leadDetails", JSON.stringify(value));
      // formData.append("attachments", image.raw);
      if(selectedFile !== null){
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      }else{
        formData.append("attachments",[])
      } 
      addFormData("/leads/new-lead", formData, resetForm);
      setLoading(false)
    },
  });



  const removeImages = (value) => {
    console.log("value", value);
    let List = selectedFile.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFile([...List]);
    let tempArr = []
    List.map((item) =>{
      let fileName = item?.name
      // console.log('fname',fileName);
      // let fileExtention =  fileName.split(".").reverse()[0]
      // if(filetype?.includes(fileExtention)){
        tempArr.push(fileName)
      // }else{
      //   let x = URL.createObjectURL(item)
      //   tempArr.push(x)
      // }
    })
    setImage(tempArr)
  };
  return (
    <div className="w-full h-full px-4 py-2 ">
      <div className="flex justify-between mb-4">
        <Breadcrumb
          items={[
            {
              name: "Lead",
              link: "/marketing/leads"

            },
            {
              name: "Create Lead"
            }
          ]}
        />
        <BackButton />
      </div>
      <div className="w-full h-[calc(100vh-140px)] overflow-y-auto">

      <form onSubmit={formik.handleSubmit}>

      <div className="w-full mx-auto pb-4 bg-white rounded-md  ">
        <div className=" w-full h-full flex justify-start content-start flex-wrap  mb-4">
          
          <div className="w-full">
<p className='py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
Leads Detail :
</p>
</div>

            <NuInput
              type="text"
              label="Company Name"
              placeholder="Company Name"
              formik={formik}
              name="name"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <DropDownAddMetaData
                label="Industry"
                placeholder="Choose"
                Options={Industry}
                formik={formik}
                name="industry"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refIndustry}
                displayProperty={"Industry"}
                propertyName={"Industry_leads"}
                isRequired={true}
              />
             <DropDownAddMetaData
                label="Company Size"
                placeholder="Choose"
                Options={CompanySize}
                formik={formik}
                name="companySize"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refCompanySize}
                displayProperty={"Company Size"}
                propertyName={"CompanySize_leads"}
              />
            <NuInput
              type="text"
              label="Contact Person"
              placeholder="Contact Person"
              formik={formik}
              name="contactPersonName"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <NuMobileNumber
              nunCode="countryCode"
              label="Mobile"
              placeholder="Mobile"
              formik={formik}
              name="mobile"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
           
           <NuInput
              type="email"
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
          

            {/* <FormikSelectTwo
              Options={LeadsRatings}
              label="Performance Ratings"
              placeholder="Ratings"
              formik={formik}
              name="ratings"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
             <DropDownAddMetaData
                label="Conversion Probabilities"
                placeholder="Choose"
                Options={CoversionProbablity}
                formik={formik}
                name="conversionProbabilities"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refCoversionProbablity}
                displayProperty={"Conversion Probabilities"}
                propertyName={"Lead_CoversionProbablity"}
              />
           
           
            {/* <NuInput
              type="text"
              label="Description"
              placeholder="Description"
              formik={formik}
              name="description"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            {/* <NuInput
              type="text"
              label="LeadOwner"
              placeholder="LeadOwner"
              formik={formik}
              name="leadOwner"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
             <DropDownAddMetaData
                label="Lead Source"
                placeholder="Choose"
                Options={Source}
                formik={formik}
                name="leadSource"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refSource}
                displayProperty={"Lead Source"}
                propertyName={"lead_Source"}
              />
             
             </div>
           </div>
           <div className="w-full mx-auto  bg-white rounded-md pb-4 mt-4 ">
          <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
       
          <div className="w-full">
  <p className='py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
    Address :
  </p></div>
           
           
            <NuInput
              type="text"
              label="Street Address Line 1"
              placeholder="Street Address Line 1"
              formik={formik}
              name="address1"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <NuInput
              type="text"
              label="Street Address Line 2"
              placeholder="Street Address Line 2"
              formik={formik}
              name="address2"
              width="w-full md:w-1/2 lg:w-1/3"
            />

<NuInput
              type="text"
              label="City"
              placeholder="City"
              formik={formik}
              name="city"
              width="w-full md:w-1/2 lg:w-1/3"
            />

<NuInput
              type="text"
              label="State / Province"
              placeholder="State / Province"
              formik={formik}
              name="state"
              width="w-full md:w-1/2 lg:w-1/3"
            />

<NuInput
              type="number"
              label="Postal / Zip Code"
              placeholder="Postal / Zip Code"
              formik={formik}
              name="postalCode"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <FormikSelectTwo
              type="text"
              label="Country"
              placeholder="Country"
              Options={CountryMIN}
              formik={formik}
              name="country"
              width="w-full md:w-1/2 lg:w-1/3"
            />

         
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 "
              new_width="lg:w-2/3"
            />
            {/* <NuUpload
              label="Attachments"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            /> */}
              {/* </div>
           </div>
           <div className="w-full mx-auto h-auto bg-white rounded-md overflow-hidden mt-4 ">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto mb-4">
        */}
          <div className="w-full">
  <p className='py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  Attachement :
  </p></div>
              {/* <div className="ml-10 w-[15%] "> */}
              
              <Attachement
                  handleChange={handleImageChange}
                  preview={image||''}
                  width="w-full"
                  label="Attach"
                  multiple={true}
                  removeImages={removeImages}
                />
                        {/* </div> */}
                        </div>
                        <div className="w-full my-4 mt-8 ">
              <div className="flex justify-end gap-4">
              <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={loading}/>
              </div>
            </div>
          </div>
        {/* </div> */}
      </form>
      </div>
    </div>
  );
}

export default CreateLeads;
