import React, { useEffect, useState } from 'react'
import UseGetDashBoardData from '../../../../Components/Hooks/useDashboardData';
import { FaCheckCircle, FaClipboardCheck, FaEye, FaFileAlt, FaHourglassHalf } from 'react-icons/fa';
import axiosInstance from '../../../../Services/axiosCommon';

function PuschaseOrderToSupplier() {
   const [data, setData] = useState([]);
    const [loading,setLoading] = useState(false);
  
    async function getData() {
      setLoading(true);
      try {
        await axiosInstance.get(`/dashboard/PO-pending`).then(res=>{
          setLoading(false);
          setData(res?.data || []) ;
        });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    useEffect(() => {
      getData();
    }, []);
  

    return (
      
      <div className="flex-1 flex-wrap  p-2 overflow-y-scroll ">
        <div className="flex items-center justify-between mb-2">
          <p className="text-[#4F4768] text-xs 2xl:text-sm font-semibold">Purchase Order </p>
      
<p className="text-[#333333] text-sm float-end font-semibold">{(data?.total || 0).toLocaleString()}</p>
</div>
        <div className="grid gap-2 grid-cols-1">
        <div className="flex items-center text-[12px] ">
            <FaHourglassHalf className="text-yellow-500 mr-1" />
                      <div className=' w-full flex items-center'>
                        <div className=' w-[51%]'>Pending</div>
                        <div className='w-[49%]'>
  : { (data?.pending || 0).toLocaleString() }
</div>
     </div>
          </div>
         
          <div className="flex items-center text-[12px]">
          <FaEye className="text-indigo-500 mr-1" />
          <div className=' w-full flex items-center'>
                        <div className=' w-[51%]'>Reviewed</div>
                        <div className=' w-[49%] '>: {(data?.reviewed||0).toLocaleString()}</div>
                      </div>

          </div>
          <div className="flex items-center text-[12px]">
          <FaClipboardCheck className="text-purple-500 mr-1" />
          <div className=' w-full flex items-center'>
                        <div className=' w-[51%]'>Verified</div>
                        <div className=' w-[49%] '>: { (data?.verified || 0).toLocaleString() } </div>
                      </div>
                  
          </div>
          <div className="flex items-center text-[12px]">
            <FaCheckCircle className="text-green-500 mr-1" />
            <div className=' w-full flex items-center'>
                        <div className=' w-[51%]'>Approved</div>
                        <div className=' w-[49%] '>: {(data?.approved||0).toLocaleString()}</div>
                      </div>
           
          </div>
         
        </div>
      </div>
    )
  }
export default PuschaseOrderToSupplier