import React from 'react';
import { TextMediumBase, TextMediumSM, TextMediumXS } from '../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../Components/Hooks/useDashboardData';
import NuAmount from '../../../../Components/Utils/NuAmount';

export default function Difference() {
  const { data, loading } = UseGetDashBoardData(`/dashboard/sales-po-difference`);
  return (
    <div className="col-span-2  max-h-[40vh] w-full p-5 bg-[#31304D] rounded-lg shadow-lg overflow-hidden mb-4">
      <div className="flex ">
    <div className=" ">
          <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#06D001" class="size-16">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>

          </div>
          <div className="pl-2 pt-2 w-full">
          <p className='text-white text-[19px] font-normal '>Difference</p>

          {/* <TextMediumSM content={"Difference"} color="text-white" /> */}
          {/* <p className=' text-sm font-normal'>Difference</p> */}
        <p className='text-[#07b018] text-[18px] font-semibold uppercase'> <NuAmount currency={data?.currency} value={data?.difference||0} /></p>

        </div>
        </div>
 {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <div className="w-full h-[82%] flex justify-around align-middle overflow-hidden items-center">
          <div className=''>
         <p className='font-normal text-white'>Sales</p>
         <p className='text-[#FFBF00] text-[18px] font-semibold uppercase'> <NuAmount currency={data?.currency} value={data?.total_sales_order_price||0}/></p>

         </div>
         <div className="border-r border-[1px] border-slate-200/60 border-solid h-[70%] "></div>

         <div className=''>
         <p className='font-normal text-white'>Procurments</p>
         <p className='text-[#D2649A] text-[18px] font-semibold uppercase'>
         <NuAmount currency={data?.currency} value={data?.total_purchase_order_price||0}/>
</p>

          </div>
        </div>
      )}
          {/* <div className='flex'>
                  <p className='flex items-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M10 17L10 5" stroke="#9B74F2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M4.16699 10.0001L10.0003 4.16675L15.8337 10.0001" stroke="#9B74F2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg> 
                   </p>
                  <p className='pl-[2px] text-xs'>this month</p>
                </div>  */}
         
          

         
         





      {/* <div className="w-full bg-gray-50 px-1 py-2 rounded-md ">
        <TextMediumSM content="Sales Orders" color="text-gray-800" />
      </div> */}

      {/* {loading ? (
        <div className="p-4 flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full overflow-hidden">
          <div className="mb-4 overflow-y-auto max-h-[60%]">
            <TextMediumSM content="Customer Category Orders" />
            {data?.customer_category_orders?.map((item, index) => (
              <div key={index} className="flex justify-between px-4 py-2 border-b">
                <TextMediumXS content={`Category: ${item?.category || 'N/A'}`} />
                <TextMediumXS content={`Count: ${item?.count || 0}`} />
              </div>
            ))}
          </div>
          
          <div className="flex justify-around items-center">
            <div className="text-center">
              <TextMediumSM content="Percentage Last Month" />
              <TextMediumXS content={`${parseFloat(data?.percentage_last_month).toFixed(2) || 0}%`} />
            </div>
            <div className="text-center">
              <TextMediumSM content="Total Sales Orders" />
              <TextMediumXS content={data?.total_sales_orders || 0} />
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
}