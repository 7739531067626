import React, { useEffect, useState } from "react";
import DataTable from "../../../../Components/Others/DataTable";
import {
  CancelButton,
  CreateButton,
  FilterButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { Link } from "react-router-dom";
import SearchBar from "../../../../Components/Utils/SearchBar";
import Dashboard from "../../Dashboard";
// import UseGetData from "../../../../Components/Hooks/useFetchData";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { useFormik } from "formik";
import * as yup from 'yup';
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import UseCatSubCatDropDown from "../../../../Components/Hooks/useCategoryDropDown";
import useFetchMDIndex from "../../../../Components/Hooks/useFetchMDIndex";

function PromotionIndex() {
  
 
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(20);
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const {value:brand} = useFetchMDIndex(`/dropdown/dropdown-details/`,'brand_item',showFilterDialog)
  const {value:Manufacture} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Manufacture_item',showFilterDialog)
  const {value:Unit} = useFetchMDIndex(`/dropdown/dropdown-details/`,'Unit_item',showFilterDialog)

  const [filterProps, setFilterProps] = useState({
    category:"",
    subCategory:"",
    brand:"",
    manufaturer:"",
    isPromotable:"",
    unit:""
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:"",
    category:"",
    subCategory:"",
    brand:"",
    manufaturer:"",
    isPromotable:"",
    unit:""
   
  });
  const clearFilter = ()=> {
    setParams({...params,category:"",
      subCategory:"",
      brand:"",
      manufaturer:"",
      isPromotable:"",
      unit:""})
      setFilter(false)
      formik.values.unit=""
      formik.values.isPromotable=""
      formik.values.manufaturer=""
      formik.values.brand=""
      formik.values.subCategory=""
      formik.values.category=""

  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,category:value.category, subCategory:value.subCategory,unit:value.unit,isPromotable:value.isPromotable,manufaturer:value.manufaturer,brand:value.brand,})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        category:"",
    subCategory:"",
    brand:"",
    manufaturer:"",
    isPromotable:"",
    unit:""
      });
    }
  })

  const { data, totalCount, getData } = UseGetDataSearch(
   
    "/items/promotions",params
  );
  console.log(data, "dddd");

  const defaultHeader = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "productId",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Item Code",
      width: "9%",
      field: "itemCode",
      isLink: true,
      id: "productId",
      link: '/sales/promotions/view/',
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Product Name",
      width: "9%",
      isLink: true,
      id: "productId",
      link: '/sales/promotions/view/',
      field: "productName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Category",
      width: "9%",
      field: "category",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Sub Category",
      width: "9%",
      field: "subCategory",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Unit",
      width: "5%",
      field: "unit",
      textAlign: "center",
      rowAlign: "center",
    },
   
   

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "productId",
        removeValue: "ref",
        url: "/promotions/",
        moduleName: "promotions",
        from: "/sales/promotions",
        // editLink: "/marketing/leads/edit/",
        viewLink: "/sales/promotions/view/",
      },
    },
  ];

  let availableHeaders = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "productId",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Item Code",
      width: "9%",
      field: "itemCode",
      isLink: true,
      id: "productId",
      link: '/sales/promotions/view/',
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Product Name",
      width: "9%",
      isLink: true,
      id: "productId",
      link: '/sales/promotions/view/',
      field: "productName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Category",
      width: "9%",
      field: "category",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Sub Category",
      width: "9%",
      field: "subCategory",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Unit",
      width: "5%",
      field: "unit",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Is Promotable",
      width: "9%",
      field: "isPromotable",
      isCondition: true,
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Supplier Name",
      width: "9%",
      field: "vendorName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Brand",
      width: "9%",
      field: "brand",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Manufaturer",
      width: "9%",
      field: "manufaturer",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Description",
      width: "9%",
      field: "productDescription",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Comments",
    //   width: "9%",
    //   field: "comments",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "productId",
        removeValue: "ref",
        url: "/promotions/",
        moduleName: "promotions",
        from: "/sales/promotions",
        // editLink: "/marketing/leads/edit/",
        viewLink: "/sales/promotions/view/",
      },
    },
  ];

  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };
  const { category: itemsCategory, subCategory: itemsSubCategory } = UseCatSubCatDropDown('/category/ItemCategory', "ItemCategory", formik?.values?.category);

  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Promotions",
            },
          ]}
        />

        <div className=" flex gap-2">
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />  <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
          {/* <CreateButton label="Import" /> */}
      
        </div>
      </div>

      <CustomDataTable
        fetcher={getData}
        data={data}
        availableHeaders={availableHeaders}
        defaultHeader={defaultHeader}
        bodyHeight="max-h-[calc(100vh-260px)]"
        height="h-[calc(100vh-145px)]"
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title="Promotion List"
        storageName="PromotionTable"
      />
      {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Promotion</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <FormikSelectTwo 
                        label="Category"
                        placeholder="Choose"
                        Options={itemsCategory}
                        formik={formik}
                        name="category"
                        width="w-full"
                      />
                       <FormikSelectTwo
              type="text"
              label="Sub-Category"
              placeholder="Sub-Category"
              Options={itemsSubCategory}
              formik={formik}
              name="subCategory"
              width="w-full "
            />
                       {/* <FormikSelectTwo
              type="text"
              label="Uder Promotable"
              placeholder="Uder Promotable"
              Options={[
                { value: "No", label: "No" },
                { value: "Yes", label: "Yes" }

              ]}
              formik={formik}
              name="isPromotable"
              width="w-full "
            /> */}
              <FormikSelectTwo
                type="text"
                label="Unit"
                placeholder="Choose"
                Options={Unit}
                formik={formik}
                name="unit"
                width="w-full "
              />
             <FormikSelectTwo
                type="text"
                label="Manufaturer"
                placeholder="Choose"
                Options={Manufacture}
                formik={formik}
                name="manufaturer"
                width="w-full "
              />
               <FormikSelectTwo
                type="text"
                label="Brand"
                placeholder="Choose"
                Options={brand}
                formik={formik}
                name="brand"
                width="w-full "
              />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
     
    </div>
  );
}
export default PromotionIndex;
