import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { TextMediumSM, TextMediumXS } from '../../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';

const Industry = () => {
  const { data, loading } = UseGetDashBoardData(`/dashboard/customers-by-industry`);
  console.log("data?.customers_by_industry",data?.customers_by_industry);
  const [chartData,setChartData] = useState([]);
  
  

  useEffect(() => {
    if (data?.customers_by_industry) {
      const formattedData = data?.customers_by_industry.map(item => ({
        name: item.industry,
        value: item.count,
      }));
      formattedData.sort((a, b) => b.value - a.value);
      setChartData(formattedData);
    }
  }, [data]);

  console.log("dddd",chartData);
  

  const COLORS = ['#0C1844', '#1679AB', '#DA7297', '#55AD9B'];

  const CustomTooltip = ({ active, payload, }) => {
    console.log("sss", active, payload);
    
    if (active && payload && payload.length) {
      return (
        <div className=" max-w-[250px] bg-white shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] rounded-lg p-[5px]">
          <p className=" text-xs font-medium">{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    }
  
    return null;
  };

  return (
    <div className="col-span-2 bg-white h-[40vh] p-4 pb-10 rounded-lg shadow-lg overflow-hidden mb-4">
      <div className="flex justify-between items-center mb-2">
        <TextMediumSM content="Customer By Industry" color="text-[#4F4768]" />
      </div>

      {(chartData.length>0 && loading) ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <>
            <div className='w-full flex h-[89%]'>

            <div className="w-1/2 flex justify-center items-center">
            <ResponsiveContainer width="100%" height={150}>
              <PieChart>
                <Pie
                  data={chartData}
                  cx="50%"
                  cy="50%"
                  innerRadius={30}
                  outerRadius={60}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            </ResponsiveContainer>
            </div>
            <div className="w-1/2 overflow-y-scroll">
          <div className='w-full sticky top-0 bg-white shadow-sm flex justify-start gap-2 pt-2  text-xs 2xl:text-sm'>
           <p className='w-[60%]'>Industry</p>
          <p className='w-[40%]'>Count</p>
        
</div>
              {chartData?.map((item, index) => (
                <div key={index} className="flex items-center text-xs mb-1">
                  <span
                    className="inline-block w-2 h-2 rounded-full mr-2"
                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                  ></span>
                  <p className='w-[60%]'>{item.name}</p>
                  <p className='w-[40%]'> {item.value}</p>
                </div>
              ))}
              
             
            </div>
          </div>
         
        </>
      )}
    </div>
  );
}

export default Industry;
