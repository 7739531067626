// import React, { useEffect, useMemo, useState } from "react";
// import {
//   CreateButton
// } from "../../../../Components/Utils/Buttons";
// import { useOutletContext } from "react-router-dom";
// import ModalContainer from "../../../../Components/Others/ModalContainer";
// import {
//   TextMediumSM,
//   TextMediumXS,
// } from "../../../../Components/Text/MediumText";
// import { TextXS } from "../../../../Components/Text/NormalText";
// import axiosInstance from "../../../../Services/axiosCommon";
// import NuAmount from "../../../../Components/Utils/NuAmount";
// import { useFormik } from "formik";
// import * as yup from "yup";
// import { useToast } from "../../../../Components/Others/ToastServices";
// import { NuInput } from "../../../../Components/Utils/Input";

// function ViewQuote({ startLoading = false }) {

//   const { prId:viewqoutesId } = useOutletContext();
//   console.log(viewqoutesId,'dddddddd.d.d.d');
//   const toast = useToast();

//   const [land, setland] = useState(0.0);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isMarginModalOpen, setIsMarginModalOpen] = useState(false);
//   const [marginValue, setMarginValue] = useState(0);
//   const [marginChange, setMarginChange] = useState("");
//   const [createNew, setCreateNew] = useState({
//     currencyId: 0,
//     duttyCost: 0,
//     saberCost: 0,
//     tptCost: 0,
//     landedCost: 0,
//     otherCost: 0,
//     itemCount: 0,
//   });

//   const [duty, setDuty] = useState(0);
//   const [tpt, setTpt] = useState(0);
//   const [saber, setSaber] = useState(0);
//   const [other, setOther] = useState(0);
//   const [total, setTotal] = useState(0);
//   const [dutyPerProduct, setDutyPerProduct] = useState(0);
//   const [tptPerProduct, setTptPerProduct] = useState(0);
//   const [saberPerProduct, setSaberPerProduct] = useState(0);
//   const [otherPerProduct, setOtherPerProduct] = useState(0);
//   const [totalPerProduct, setTotalPerProduct] = useState(0);
//   const [productCount, setProductCount] = useState(0);
//   const [vendorValue, setVendorValue] = useState()
//   const [finalProId, setFfinalProId] = useState([])

//   const [dynamicHeaderCount, setDynamicHeaderCount] = useState(3);
//   const [data, setData] = useState([]);

//   const [stateValue, setStateValue] = useState([]);

//   const [supplierList, setSuppliersList] = useState([]);
//   const [isLoading, setIsLoading] = useState(true)

//   const dynamicHeaders = Array.from(
//     { length: dynamicHeaderCount },
//     (_, index) => (
//       <React.Fragment key={`dynamic-header-${index}`}>
//         <th className="col-span-5 w-[3000px]">
//           <div className="w-full border border-x-0 border-y-0 bg-[#C4C2C2]-300  py-2">
//             <div className="w-full py-2 flex justify-center items-center">
//               <div className="px-2">
//                 {supplierList[index]?.name && <span className="text-[10px] leading-3 text-slate-300">{`(Supplier ${index + 1}) `}</span>}{supplierList[index]?.name || `Supplier ${index + 1}`}
//               </div>
//               <div className="cursor-pointer"
//                 onClick={async () => {
//                   await getData(viewqoutesId, supplierList[index]?.id)
//                   setVendorValue(supplierList[index]?.id)
//                   setIsModalOpen(true)
//                 }}>
//                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="size-4">
//                   <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V13.5Zm0 2.25h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V18Zm2.498-6.75h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V13.5Zm0 2.25h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V18Zm2.504-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5Zm0 2.25h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V18Zm2.498-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5ZM8.25 6h7.5v2.25h-7.5V6ZM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0 0 12 2.25Z" />
//                 </svg>
//               </div>
//             </div>

//             <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] mx-auto">
//             </div>

//             <div>
//               <th className="w-[300px] py-2 overflow-hidden">Unit Price</th>
//               <th className="w-[300px] py-2">
//                 <div
//                   className=" w-full h-full"
//                 >
//                   Landing Cost
//                 </div>
//               </th>
//               {/* <th className="w-[150px] py-2">GP</th> */}
//               <th className="w-[200px] py-2">GP</th>
//               <th className="w-[500px] py-2">Selling Price</th>
//             </div>
//           </div>
//         </th>
//         {/*         
//              <th className="w-[200px] py-2 capitalize">
//                {supplierList[index]?.name || `Supplier ${index + 1}`}
//              </th>
//              <th className="w-[100px] py-2">Unit Price</th>
//              <th className="w-[150px] py-2">
//                <div
//                  className=" w-full h-full cursor-pointer"
//                  onClick={async () => {
//                    await getData(viewqoutesId,supplierList[index]?.id)
//                    setVendorValue(supplierList[index]?.id)
//                    setIsModalOpen(true)
//                  }}
//                >
//                 Landing Cost
//                </div>
//              </th>
//              <th className="w-[100px] py-2">VAT</th>
//              <th className="w-[80px] py-2">Gross</th>
//              <th className="w-[150px] py-2">Total Price</th> */}
//       </React.Fragment>
//     )
//   );

//   function handleChange(e, supId, proId) {
//     // console.log("SID - xxx", supId);
//     // console.log("e - xxx", e.target.checked);
//     // console.log("PID - xxx", proId);
//     let List = [...stateValue];
//     let value = List.find((i) => i.vendorId == supId && i.productId == proId);
//     console.log("value", value);
//     value.isSelected = e.target.checked;
//     // setFfinalProId([...finalProId,value.id])
//     setStateValue(List);
//     console.log("final pro", finalProId);
//     FinalProductList(value.id)
//   }

//   function FinalProductList(id) {
//     if (finalProId.some(i => i == id) == true) {
//       let tempValue = finalProId.filter((i) => i != id);
//       console.log("tem", tempValue);
//       setFfinalProId(tempValue)
//     } else {
//       setFfinalProId([...finalProId, id])
//     }
//   }

//   function MarginCompleted() {
//     setIsMarginModalOpen(false);
//     setMarginValue(0);
//     setMarginChange("");
//   }

//   function getViewQuotes(prId) {
//     setIsLoading(true)

//     axiosInstance
//       .get(`/supplier-quotations/quot-compare/${prId}`)
//       .then((res) => {
//         console.log(res.data);
//         if (res.data.status) {
//           setData(res.data?.data?.products);
//           setProductCount(res?.data?.data?.products?.length || 0);
//           setDynamicHeaderCount(res.data?.data?.vendorNames ? res.data?.data?.vendorNames.length : 0);
//           setSuppliersList(res.data?.data?.vendorNames);
//           setStateValue(res.data?.data?.vendors);
//           let vendorsItem = res.data?.data?.vendors || []
//           let TempValue = []
//           vendorsItem.forEach(i => {
//             if (i.isSelected) {
//               TempValue.push(i.id)
//             }
//           })
//           setFfinalProId(TempValue)
//           setIsLoading(false)

//         }
//       });
//   }

//   useEffect(() => {
//     getViewQuotes(viewqoutesId);
//   }, [viewqoutesId]);

//   useEffect(() => {
//     setTotalPerProduct(total / setCreateNew.itemCount);
//   }, [total]);

//   function AddPrice(x, y, z) {
//     let UP = x || 0;
//     let M = y || 0;
//     let PP = z || 0;
//     console.log(UP, M, PP);
//     return UP + PP;
//   }

//   function AddTotalPrice(q, x) {
//     let Q = q || 0;
//     let UP = x || 0;
//     return Q * UP;
//   }

//   const formik = useFormik({
//     enableReinitialize: true,
//     initialValues: createNew,
//     validateOnChange: false,
//     validateOnBlur: false,
//     validationSchema: yup.object({
//       // email: yup.string().required('Email is required!'),
//     }),
//     onSubmit: async (value, { resetForm }) => {
//       value = { ...value, landedCost: land };
//       axiosInstance
//         .put(`/supplier-quotations/edit-calculation/${viewqoutesId}/${vendorValue}`, value)
//         .then((res) => {
//           console.log(res);
//           // getData();
//           getViewQuotes(viewqoutesId);
//           if (res.data?.status === true) {
//             // res.data?.data && setData();
//             toast.open({
//               type: "success",
//               message: "Success",
//               description: res.data?.msg || "Created Successfully.",
//             });
//           } else if (res.data?.status === false && res.data?.error) {
//             toast.open({
//               type: "error",
//               message: "Error",
//               description: res.data.error || "Network error.",
//             });
//           } else {
//             toast.open({
//               type: "warning",
//               message: "Warning",
//               description: res.data?.msg || "Warning.",
//             });
//           }
//         })
//         .catch((err) => {
//           console.log(err);
//           toast.open({
//             type: "error",
//             message: "Error",
//             description: "Network error.",
//           });
//         });
//     },
//   });

//   useMemo(() => {
//     let land =
//       formik.values.tptCost +
//       formik.values.duttyCost +
//       formik.values.otherCost +
//       formik.values.saberCost;
//     setland(land);
//     let itemCount = createNew.itemCount || 1;
//     setDutyPerProduct(formik.values.duttyCost / itemCount);
//     setTptPerProduct(formik.values.tptCost / itemCount);
//     setSaberPerProduct(formik.values.saberCost / itemCount);
//     setOtherPerProduct(formik.values.otherCost / itemCount);
//     setTotalPerProduct(land / itemCount);
//   }, [formik.values]);

//   function getData(prId, vendorId) {
//     console.log("vendor", vendorId);
//     axiosInstance.get(`/supplier-quotations/calculation/${prId}/${vendorId}`).then((res) => {
//       setCreateNew({
//         duttyCost: res?.data?.data?.duttyCost || 0,
//         tptCost: res?.data?.data?.tptCost || 0,
//         landedCost: res?.data?.data?.landedCost || 0,
//         otherCost: res?.data?.data?.otherCost || 0,
//         saberCost: res?.data?.data?.saberCost || 0,
//         itemCount: res?.data?.data?.itemCount || 0,
//       });
//     });
//   }
//   // useEffect(() => {
//   //   getData();
//   // }, []);


//   function saveProducts() {
//     axiosInstance.put(`/supplier-quotations/quot-compare/select-quotes/${viewqoutesId}`, { 'products': finalProId }).then(res => {
//       if (res.data?.status === true) {
//         getViewQuotes(viewqoutesId)
//         toast.open({
//           type: "success",
//           message: "Success",
//           description: res.data?.msg || "Created Successfully.",
//         });
//       } else if (res.data?.status === false && res.data?.error) {
//         toast.open({
//           type: "error",
//           message: "Error",
//           description: res.data.error || "Network error.",
//         });
//       } else {
//         toast.open({
//           type: "warning",
//           message: "Warning",
//           description: res.data?.msg || "Warning.",
//         });
//       }
//     }).catch(err => {
//       toast.open({
//         type: "error",
//         message: "Error",
//         description: "Network error.",
//       });
//     })
//     console.log("final", finalProId);
//   }

//   return (
//     <div className="w-full h-full px-4 py-2">

//       <div className="w-full max-w-full bg-white rounded-lg">
//         <div className="w-[calc(100vw-250px)] h-[calc(100vh-300px)] overflow-y-scroll">

//           {
//             (isLoading == true || startLoading == true) ? (
//               // <div className="w-full h-full">
//               <>
//                 <div className="space-y-4 w-full">
//                   {[...Array(5)].map((_, index) => (
//                     <div key={index} className="animate-pulse flex space-x-4">
//                       <div className="rounded-full bg-slate-200 h-10 w-10"></div>
//                       <div className="flex-1 space-y-6 py-1">
//                         <div className="h-4 bg-slate-200 rounded"></div>
//                         <div className="space-y-3">
//                           <div className="grid grid-cols-3 gap-4">
//                             <div className="h-4 bg-slate-200 rounded col-span-2"></div>
//                             <div className="h-4 bg-slate-200 rounded col-span-1"></div>
//                           </div>
//                           <div className="h-4 bg-slate-200 rounded"></div>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </>
//               // </div>
//             )
//               :
//               (
//                 <div className={supplierList?.length > 1 ? `w-[calc((100vw-100px)*2.5)] overflow-x-scroll` : `w-[calc(100vw-250px)] overflow-x-scroll`}>
//                   <table className="table-auto relative w-full border-collapse border border-slate-300">
//                     <thead className="bg-baseColor-primary sticky top-0 text-white text-xs 2xl:text-sm">
//                       <tr>
//                         <th className="w-[80px] py-2">S.N.</th>
//                         <th className="w-[300px] text-left py-2">Product</th>
//                         <th className="w-[150px] py-2">Unit</th>
//                         <th className="w-[150px] py-2">Qty</th>

//                         {dynamicHeaders}
//                       </tr>
//                     </thead>
//                     <tbody className="">
//                       {data && data.map((item, index) => (
//                         <tr
//                           key={index}
//                           className="odd:bg-white even:bg-slate-50  text-xs 2xl:text-sm border-collapse border border-slate-300"
//                         >
//                           <td className="py-2 text-center">{index + 1}</td>
//                           <td className="py-2 text-left">{item.name}</td>
//                           <td className="py-2 text-center">{item.unit}</td>
//                           <td className="py-2 text-center">{item.requestedQty}</td>

//                           {supplierList.map((sub, index) => {
//                             const supplier = stateValue.find(
//                               (i) => i.productId === item.id && i.vendorId === sub.id
//                             );
//                             if (supplier) {
//                               return (
//                                 <React.Fragment key={`dynamic-cell-${index}-${index}`}>

//                                   <td className="col-span-5">
//                                     <div className="w-full">
//                                       {/* <td
//                                  className={`py-2 text-center border-collapse border  border-slate-300`}
//                                >
//                                  {supplier.currencyLabel || "-"}
//                                </td> */}
//                                       <td
//                                         className={`py-2 text-center border-collapse border-x border-y-0 w-[300px] border-slate-300`}
//                                       >
//                                         {<NuAmount
//                                           value={
//                                             supplier?.unitPrice || 0
//                                           }
//                                         /> || "-"}
//                                       </td>

//                                       <td
//                                         className={`py-2 text-center border-collapse border-x border-y-0 w-[300px] ${supplier.isLow == true && supplier.isHigh == true
//                                           ? "bg-green-100"
//                                           : supplier.isHigh == true
//                                             ? "bg-red-100"
//                                             : supplier.isLow == true
//                                               ? "bg-green-100"
//                                               : ""
//                                           } border-slate-300`}
//                                       >
//                                         <div
//                                           className=" w-full h-full flex justify-evenly"
//                                         // onClick={() => setIsModalOpen(true)}
//                                         >
//                                           <input
//                                             type="checkbox"
//                                             checked={supplier.isSelected}
//                                             onChange={(e) =>
//                                               handleChange(
//                                                 e,
//                                                 supplier.vendorId,
//                                                 supplier.productId
//                                               )
//                                             }
//                                           />
//                                           <NuAmount
//                                             value={
//                                               supplier?.sellingPrice || 0
//                                             }
//                                           />
//                                         </div>
//                                       </td>
//                                       {/* <td
//                                  className={`py-2 text-center border-collapse border  border-slate-300`}
//                                >
//                                  <div
//                                    className=" w-full h-full"
//                                    // onClick={() => setIsModalOpen(true)}
//                                  >
//                                    {supplier.quantity || 0}
//                                  </div>
//                                </td> */}
//                                       {/* <td
//                                         className={`py-2 text-center border-collapse border-x border-y-0 w-[150px] border-slate-300`}
//                                       >
//                                         <div
//                                           className=" w-full h-full"
//                                         // onClick={() => setIsModalOpen(true)}
//                                         >
//                                           <NuAmount value={supplier?.vat || 0} />
//                                         </div>
//                                       </td> */}
//                                       <td
//                                         className={`py-2 text-center border-collapse border-x border-y-0 w-[200px] border-slate-300`}
//                                       >
//                                         <div
//                                           className=" w-full h-full cursor-pointer"
//                                           onClick={() => {
//                                             setMarginValue(supplier.margin);
//                                             setMarginChange(supplier.margin);
//                                             setIsMarginModalOpen(true);
//                                           }}
//                                         >
//                                           {<NuAmount value={supplier.gross || 0} /> || "-"}
//                                         </div>
//                                       </td>
//                                       <td
//                                         className={`py-2 text-center  w-[500px] border-slate-300`}
//                                       >
//                                         <div
//                                           className=" w-full h-full"
//                                         // onClick={() => setIsModalOpen(true)}
//                                         >
//                                           <NuAmount
//                                             value={
//                                               AddTotalPrice(
//                                                 supplier.quantity,
//                                                 supplier.sellingPrice,
//                                               ) || 0
//                                             }
//                                           />
//                                         </div>
//                                       </td>
//                                     </div>
//                                   </td>


//                                 </React.Fragment>
//                               );
//                             } else {
//                               return (
//                                 <React.Fragment key={`dynamic-cell-${index}-${index}`}>
//                                   <td className="border-collapse border-x border-y-0 border-slate-300"></td>
//                                   <td className="border-collapse border-x border-y-0 border-slate-300"></td>
//                                   <td className="border-collapse border-x border-y-0 border-slate-300"></td>
//                                   <td className="border-collapse border-x border-y-0 border-slate-300"></td>
//                                   <td className="border-collapse border-x border-y-0 border-slate-300"></td>
//                                   <td className="border-collapse border-x border-y-0 border-slate-300"></td>
//                                   <td className="border-collapse border-x border-y-0 border-slate-300"></td>
//                                   <td className=" border-slate-300"></td>
//                                 </React.Fragment>
//                               );
//                             }
//                           })}
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               )
//           }
//         </div>
//         <div className=" w-full flex items-center px-4 h-[60px]">
//           <div className=" w-[calc(84vw-240px)] flex items-center gap-2">
//             <TextMediumXS color="text-red-700" content="Notes : " />
//             <div className=" flex gap-3 flex-wrap">
//               {/* <TextXS content="You can edit Margin by Clicking Margin Column ," /> */}
//               <TextXS content="You can view the landing cost by clicking the calculator icon near each supplier in the header." />
//               <span className=" flex gap-1 items-center">
//                 <div className=" w-4 h-4 rounded-full bg-green-100"></div>
//                 <TextXS content=" color is show the Lowest Price , " />
//               </span>

//               <span className=" flex gap-1 items-center">
//                 <div className=" w-4 h-4 rounded-full bg-red-100"></div>
//                 <TextXS content=" color is show the Highest Price. " />
//               </span>
//             </div>
//           </div>
//           <div className=" w-44 flex gap-3 justify-end">
//             <CreateButton label="Save" handleClick={saveProducts} svg={false} />
//             {/* <CreateButton label="Create Quotes" svg={false} /> */}
//           </div>
//         </div>
//       </div>
//       <ModalContainer
//         visiable={isModalOpen}
//         title="Added Cost"
//         content={
//           <>
//             <div className=" w-[40vw]">
//               <div className=" px-2 my-2 w-full flex justify-between">
//                 {/* <div className=" w-2/5">
//                        <TextMediumSM content={"Duty  : "} />
//                      </div> */}
//                 <div className=" w-1/2">
//                   <NuInput
//                     type="number"
//                     disabled={true}
//                     label="Duty"
//                     // value={duty}
//                     formik={formik}
//                     name="duttyCost"
//                     // onChange={(e)=>setDuty(e.target.value)}
//                     className=" w-full py-1 px-3 border rounded-lg outline-none "
//                   />
//                 </div>
//                 <div className=" w-1/2">
//                   <NuInput
//                     type="number"
//                     disabled={true}
//                     label="TPT"
//                     className=" w-full py-1 px-3 border rounded-lg outline-none "
//                     // value={tpt}
//                     formik={formik}
//                     name="tptCost"
//                   // onChange={(e)=>setTpt(e.target.value)}
//                   />
//                 </div>
//               </div>
//               {/* <div className=" px-2 my-2 w-full flex justify-center"> */}
//               {/* <div className=" w-2/5">
//                        <TextMediumSM content={"TPT  : "} />
//                      </div> */}

//               {/* </div> */}
//               <div className=" px-2 my-2 w-full flex justify-between">
//                 {/* <div className=" w-2/5">
//                        <TextMediumSM content={"SABER  : "} />
//                      </div> */}
//                 <div className=" w-1/2">
//                   <NuInput
//                     type="number"
//                     disabled={true}
//                     label="SABER"
//                     className=" w-full py-1 px-3 border rounded-lg outline-none "
//                     // value={saber}
//                     formik={formik}
//                     name="saberCost"
//                   />
//                 </div>
//                 <div className=" w-1/2">
//                   <NuInput
//                     type="number"
//                     label="Others"
//                     className=" w-full py-1 px-3 border rounded-lg outline-none "
//                     // value={other}
//                     disabled={true}
//                     formik={formik}
//                     name="otherCost"
//                   // onChange={(e)=>setOther(e.target.value)}
//                   />
//                 </div>
//               </div>

//               {/* <div className=" px-2 my-2 w-full flex justify-center"> */}
//               {/* <div className=" w-2/5">
//                        <TextMediumSM content={"Others  : "} />
//                      </div> */}

//               {/* </div> */}

//               <div className=" px-2 my-2 w-full flex justify-center">
//                 <div className=" w-2/5">
//                   <TextMediumSM content={"Total LANDED COST : "} />
//                 </div>
//                 <div className=" w-3/5 flex justify-end">{land}</div>
//               </div>
//               <div className=" px-2 my-2 w-full flex justify-center">
//                 <div className=" w-2/5">
//                   <TextMediumSM content={"Total Product : "} />
//                 </div>
//                 <div className=" w-3/5 flex justify-end">
//                   {formik.values.itemCount}
//                 </div>
//               </div>
//               <div className=" w-[95%] mx-auto h-[1px] bg-slate-300 my-6"></div>
//               <div className=" px-2 my-2 w-full flex justify-center">
//                 <div className=" w-2/5">
//                   <TextMediumSM content={"Duty Per Product : "} />
//                 </div>
//                 <div className=" w-3/5 flex justify-end">
//                   <NuAmount value={dutyPerProduct || 0} />
//                 </div>
//               </div>
//               <div className=" px-2 my-2 w-full flex justify-center">
//                 <div className=" w-2/5">
//                   <TextMediumSM content={"TDT Per Product : "} />
//                 </div>
//                 <div className=" w-3/5 flex justify-end">
//                   <NuAmount value={tptPerProduct || 0} />
//                 </div>
//               </div>
//               <div className=" px-2 my-2 w-full flex justify-center">
//                 <div className=" w-2/5">
//                   <TextMediumSM content={"SABER Per Product : "} />
//                 </div>
//                 <div className=" w-3/5 flex justify-end">
//                   <NuAmount value={saberPerProduct || 0} />
//                 </div>
//               </div>
//               <div className=" px-2 my-2 w-full flex justify-center">
//                 <div className=" w-2/5">
//                   <TextMediumSM content={"Other Per Product : "} />
//                 </div>
//                 <div className=" w-3/5 flex justify-end">
//                   <NuAmount value={otherPerProduct || 0} />
//                 </div>
//               </div>
//               <div className=" px-2 my-2 w-full flex justify-center">
//                 <div className=" w-2/5">
//                   <TextMediumSM content={"LANDED COST Per Product : "} />
//                 </div>
//                 <div className=" w-3/5 flex justify-end">
//                   <NuAmount value={totalPerProduct || 0} />
//                 </div>
//               </div>
//             </div>
//           </>
//         }
//         handleClick={() => {
//           formik.submitForm();
//           setIsModalOpen(false);
//         }}
//         closeModal={() => setIsModalOpen(false)}
//       />

//       <ModalContainer
//         visiable={isMarginModalOpen}
//         title="Change Margin (%)"
//         hasContent={true}
//         content={
//           <div>
//             <input
//               className=" border  px-3 py-1 rounded-lg w-full border-slate-400 text-xs 2xl:text-sm"
//               type="number"
//               value={marginChange}
//               onChange={(e) => setMarginChange(e.target.value)}
//             />
//             {/* {marginValue} */}
//           </div>
//         }
//         handleClick={MarginCompleted}
//         closeModal={() => setIsMarginModalOpen(false)}
//       />
//     </div>
//   );
// }

// export default ViewQuote;

import React, { useEffect, useMemo, useState } from "react";
import {
  CreateButton
} from "../../../../Components/Utils/Buttons";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import ModalContainer from "../../../../Components/Others/ModalContainer";
import {
  TextMediumSM,
  TextMediumXS,
} from "../../../../Components/Text/MediumText";
import { TextSM, TextXS } from "../../../../Components/Text/NormalText";
import axiosInstance from "../../../../Services/axiosCommon";
import NuAmount from "../../../../Components/Utils/NuAmount";
import { useFormik } from "formik";
import * as yup from "yup";
import { useToast } from "../../../../Components/Others/ToastServices";
import { NuInput } from "../../../../Components/Utils/Input";
import EditIcon from "../../../../Components/Icons/Edit";

function ViewQuote({ startLoading = false,backUrl="" }) {
  const { quotesEditId } = useParams();
  const location = useLocation();
  const data = location?.state?.data;

  const { prId:viewqoutesId } = useOutletContext();
  const toast = useToast();
  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  // const { data: currency } = UseDropDown('/supplier-quotations/currency/drop-down');
  const [land, setland] = useState(0.0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMarginModalOpen, setIsMarginModalOpen] = useState(false);
  const [marginValue, setMarginValue] = useState(0);
  const [marginChange, setMarginChange] = useState("");
  const [createNew, setCreateNew] = useState({
    currencyId: null,
    duttyCost: 0,
    saberCost: 0,
    tptCost: 0,
    landedCost: 0,
    otherCost: 0,
    itemCount: 0,
  });

  const [duty, setDuty] = useState(0);
  const [tpt, setTpt] = useState(0);
  const [saber, setSaber] = useState(0);
  const [other, setOther] = useState(0);
  const [total, setTotal] = useState(0);
  const [dutyPerProduct, setDutyPerProduct] = useState(0);
  const [tptPerProduct, setTptPerProduct] = useState(0);
  const [saberPerProduct, setSaberPerProduct] = useState(0);
  const [otherPerProduct, setOtherPerProduct] = useState(0);
  const [totalPerProduct, setTotalPerProduct] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [vendorValue, setVendorValue] = useState()
  const [finalProId,setFfinalProId]=useState([])
  const [sellingPrices,setSellingPrices]=useState({})
  const [gPEditShow,setGPEditShow] = useState(false);

  const [dynamicHeaderCount, setDynamicHeaderCount] = useState(3);
  const [datas, setData] = useState([]);

  const [stateValue, setStateValue] = useState([]);

  const [supplierList, setSuppliersList] = useState([]);
const [isLoading,setIsLoading]=useState(true)

  const dynamicHeaders = Array.from(
    { length: dynamicHeaderCount },
    (_, index) => (
      <React.Fragment key={`dynamic-header-${index}`}>
        <th className=" w-[500px]">
          <div className="w-full border border-x-0 border-y-0 bg-[#C4C2C2]-300  py-1">
            <div className="w-full py-1 flex justify-center items-center">
              <div className="px-2">
                {supplierList[index]?.name && supplierList[index]?.name || `Supplier ${index + 1}`}
              </div>
              <div className=" ml-2 h-7">
                <div className=" text-left font-medium text-[9px] leading-3">Ref : {supplierList[index]?.ref||"#"}</div>
                <div className=" text-left font-medium text-[9px] leading-3">Prepared By : <span className=" capitalize">{supplierList[index]?.preparedBy||"-"}</span> </div>
              </div>
            </div>

            <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] mx-auto">
            </div>
              <div>
                <th className="w-[200px] py-1 overflow-hidden">Unit Price</th>
                <th className="w-[100px] py-1"><p className="flex gap-1 ml-5">GP<span className="cursor-pointer" onClick={()=>{setGPEditShow(pev => !pev)}}><EditIcon width={"15"} height={"15"} /></span></p></th>
                <th className="w-[200px] py-1">Selling Price</th>
              </div>
          </div>
        </th>
      </React.Fragment>
    )
  );

  const handleGPChange = (e, supId, proId) => {
    let List = [...stateValue];
    let value = List.find((i) => i.quoteId === supId && i.productId === proId);
    if (value) {
      value.gp = parseFloat(e.target.value).toFixed(2);

      let price = !isNaN(value.LCSP) ? parseFloat(value.LCSP).toFixed(2) : 0;
      let gp = !isNaN(value.gp) ? parseFloat(value.gp).toFixed(2) : 0;
      value.sellingPrice = parseFloat(parseFloat(price)+(((parseFloat(price))/100)*gp)).toFixed(2);

      setStateValue(List);
      updateSellingPricesGP(value.id, gp);
      console.log("selingPrice...", sellingPrices);
    }
  };

  const updateSellingPricesGP = (id, gp) => {
      setSellingPrices((prevPrices) => ({
          ...prevPrices,
          [id]: {
              ...prevPrices[id],  // Preserve existing landedCost and other properties
              gp: gp              // Update the gp value
          }
      }));
  };

  const handleLCChange = (e, supId, proId) => {
    let List = [...stateValue];
    let value = List.find((i) => i.vendorId === supId && i.productId === proId);
    if (value) {
      value.landedCost = parseFloat(e.target.value).toFixed(2);

      // Calculate the selling price ((unitPrice+landingCost)*100)/(100-GP)
      let unitPrice = !isNaN(value.convertedPrice) ? parseFloat(value.convertedPrice) : 0;
      let LC = !isNaN(value.landedCost) ? parseFloat(value.landedCost) : 0;
      let LCSellingPrice = parseFloat(LC+unitPrice).toFixed(2);
      let gp = !isNaN(value.gp) ? parseFloat(value.gp).toFixed(2) : 0;
      value.sellingPrice = (LCSellingPrice*100)/(100-gp);
      
      setStateValue(List);
      updateSellingPricesLC(value.id, LC)
      console.log("selingPrice...", sellingPrices);
      
    }
  };

  const updateSellingPricesLC = (id, LC) => {
    setSellingPrices((prevPrices) => ({
        ...prevPrices,
        [id]: {
            ...prevPrices[id],  // Preserve existing landedCost and other properties
            landedCost: LC              // Update the gp value
        }
    }));
};

  function handleChange(e, supId, proId) {
    let List = [...stateValue];
    let value = List.find((i) => i.vendorId == supId && i.productId == proId);
    value.isSelected = e.target.checked;
    setStateValue(List);
    FinalProductList(value.id)
  }

  function FinalProductList(id){
    if(finalProId.some(i=>i==id)==true){
      let tempValue = finalProId.filter((i) => i != id);
      console.log("tem",tempValue);
      setFfinalProId(tempValue)
    }else{
      setFfinalProId([...finalProId,id])
    }
  }

  function MarginCompleted() {
    setIsMarginModalOpen(false);
    setMarginValue(0);
    setMarginChange("");
  }

  function getViewQuotes(prId) {
    setIsLoading(true)

    axiosInstance
      .get(`/supplier-quotations/quot-compare/${prId}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          setData(res.data?.data?.products);
          setProductCount(res?.data?.data?.products?.length || 0);
          setDynamicHeaderCount( res.data?.data?.vendorNames ? res.data?.data?.vendorNames.length : 0);
          setSuppliersList(res.data?.data?.vendorNames);
          setStateValue(res.data?.data?.vendors);
          let vendorsItem =res.data?.data?.vendors||[]
          let TempValue =[]
          vendorsItem.forEach(i=>{
            if(i.isSelected){
              TempValue.push(i.id)
            }
          })
          setFfinalProId(TempValue)
          setIsLoading(false)

        }
      }).catch(err=>{
        console.log("error",err);
        
    })
  }

  const [currency,setCurrency] = useState([]);
  async function getCurrency() {
    axiosInstance.get('/currency/').then(res => {
        console.log('daataa',res.data.data);
        let data = res.data.data;
        console.log(data,'dddd');
        let tempData = [];
        data.forEach((item) => {
            let tempObject = { value: item.id, label: item.currency };
            tempData.push(tempObject);
        });
        res.data.data && setCurrency(tempData);
    }).catch(err => {
        console.log(err, "err");
    })
  }

  const [vatRate,setVatRate] = useState();
  async function getVatRate() {
    axiosInstance.get('/vat/').then(res => {
        console.log('vat',res.data.data);
        // let data = res.data.data;
        res.data.data && setVatRate(res.data.data.vatRate);
    }).catch(err => {
        console.log(err, "err");
    })
  }

  useEffect(() => {
    getCurrency();
    getVatRate();
    getViewQuotes(viewqoutesId);
  }, [viewqoutesId]);

  useEffect(() => {
    setTotalPerProduct(total / setCreateNew.itemCount);
  }, [total]);

  function AddPrice(x, y, z) {
    let UP = x || 0;
    let M = y || 0;
    let PP = z || 0;
    console.log(UP, M, PP);
    return UP + PP;
  }

  function AddTotalPrice(q, x) {
    let Q = q || 0;
    let UP = x || 0;
    return Q * UP;
  }


  function saveProducts(){
    axiosInstance.put(`/supplier-quotations/quot-compare/select-quotes/${viewqoutesId}`,{'products':finalProId}).then(res=>{
      if (res.data?.status === true) {
        getViewQuotes(viewqoutesId)
        toast.open({
          type: "success",
          message: "Success",
          description: res.data?.msg || "Created Successfully.",
        });
      } else if (res.data?.status === false && res.data?.error) {
        toast.open({
          type: "error",
          message: "Error",
          description: res.data.error || "Network error.",
        });
      } else {
        toast.open({
          type: "warning",
          message: "Warning",
          description: res.data?.msg || "Warning.",
        });
      }
    }).catch(err=>{
      toast.open({
        type: "error",
        message: "Error",
        description: "Network error.",
      });
    })
    console.log("final",finalProId);
  }

  function saveSellingPrice(){
    axiosInstance.put(`/supplier-quotations/quot-compare/selling-price/${viewqoutesId}`,sellingPrices).then(res=>{
      if (res.data?.status === true) {
        toast.open({
          type: "success",
          message: "Success",
          description: res.data?.msg || "Selling Price updated.",
        });
      } else if (res.data?.status === false) {
        toast.open({
          type: "error",
          message: "Failed",
          description: res.data.error || "Selling Price not updated.",
        });
      } else {
        toast.open({
          type: "warning",
          message: "Failed",
          description: res.data?.msg || "Selling Price not updated.",
        });
      }
    }).catch(err=>{
      console.log(err,"err");
      toast.open({
        type: "error",
        message: "Error",
        description: "Network error.",
      });
    })
  }

  const submitEdit = ()=>{
    const isAnySelected = stateValue.some(supplier => supplier.isSelected);

  if (!isAnySelected) {
   
    toast.open({
      type: "warning",
      message: "Failed",
      description: "Price not Selected.",
    }); return; 
  }
    saveSellingPrice();
    saveProducts();
    // if(quotesEditId){
    //   navigate(`/sales/quotes/edit/${quotesEditId}`,{state:{data:{...data}}})
    // }else{
    //   navigate(`/sales/quotes/create`);
    // }
  }
;


function ClickProducts(value){
   if(value.isSelected){
    return false
   }

   let Value = stateValue.filter(item => item.productId == value?.productId)
   let click = Value.some(x=> x.isSelected == true)
   return click
 }

  return (
    <div className="w-full h-full px-4 py-2 ">
     <div className=" w-[calc(100vw-280px)]  h-full  overflow-x-hidden">
     <div className="overflow-x-auto w-[calc(100vw-280px)] rounded-lg">
        <div className=" w-full min-h-[calc(100vh-350px)] max-h-[calc(100vh-320px)]  overflow-y-scroll">

{
  viewqoutesId !="" ?<>
  
  {
     (isLoading==true  || startLoading==true)?(
      // <div className="w-full h-full">
 <>
         <div className="w-full">
            {[...Array(5)].map((_, index) => (
              <div key={index} className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-200 h-10 w-10"></div>
                <div className="flex-1 space-y-6 py-1">
                  <div className="h-4 bg-slate-200 rounded"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-4 bg-slate-200 rounded col-span-2"></div>
                      <div className="h-4 bg-slate-200 rounded col-span-1"></div>
                    </div>
                    <div className="h-4 bg-slate-200 rounded"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
        // </div>
     )
     :
     (
      // <div className={ supplierList?.length>1 ? `w-[calc((100vw-100px)*1.5)]`:`w-[calc(100vw-250px)] overflow-x-scroll`}>
      <div className={supplierList?.length>10 ? `w-[calc((100vw-300px)*4.9)]`: supplierList?.length>6 ? `w-[calc((100vw-300px)*3.9)]`:supplierList?.length>4 ? `w-[calc((100vw-300px)*2.9)]`:supplierList?.length>1 ? `w-[calc((100vw-300px)*1.8)]`:supplierList?.length==1 ? `w-[calc((100vw-300px))]`:`w-full overflow-x-scroll relative`}>
          <table className="table-auto relative border-collapse border border-slate-300 z-0">
            <thead className="bg-baseColor-primary sticky top-0 text-white text-xs 2xl:text-sm z-[1]">
              <tr>
              <th colSpan={3} className=" w-[580px] py-1 sticky  left-0 bg-baseColor-primary z-10 border-r border-slate-400">
                  <div className=" flex  items-center">
                    <div className=" w-[80px] text-left pl-1">S.N</div>
                    <div className=" w-[300px] text-left">Product</div>
                    <div className=" w-[100px] text-left">Unit</div>
                    <div className=" w-[100px] text-left">Qty</div>
                  </div>
                </th> 
                
                {dynamicHeaders}
              </tr>
            </thead>
            <tbody className="">
              {datas && datas.map((item, idx) => (
                <tr
                  key={idx}
                  className="odd:bg-white even:bg-slate-50  text-xs 2xl:text-sm border-collapse border border-slate-300"
                >
                  <td colSpan={supplierList?.length>=1 ?3:0} className={`w-[580px] py-2 text-center sticky left-0 ${idx%2!=0?"bg-slate-50":"bg-white"} z-0 `}>
                  <div className=" w-full flex  items-center">
                    <div className=" w-[80px] text-left pl-1">{idx+1}</div>
                    <div className=" w-[300px] text-left line-clamp-2">{item.name}</div>
                    <div className=" w-[100px] text-left">{item.unit}</div>
                    <div className=" w-[100px] text-left">{item.requestedQty}</div>
                  </div>
                  </td>

                  {supplierList.map((sub, index) => {
                    const supplier = stateValue.find(
                      (i) => i.productId === item.id && i.quoteId === sub.quoteId
                    );
                    if (supplier) {
                      return (
                        <React.Fragment key={`dynamic-cell-${idx}-${index}`}>

                          <td className=" w-[500px]">
                            <div className="w-full">
                              <td
                                className={`py-2 text-center border-collapse border-x border-y-0 w-[200px]  border-slate-300`}
                              >
                                <input
                                  type="checkbox"
                                  checked={supplier.isSelected}
                                  disabled={ClickProducts(supplier)}
                                  onChange={(e) =>
                                    handleChange(
                                      e,
                                      supplier.vendorId,
                                      supplier.productId
                                    )
                                  }
                                />
                                {<NuAmount
                                defaultCurrency={false}
                                currency=""
                                  value={
                                    parseFloat(supplier?.LCSP).toFixed(2) || 0
                                  }
                                /> || "-"}
                              </td>
                              <td
                                className={`py-2 text-center border-collapse border-x border-y-0 w-[100px] border-slate-300`}
                              >
                                <div
                                  className=" w-full h-full  flex justify-evenly px-1"
                                >
                                  <input
                                    type="number"
                                    placeholder="0"
                                    className="w-full py-1 px-2 text-center focus:outline-none rounded cursor-pointer border-slate-300 border"
                                    value={gPEditShow === true ? parseFloat(supplier.gp)>0?parseFloat(supplier.gp):"": parseFloat(supplier.gp)}
                                    onChange={(e) => handleGPChange(e, supplier.quoteId, supplier.productId)}
                                    disabled={gPEditShow === true ? false : true}
                                  />
                                </div>
                              </td>
                              <td
                                className={`py-2 text-center border-collapse border-x border-y-0 w-[200px] ${supplier.isLow == true && supplier.isHigh == true
                                  ? "bg-green-100"
                                  : supplier.isHigh == true
                                    ? "bg-red-100"
                                    : supplier.isLow == true
                                      ? "bg-green-100"
                                      : ""
                                } border-slate-300`}
                              >
                                <div
                                  className=" w-full h-full"
                                >
                                  <NuAmount 
                                   defaultCurrency={false}
                                   currency=""
                                   value={supplier?.sellingPrice || 0} />
                                </div>
                              </td>
                            </div>
                          </td> 

                         
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={`dynamic-cell-${index}-${index}`}>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className=" border-slate-300"></td>
                        </React.Fragment>
                      );
                    }
                  })}
                </tr>
              ))}
              <tr className="bg-white text-xs 2xl:text-sm  font-bold">
  <td colSpan={3} className="py-2 text-center sticky left-0 bg-white z-[0]">
  
  </td>

  {supplierList.map((sub, supplierIndex) => {
  
    const totalPrice = stateValue
      .filter(i => i.quoteId === sub.quoteId) 
      .reduce((total, supplier) => total + parseFloat(supplier.sellingPrice || 0), 0); 

    return (
      <React.Fragment key={`total-row-${supplierIndex}`}>
        <td className="w-[500px]">
          <div className="w-full">
           
            <td className={`py-2 text-center  w-[200px] `}>
             
            </td>
            <td className={`py-2 text-center  w-[100px] `}>
             
            </td>
            <td className="py-2 text-center  w-[200px] px-2 ">
              <div className="flex gap-2 text-[10px] text-gray-500 justify-center">
                <span>Total:</span>
                <NuAmount value={totalPrice} /> 
              </div>
            </td>
          </div>
        </td>
      </React.Fragment>
    );
  })}
</tr>

            </tbody>
          </table>
          </div>
     )
}  
  
  </>:<div className=" w-full h-[calc(100vh-350px)] flex justify-center items-center"><p className=" font-semibold text-sm 2xl:text-base text-baseColor-primary">Please Choose Purchase Request</p></div>
}


        </div>
      </div>
        <div className=" w-full flex items-center px-4 h-[60px]">
          <div className=" w-[calc(84vw-240px)]">
          <div className=" flex gap-3 py-2 flex-wrap">
            <TextMediumXS color="text-red-700 py-2" content="Notes : "/>
              <span className=" flex gap-1 items-center">
                <TextSM color="text-blue-700" otherStyles="leading-3" content="You can edit GP " />
              </span>
              {/* <span className=" flex gap-1 items-center">
                <TextSM color="text-blue-700" otherStyles="leading-3" content="and GP. " />
              </span> */}
            </div>
            <div className=" flex gap-3 flex-wrap">
              <span className=" flex gap-1 items-center">
                <div className=" w-4 h-4 border border-solid border-green-500 rounded-full bg-green-100"></div>
                <TextXS content="Lowest Price  ," />
              </span>

              <span className=" flex gap-1 py-2 items-center">
                <div className=" w-4 h-4 border border-solid border-red-500 rounded-full bg-red-100"></div>
                <TextXS content="Highest Price. " />
              </span>
            </div>
          </div>
          <div className=" w-44 flex gap-3 justify-end">
            <CreateButton label="Save" handleClick={submitEdit} svg={false} />
            {/* <CreateButton label="Create Quotes" svg={false} /> */}
          </div>
        </div>
     </div>
    </div>
  );
}

export default ViewQuote;