// import React from "react";
// import {
//   TextMediumBase,
//   TextMediumSM,
//   TextMediumXS,
// } from "../../../../Components/Text/MediumText";
// import UseGetDashBoardData from "../../../../Components/Hooks/useDashboardData";

// function PurchaseRequestDashborad() {
//   const { data, loading } = UseGetDashBoardData(`/dashboard/purchaseRequest`);
//   return (
//     <div className="col-span-3 row-span-2 col-start-4 row-start-2 max-h-[35vh] bg-white p-2 rounded-lg drop-shadow-lg">
//       <div className=" w-full bg-emerald-500 px-1 py-[2px] rounded-md">
//         <TextMediumSM content={"Purchase Requests"} color="text-white" />
//       </div>
//       {loading == true ? (
//         <>
//           <div className=" p-4 max-w-sm w-full flex flex-col justify-center items-center h-full">
//             <div className="animate-pulse flex space-x-4 w-full">
//               <div className="flex-1 space-y-3 py-1">
//                 <div className="h-3 bg-slate-200 rounded"></div>
//                 <div className="space-y-3">
//                   <div className="grid grid-cols-3 gap-4">
//                     <div className="h-3 bg-slate-200 rounded col-span-2"></div>
//                     <div className="h-3 bg-slate-200 rounded col-span-1"></div>
//                   </div>
//                   <div className="h-3 bg-slate-200 rounded"></div>
//                   <div className="grid grid-cols-3 gap-4">
//                     <div className="h-3 bg-slate-200 rounded col-span-1"></div>
//                     <div className="h-3 bg-slate-200 rounded col-span-2"></div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </>
//       ) : (
//         <>
//           <div className=" text-center flex justify-between items-center w-full">
//             <TextMediumXS content={"Last Month Percentage"} />
//             <TextMediumXS
//               align="center"
//               content={`${parseFloat(data?.last_month_percentage).toFixed(2) } %`|| 0}
//             />
//           </div>
//           <div className=" w-full flex flex-col items-center h-[90%]">
//             <div className="  w-full my-2">
//               <TextMediumXS content={"Customers Category Wise :"} />
//               {data?.customer_category_wise?.map((item) => {
//                 return (
//                   <div className="flex justify-evenly my-1">
//                     <TextMediumXS
//                       align="center"
//                       content={`Category: ${item?.category || ""}`}
//                     />
//                     <TextMediumXS
//                       align="center"
//                       content={`Count: ${item?.count || 0}`}
//                     />
//                   </div>
//                 );
//               })}
//             </div>
//             <div className=" w-full my-2">
//               <TextMediumXS content={"Customers Category Status Wise :"} />
//               {data?.status_wise?.map((item) => {
//                 return (
//                   <div className="flex justify-evenly my-1">
//                     <TextMediumXS
//                       align="center"
//                       content={`Count: ${item?.count || 0}`}
//                     />
//                     <TextMediumXS
//                       align="center"
//                       content={`Status: ${item?.status || 0}`}
//                     />
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//         </>
//       )}
//     </div>
//   );
// }

// export default PurchaseRequestDashborad;

import React from "react";
import {
  TextMediumBase,
  TextMediumSM,
  TextMediumXS,
} from "../../../../Components/Text/MediumText";
import UseGetDashBoardData from "../../../../Components/Hooks/useDashboardData";
import NuAmount from "../../../../Components/Utils/NuAmount";

function PurchaseRequestDashborad() {
  const { data, loading } = UseGetDashBoardData(`/dashboard/purchaseRequest`);

  const totalRequests = data?.customer_category_wise?.reduce(
    (total, item) => total + (item.count || 0),
    0
  );

  return (
    <div className=" h-[40vh] w-full bg-white p-2 pb-10 rounded-lg drop-shadow-lg overflow-hidden mb-4">

      <div className="w-full px-1 py-[2px] rounded-md flex justify-between items-center p-4">
        <TextMediumSM content={"Requests from Customers"} color="text-[#4F4768]" />
        <div className="bg-[#95D2B3] px-2 py-0.5 rounded-md">
        
        <p className='text-[#333333] text-[12px] font-semibold '><NuAmount
         value={totalRequests || 0}/></p>
</div>
        {/* <TextMediumSM content={` ${totalRequests || 0}`} className="font-bold text-[20px] text-[#333333]" /> */}
      </div>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>

      {loading ? (
        <div className="p-4 max-w-sm w-full flex flex-col justify-center items-center h-full">
          <div className="animate-pulse flex space-x-4 w-full">
            <div className="flex-1 space-y-3 py-1">
              <div className="h-3 bg-slate-200 rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                  <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                </div>
                <div className="h-3 bg-slate-200 rounded"></div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                  <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="  h-[96%]  overflow-y-scroll ">
<div className="grid">
       {/* <div className="flex flex-col items-center h-full p-1 text"> */}
         {/* <div className="flex justify-around w-full text-center items-center"> */}
            {/* {data?.customer_category_wise?.map((item, index) => (
              <div style={{ borderRight: "1px solid #F0F0F0", paddingRight: "5px" }}

               className="flex flex-col items-center p-2" key={index}>
        <p className='text-[#333333] text-base  2xl:text-[22px] font-semibold '>{`${item.count || 0}`}</p>
          <p className=' text-xs 2xl:text-sm '>{`Category ${item.category || ""}`}</p>
               <p className="text-xs 2xl:text-sm">{item.difference||""}</p> */}
         
          {data?.customer_category_wise?.map((item, index) => (
              <div key={index} className="flex  justify-evenly p-2">
                <div>
                <p className='text-xs 2xl:text-sm'>
  {item.category ? `${item.category.charAt(0).toUpperCase() + item.category.slice(1).toLowerCase()}` : ""}
</p>  <p className='text-[#048178] text-[10px] 2xl:text-xs text-start'>{item.difference||""}</p>
              </div>
              <div>
              <p className='text-[#333333] text-base  2xl:text-[22px] font-semibold text-center'>{`${item.count || 0}`}</p>
          {/* <p className='text-[#ACACAC] text-[10px] 2xl:text-xs font-light'> {item.currency || ""} <span>{item.price}</span></p> */}
          </div>
          </div>              
            ))}
          </div>
          </div>
          
         
      )}
    </div>
  );
}

export default PurchaseRequestDashborad;










