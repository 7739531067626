import React, { useEffect, useState } from 'react'
import { NormalHeading } from '../../../../Components/Utils/Heading';
import { BackButton, CancelButton, CreateButton, EditButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import SideModal from '../../../../Components/Others/SideModel';
import DefaultDownloadLink from './pdf/DefaultDownloadLink';
import NuDate from '../../../../Components/Utils/NuDate';
import ViewPdf from './ViewPdf';
import Comments from './CommentsPr';
import CommonComments from '../../../../Components/Utils/commonComments';
import ApprovalComponent from '../../../../Components/Utils/approvalComponent';
import axiosInstance from '../../../../Services/axiosCommon';
import NuAmount from '../../../../Components/Utils/NuAmount';
import POPdfOther from '../../ViewPdfTemplate/POPdfOther';
import POPdfNu from '../../ViewPdfTemplate/POPdfNu';
import PDFViewer from '../../../../Components/Utils/PDFViewer';

function ViewPo() {
  const { poViewId } = useParams();
  const location = useLocation();
  const [data,setData] = useState(location.state.data);
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [showPdfComponent, setShowPdfComponent] = useState(false);

  const handleOpenCommentsModal = () => {
    setIsCommentsModalOpen(true);
  };

  const handleCloseCommentsModal = () => {
    setIsCommentsModalOpen(false);
  };
  const handlePdfIconClick = () => {
    setShowPdfComponent(true);
  };
  const closeFilterDialog = () => {
    setShowPdfComponent(false);
  };

  const id = poViewId; 
  const getUrl = `/PO/get-status`;
  const putUrl = `/PO/edit-status`; 
  const statusUrl = '/PO/all-status';
  const undoUrl = `/PO/undo-status`; 
  const editUrl = '/PO/edit-reason';



  const downloadPDF = async () => {
    try {
        const response = await axiosInstance.get(`/PO/download-pdf/${poViewId}`, {
            responseType: 'blob' // Important to specify the response type as blob
        });

        // Create a Blob from the response
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

        // Create a link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = `${data.ref}.pdf`; // The filename you want for the downloaded file

        // Append the link to the body (required for Firefox)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

    } catch (error) {
        console.error('Error downloading the PDF:', error);
    }
};

const [viewPDF,setViewPDF] = useState(null);

const ViewPDF = async () => {
  try {
      const response = await axiosInstance.get(`/PO/download-pdf/${poViewId}`, {
          responseType: 'blob' // Important to specify the response type as blob
      });

      // Create a Blob from the response
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

      // Create a link element
      let Viewlink = window.URL.createObjectURL(pdfBlob);
      setViewPDF(Viewlink);

  } catch (error) {
      console.error('Error downloading the PDF:', error);
  }
};
console.log(data?.shipmentDetails?.ETA,"kjhgc");

useEffect(() => {
  ViewPDF()
},[])


return (
  <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb 
       items={[
        { name: "Purchase Order",
            link:"/procurement/purchase-order/"},
            
          { name: "View"},
  ]} />
       <div className=" flex gap-2">
          <Link to={'/procurement/purchase-order'}><BackButton link={true}/></Link>
          <CreateButton svg={false} handleClick={downloadPDF} label="Download as PDF" />
          {/* <DefaultDownloadLink
            data={data}
            fileName={`Purchase Order_${data.ref}_${NuDate({
              value: Date(),
              format: "DD-MMM-YYY",
            })}.pdf`}
          /> */}
          <Link to={`/procurement/purchase-order/edit/${poViewId}`} 
          state={{data: data, from:`/procurement/purchase-order/view/${poViewId}`}}
          ><EditButton height='8'/></Link>
                 {/* <button className={` cursor-pointer text-white bg-[#048178] rounded-md inline-flex w-auto h-8 px-2 flex-shrink-0 items-center justify-center`} onClick={handleOpenCommentsModal}>Comments</button> */}

        </div>
    </div> 
    <div className="w-full mx-auto h-[calc(100vh-125px)]  rounded-md overflow-hidden p-2   flex gap-2 text-[#111537]">
        <div className="w-[70%]  bg-white rounded-lg drop-shadow-lg py-1 overflow-hidden ">
        <div className="w-[98%]  bg-white px-2 mx-2 border border-gray py-2 rounded-lg flex justify-between items-center  z-10">  
       
       <div className='grid items-center px-2 w-[90%]'>
      {/* <NormalHeading title={"Purchase Order"} />  : */}
      <div className="flex text-[14px] mb-1 text-[#111537] font-semibold w-[90%]">
            <p className="">{data.ref}</p>
            {/* <p className=""> : {data?.description || 'None'}</p> */}
            </div>
              <div className="flex gap-4">
            <p className="text-xs text-[#111537]">Date : {data?.createdAt ? (
    <NuDate value={data?.createdAt} format="DD-MMM-YYYY, hh:mm A" /> 
  ) : (
    "N/A"
  )}
         </p>
         <p className="text-xs text-[#111537]"> Created By : {data.preparedByName || 'None'}</p>
         {/* <p className="bg-[#579BB1] text-white items-center rounded-md text-xs p-[2px]"> Status : {data.status || 'None'}</p> */}

         </div>
       {/* <p className="text-xs grid pt-5"> {data.ref} <span className="font-light text-[11px] px-2">{data?.date ? (
                  <NuDate value={data?.date} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</span></p> */}
                
      </div>
      {/* <p className=" p-2  bg-[#F0DE36] text-white items-center rounded-md text-xs h-8 ">
                {data.status || 'None'}
              </p> */}
               <div className="grid gap-2  justify-end">
               <div className="border rounded-md mr-3  text-xs inline-flex w-auto  flex-shrink-0  justify-center">
            <p className="  bg-white  text-black  p-1 rounded-l-md  ">
              Status  </p><p className="bg-[#579BB1] text-white p-1 items-center rounded-r-md"> {data.status || 'None'}</p>
             
              </div>
       
         {/* <p className=" p-[2px]  bg-[#579BB1] text-white items-center rounded-md text-xs inline-flex w-auto h-5 px-2 mr-3 flex-shrink-0  justify-center ">
            {data.status || 'None'}
           </p> */}
       </div>  
              </div>
          <div className="h-[calc(100vh-200px)] overflow-y-auto p-2 pb-8">

          <div className=" w-full flex flex-wrap   border border-gary mt-2 px-2 rounded-lg ">
          <div className=" w-full grid grid-cols-2 gap-x-8 ">
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Purchase Request</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data?.prRrf}</p>
                </div> */}
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Date</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data.date}</p>
                </div>  */}
                
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Total Price</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data.totalPrice}</p>
                </div> */}
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Supplier</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data.vendorName}</p>
                </div> */}
                 <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Requested Delivery Date</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data.requestedDeliveryDate? (
                  <NuDate value={data?.requestedDeliveryDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
                </div>
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Payment Terms</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data.paymentTerms}</p>
                </div> */}
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Delivery Terms</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data.deliveryTerms}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Payment Status</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data.paymentStatus}</p>
                </div>
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Billing Address</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279] '>: {data.billingAddress}</p>
                </div>
                 <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'> Delivery Address</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data.deliveryAddress}</p>
                </div> */}
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'> Currency</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {String(data?.vendorCurrency).toUpperCase()}</p>
                </div>
                {data?.isEmailSent===true && (
                  <>
           <div className='w-full flex px-2'>
  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Email Sent Date & Time</p>
  <p className='w-[65%] py-2 text-xs text-[#606279]'>
    : {data?.emailSentDateTime ? (
      <NuDate value={data?.emailSentDateTime} format="DD-MMM-YYYY, hh:mm A" /> 
    ) : (
      "N/A"
    )}
  </p>
</div>

           
            </>
            )}
  
                {/* {data && (
<>
{data.requestedByName   &&           
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Requested By</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data.requestedByName}</p>
                </div>}
                {data.approvedByName &&
                <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Approved By</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data.approvedByName}</p>
                </div>
}
                </>
                 )}
                 */}
                             
                     
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'> Status</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>{data.status}</p>
                </div> */}
                
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Approved Status</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>{data.approvalStatus}</p>
                </div> */}
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Comments</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>{data.comments}</p>
                </div> */}
                
              </div>
              
              </div>
              {data?.shipmentDetails && (
                <>
<p className='w-full pt-4 3x-4 ml-2 text-xs font-semibold text-[#111537]'>Booking Details</p>

<div className='w-full flex flex-wrap   border border-gary mt-2 px-2 rounded-lg "'>
<div className=" w-full grid grid-cols-2 gap-x-8 ">
 
{data?.shipmentDetails?.bookingDate   &&    (
 <div className='w-full flex px-2'>
 <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'> Booking Date</p>
 <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data?.shipmentDetails?.bookingDate? (
                  <NuDate value={data?.shipmentDetails?.bookingDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
</div>
)}
{data?.shipmentDetails?.agent   &&    (
 <div className='w-full flex px-2'>
 <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'> Agent</p>
 <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data?.shipmentDetails?.agentName}</p>
</div>
)}
{data?.shipmentDetails?.modeOfShipment   &&    (
 <div className='w-full flex px-2'>
 <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'> Mode Of Shipment</p>
 <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data?.shipmentDetails?.modeOfShipment}</p>
</div>
)}
{data?.shipmentDetails?.permitReq   &&    (
 <div className='w-full flex px-2'>
 <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'> Permit Required?</p>
 <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data?.shipmentDetails?.permitReq  ?  data?.shipmentDetails?.permitReq ? "Yes" : "No" : '-'}</p>
</div>
)}
{data?.shipmentDetails?.ETD   &&    (
 <div className='w-full flex px-2'>
 <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'> ETD </p>
 <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data?.shipmentDetails?.ETD? (
                  <NuDate value={data?.shipmentDetails?.ETD} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
</div>
)}
{data?.shipmentDetails?.ETA   &&    (
 <div className='w-full flex px-2'>
 <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'> ETA</p>
 <p className='w-[65%] py-2 text-xs font-medium text-[#606279]'>: {data?.shipmentDetails?.ETA? (
                  <NuDate value={data?.shipmentDetails?.ETA} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
</div>
)}

</div>
</div>
</>
)}
              <div className="flex gap-4 mt-4">
          <div className="w-[50%] ">
        <p className="  text-xs font-semibold text-[#111537] p-1">
        Billing Address
            </p>
        <div className="  border border-gary  h-28 overflow-y-scroll rounded-lg items-center">
           
            <p className="  text-xs p-2 break-words text-[#606279]">
              {data.billingAddress || "-"}
            </p>
          </div>
         </div>
          <div className="w-[50%] ">
          <p className=" p-1 text-xs font-semibold text-[#111537]">
          Delivery Address
            </p>
          <div className=" border border-gary  overflow-y-scroll h-28 rounded-lg p-2">
          <p className="  text-xs p-2 break-words text-[#606279]">
              {data.deliveryAddress || "-"}
            </p>
    </div>
    </div>
    </div>
            

              {/* <div className="flex gap-4 mt-4">
          <div className="w-[50%] ">
        <p className="  text-xs font-medium text-[#111537] p-1">
              Notes from Customer
            </p>
        <div className="  border border-gary  h-28 overflow-y-scroll rounded-lg items-center">
           
            <p className="  text-xs p-2 break-words text-[#606279]">
              {data.notesFromCus || "-"}
            </p>
          </div>
          </div> */}
             
        

        {/* <div onClick={handlePdfIconClick} className="cursor-pointer m-2 border border-gary p-4 rounded-lg w-[10%]">
        <svg
            width="30"
            height="30"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
        </div> */}
        {/* </div> */}
            {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div> */}
                

            <div className="w-full bg-white border border-gary  mt-4 rounded-lg ">
            <div className="w-full py-2 bg-baseColor-primary flex justify-between rounded-lg px-2">
            {/* <p className=" w-[15%] text-left pl-1 text-white text-xs font-medium p-1">Item Code </p> */}
            <p className="w-[5%]  text-center   text-white text-xs font-medium p-1">S.N. </p>

            <p className=" w-[50%] text-left  text-white text-xs font-medium p-1"> Product </p>
            <p className=" w-[5%] text-center text-white text-xs font-medium p-1"> Unit</p>
            <p className=" w-[10%] text-center text-white text-xs font-medium p-1">  Req.Qty</p>
            
            <p className=" w-[10%] text-center text-white text-xs font-medium p-1"> Ordered Qty </p>
         
            <p className=" w-[10%] text-center text-white text-xs font-medium p-1">  Price</p>
           
            <p className=" w-[10%] text-center text-white text-xs font-medium p-1">  Neg.Price</p>
            <p className=" w-[10%] text-center text-white text-xs font-medium p-1">
                Total
                </p>
            {/* <p className=" w-[15%] text-left text-white text-xs font-medium p-1"> Product Category</p> */}
           
            </div>
            {data?.products?.length>0 && data?.products?.map((List, index) => {
              return (
                <div key={index} className="w-full py-2 flex justify-between odd:bg-white even:bg-slate-100 px-2">
                    {/* <p className=" w-[15%] text-left pl-1 text-[#606279] text-xs font-light py-1">
                  {List?.item_code}
                </p> */}
                 <p className="w-[5%]  text-center pl-1 text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                 <p className=" w-[50%] text-left  text-[#606279] text-xs font-light py-1">
                  {List?.product_name}
                </p>
                <p className=" w-[5%] text-center text-[#606279] text-xs font-light py-1">
                  {List?.product_unit}
                </p>
              
                <p className=" w-[10%] text-center text-[#606279] text-xs font-light py-1">
                  {List?.requestedQty}
                </p> 
                <p className=" w-[10%] text-center text-[#606279] text-xs font-light py-1">
                  {List?.orderedQty}
                </p>
                <p className="w-[10%] text-center text-[#606279] text-xs font-light py-1">
  {(Number(List?.price) || 0).toLocaleString(undefined, { 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2 
  })}
</p>

                <p className="w-[10%] text-center text-[#606279] text-xs font-light py-1">
  {(Number(List?.negotiatedPrice) || 0).toLocaleString(undefined, { 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2 
  })}
</p>


              
                <p className="text-center w-[10%] text-[#606279] text-xs font-light py-1">
  {(List.negotiatedPrice * List.orderedQty).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
</p>
                </div>
              );
            })}
            <div className="w-full flex px-4 py-2 justify-end">
          <div className="flex">
            <p className=" py-3 text-xs font-medium text-[#111537]">
              Total Price 
            </p>
            <p className=" py-3 text-xs font-medium text-[#606279]">
             : <NuAmount currency={String(data?.vendorCurrency).toUpperCase()} value= {data.totalPrice} />
            </p>
          </div>
        </div>
          </div>
          {/* <div className="w-full flex px-2 py-2">
          <div className="w-1/4 flex">
            <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
              Total Price :
            </p>
            <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
              {data.totalPrice}
            </p>
          </div>
          </div> */}
          </div>
          </div>
          {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
          
            <div className='w-[55%] flex px-2 border border-gary mt-3'>
                  <p className=' py-3 text-xs font-medium text-[#111537]'>Notes From Customer</p>
                  <p className=' py-3 text-xs pl-5 text-wrap text-[#606279]'>{data.notesFromCus}</p>
                </div> */}
         <div className="w-[30%] bg-white rounded-lg drop-shadow-lg py-1">
        <div className="h-[98%] overflow-y-scroll p-2 ">
        {/* <div className=" border border-gary   py-2 px-1 rounded-lg">
         <p className="text-xs text-[#111537] font-medium">Approval
         <span>
                   : {data.approvalStatus || "None"}
                </span>
         </p>
          <div className="flex gap-4 mt-4 justify-around">
          <p className=" px-2 py-2 bg-green-500 hover:cursor-pointer text-white items-center rounded-md text-xs text-center  h-7  ">
               Approve
              </p>
              <p className=" px-2 py-2 bg-red-500 hover:cursor-pointer text-white items-center rounded-md text-xs text-center  h-7  ">
              Decline
              </p>
          </div>
         </div> */}
<ApprovalComponent id={id} getUrl={getUrl} putUrl={putUrl} statusUrl={statusUrl}  undoUrl={undoUrl} editUrl={editUrl} refSetData={setData}  data={data} type={"PO"} preUrlName={"PO"}/>

         <div className="w-full grid  border border-gary mt-4  rounded-lg">
          {/* <p className="text-xs text-[#111537] font-medium">Supplier Detail</p> */}
          <div className="bg-baseColor-primary  p-2 rounded-t-lg">
            <p className="text-xs text-white font-medium">Supplier Detail</p>

            </div>
          <div className='w-full flex px-2 justify-between'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Purchase Request</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279] truncate'>: {data?.prRrf}</p>
                </div>
            <div className='w-full flex px-2 justify-between'>
                  <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Supplier</p>
                  <p className='w-[65%] py-2 text-xs font-medium text-[#606279] truncate'>: {data.vendorName}</p>
                </div>
           </div>
           <div className="w-full grid  border border-gary mt-4  rounded-lg ">
           <p className="text-xs text-white font-medium bg-baseColor-primary  p-2 rounded-t-lg">Documents </p>
           <div className=" w-full flex flex-wrap overflow-y-scroll h-16">
           {/* <div onClick={handlePdfIconClick} className="cursor-pointer m-2   ">
          <svg
            width="30"
            height="30"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
        </div> */}

        <div onClick={handlePdfIconClick} className="cursor-pointer m-1 w-auto h-[25px] flex items-center  justify-between  rounded p-[2px] border ">
          <svg
            width="20"
            height="20"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
          <p className=' w-auto  text-[9px]'>{data?.ref}</p>
        </div>
        </div>
        </div>
           <div className="w-full h-auto  flex  border border-gary mt-4 overflow-hidden rounded-lg">
         <CommonComments
            visible={true}
            // closeModal={handleCloseCommentsModal}
            title="Comments"
            mainId={poViewId}
            addurl={'/po-comments/new-comment'}
            geturl={`/po-comments/by-PO/${poViewId}`}
          />
         </div>
          </div>
         </div>
            
      </div>
      {showPdfComponent && (
  <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
  <div className="animate-scale-in relative w-[98%] h-[98%] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
    <button
              className="absolute top-2 right-2 text-white bg-red-600"
              onClick={() => setShowPdfComponent(false)}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <PDFViewer pdfFile={viewPDF}/>

      {/* <div className='border border-solid-black'> */}
      {/* <POPdfOther data={data} /> */}
      {/* <POPdfNu/> */}
      {/* <div className="flex justify-end gap-2 w-[50%] mx-auto mt-4"> */}
                {/* <CancelButton handleClick={closeFilterDialog} /> */}
                {/* <SubmitButton name="Apply" /> */}
              {/* </div> */}
              {/* </div> */}
    </div>
  </div>
)}
      {/* {isCommentsModalOpen && (
        <SideModal
          visible={isCommentsModalOpen}
          closeModal={handleCloseCommentsModal}
          title="Comments"
          mainId={poViewId}
          addurl={'/po-comments/new-comment'}
          geturl={`/po-comments/by-PO/${poViewId}`}
        />
      )} */}
   
  </div>
)
};
export default ViewPo