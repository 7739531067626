import React, { useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../Components/Utils/Buttons';
import FormikSelectTwo, { SelectCountry } from '../../../Components/Utils/Select';
import { TextMediumSM } from '../../../Components/Text/MediumText';
import { NuInput, NuMobileNumber, NuTextArea, NuUpload } from '../../../Components/Utils/Input';
import Breadcrumb from '../../../Components/Utils/Breadcrumb';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import useEditFormData from '../../../Components/Hooks/useEditFormData';
import * as yup from 'yup';
import CustomImgUpload from '../../../Components/Utils/CustomImgUpload';

const EditUserprofile = () => {
    const location = useLocation();
    const data = location?.state?.data;
    const from = location?.state?.from;
    const { Oncancel, editFormData, contextHolder, isLoading } =
      useEditFormData();
    const [image, setImage] = useState({ preview: "", raw: "" });
    const [createNew, setCreateNew] = useState({
      city: data?.city||"",
      streetName: data?.streetName||"",
      district: data?.district||"",
      state: data?.state||"",
      country: data?.country||"",
      postalCode: data?.postalCode||"",
      name: data?.name||"",
      designation: data?.designation||"",
      division: data?.division||"",
      email: data?.email||"",
      password: data?.password||"",
      phone: data?.phone.number,
      countryCode: data?.phone.countryCode,
      status: data?.status||"",
      userImage: data?.userImage?.file,
      address1:data?.address1,
      address2:data?.address2
      
    });
  
    const handleChangeImg = (e) => {
      if (e.target.files.length) {
        setImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        });
      }
    };
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        email: yup.string().required("Email is required!"),
        name: yup.string().required("Name  is required!"),
     
      }),
      onSubmit: async (value, { resetForm }) => {
        let formData = new FormData();
        formData.append("json_data", JSON.stringify(value));
        formData.append("userImage", image.raw);
        editFormData(
          `/user/edit-user-details/${data?.userId}`,
          formData,
          resetForm,
          from
        );
      },
    });
  
    const handleDeleteImg = () => {
        setImage({
          preview: "",
          raw: "",
        });
        setCreateNew(prevState => ({ ...createNew, userImage: "" }))
        formik.values.userImage = ''
      }

    return (
      <div className="w-full h-full px-4 py-2">
        <div className="w-full flex justify-between items-center mb-4">
          <Breadcrumb
            items={[
             
              {
                name: "User Profile",
              },
              {
                name: "Edit",
              },
            ]}
          />
          <BackButton />
        </div>
        <div className="w-full h-[calc(100vh-145px)] overflow-y-auto">
      <form onSubmit={formik.handleSubmit}>
      
      
      <div className="w-full mx-auto pb-5 bg-white rounded-md ">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  mb-4">
          
                   <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  Personal Info :
  </p>
</div>  
<CustomImgUpload
            borderStyle="dashed"
            handleChange={handleChangeImg}
            label={'User Profile'}
            preview={image.preview || createNew.userImage || ""}
            removeFunction={handleDeleteImg}
          />
     <NuInput
                type="text"
                label="Name"
                placeholder="Enter Name"
                formik={formik}
                name="name"
                isRequired={true}
                width="w-full md:w-1/3 md:-mt-2"
              />
              <NuInput
                type="email"
                label="Email"
                placeholder="Email"
                formik={formik}
                name="email"
                isRequired={true}
                width="w-full md:w-1/3 md:-mt-2"
              />
              <NuMobileNumber
                nunCode="countryCode"
                label="Phone"
                placeholder="Phone"
                formik={formik}
                name="phone"
                width="w-full md:w-1/3 md:-mt-2"
              />
              {/* <NuUpload
                label="User Image"
                width="w-full md:w-1/3"
                onChange={handleChangeImg}
              /> */}
              </div>
           </div>
          
          
          
          
           <div className="w-full mx-auto pb-4  bg-white rounded-md  mt-4 ">
          <div className=" w-full  flex justify-start content-start flex-wrap   pb-6">
       
          <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
    Address
  </p></div>
              <NuInput
                type="text"
                label="Building / Street Name"
                placeholder="Type Here"
                formik={formik}
                name="streetName"
                width="w-full md:w-1/3 md:-mt-2"
              />
              <NuInput
                type="text"
                label="City"
                placeholder="Enter City"
                formik={formik}
                name="city"
                width="w-full md:w-1/3 md:-mt-2"
              />
              <NuInput
                type="text"
                label="District"
                placeholder="Enter District"
                formik={formik}
                name="district"
                width="w-full md:w-1/3 md:-mt-2"
              />
              <NuInput
                type="text"
                label="State"
                placeholder="Enter State"
                formik={formik}
                name="state"
                width="w-full md:w-1/3 md:-mt-2"
              />
              <SelectCountry
                formik={formik}
                name="country"
                width="w-full md:w-1/3 md:-mt-2"
              />
              <NuInput
                type="number"
                label="Zip Code"
                placeholder="Type Here"
                formik={formik}
                name="postalCode"
                width="w-full md:w-1/3 md:-mt-2"
              />
   <NuTextArea
                label="Address 1"
                type="text"
                formik={formik}
                placeholder="Address 1"
                name="address1"
                width="w-full md:w-1/2 lg:w-1/3"
              />
             
             <NuTextArea
                label="Address 2"
                type="text"
                formik={formik}
                placeholder="Address 2"
                name="address2"
                width="w-full md:w-1/2 lg:w-1/3"
              />
             
              <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
                <div className="md:flex justify-end gap-4">
                  <CancelButton
                    handleClick={() => {
                      Oncancel(-1);
                    }}
                  />
                  <SubmitButton />
                </div>
              </div>
            </div>
          </div>
        </form>
        </div>
      </div>
    );
  }
  

export default EditUserprofile