import React, { useState, useEffect } from 'react';
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import BarChartView from '../../../../Components/Charts/BarChart';
import UseGetDashBoardWithParams from '../../../../Components/Hooks/useDashboardDataParams';

export default function ReceivablePayableChart() {
 
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().toLocaleString('default', { month: 'long' });

  const [params, setParams] = useState({
   
    month: currentMonth,
    year: currentYear,
  });

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  
  const { data, loading } = UseGetDashBoardWithParams(`/dashboard/financeStats`, params);

  
  const handleMonthChange = (e) => {
    const newMonth = e.target.value;
    setParams({ ...params, month: newMonth });
    setSelectedMonth(newMonth);
  };

  
  const handleYearChange = (e) => {
    const newYear = e.target.value;
    setParams({ ...params, year: newYear });
    setSelectedYear(newYear);
  };

 
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  
  const years = Array.from(new Array(10), (val, index) => currentYear - 5 + index);

  return (
    <div className="col-span-3 bg-white max-h-[40vh] w-full p-4 rounded-lg drop-shadow-lg mb-4">
      <div className="w-full px-1 py-[2px] rounded-md flex justify-between">
        <TextMediumSM content={"Invoice"} color="text-[#4F4768]" />
        
        <div className="text-center text-xs text-[#304463] flex space-x-2">
          <select
            value={selectedMonth}
            onChange={handleMonthChange}
            className="border rounded-md p-1 text-sm text-[#ACACAC] font-normal"
          >
            {months.map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
            
          <select
            value={selectedYear}
            onChange={handleYearChange}
            className="border rounded-md p-1 text-sm text-[#ACACAC] font-normal"
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full "></div>

      {/* {loading ? <p>Loading...</p> : */}
       <BarChartView data={data} />
       {/* } */}
    </div>
  );
}


