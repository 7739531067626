import React from "react";
import Breadcrumb from "../../../Components/Utils/Breadcrumb";
import { AppointmentsDashboard, CustomersDashboard, InventoryDashboard, LeadsDashboard, MaterialShipmentDashboard, OrdersbySalesmenDashboard, PayablesDashboard, ProductsDashboard, PurchaseOrdersfromCustomers, PurchaseOrderstoSuppliers, PurchaseRequestDashborad, QuotestoCustomersDashboard, ReceivablePayableChart, ReceivablesDashboard, RequestsDashboard, ReturnsDashboard, SalesOrdersDashboard, ServiceMaintenanceDashboard, SuppliersDashboard, TaskDashBoard, TopCustomersDashboard, TopSuppliersDashboard } from "./Components";
import Difference from "./Components/Difference";
import Approvals from "./Components/Approval";
import AlertDashborad from "./Components/AlertDashborad";
import SalesOrderSales from "./Sales/Components/SalesOrderSales";
// import ApprovalOverAll from "./Components/ApprovalOverAll";


function Dashboard() {
  return (
<div className="w-[100%] h-[calc(100vh-90px)] py-2  ">

 
<div className="grid grid-cols-3 grid-rows-2 gap-4 w-full max-h-[44vh] 2xl:max-h-[33vh] mb-4">
    <div > <CustomersDashboard /></div>
    <div className=""> <SuppliersDashboard /></div>
    <div className="row-span-2"> <AlertDashborad /></div>
    <div className=""> <PurchaseOrderstoSuppliers /> </div>
    <div className="" > <SalesOrderSales /></div>
</div>
  <div className="w-full grid grid-cols-4 gap-4 ">

<Approvals/>
</div>
    <div className="w-full grid grid-cols-3 gap-4 ">
    <PurchaseRequestDashborad/>
    <QuotestoCustomersDashboard/>
    <PurchaseOrdersfromCustomers/>

    </div>
    <div className="w-full grid grid-cols-5 gap-4 ">

    <ReceivablePayableChart/>

    <Difference/>
    </div>
  
   
    {/* <CustomersDashboard/>
    <SuppliersDashboard/>
    <TaskDashBoard/>
    <OrdersbySalesmenDashboard/>
    <PurchaseRequestDashborad/>
    <QuotestoCustomersDashboard/>
    <ReceivablePayableChart/>
    <ReceivablesDashboard/>
    <PayablesDashboard/>
    <MaterialShipmentDashboard/>
    <TopCustomersDashboard/>
    <TopSuppliersDashboard/>
    <ProductsDashboard/>
    <PurchaseOrdersfromCustomers/>
    <SalesOrdersDashboard/>
    <PurchaseOrderstoSuppliers/>
    <AppointmentsDashboard/>
    <LeadsDashboard/>
    <InventoryDashboard/>
    <RequestsDashboard/>
    <ServiceMaintenanceDashboard/>
    <ReturnsDashboard/> */}

    {/* <div className="col-span-3">1</div> */}
    {/* <div className="col-span-3 col-start-4">2</div> */}
    {/* <div className="col-span-3 col-start-7">3</div> */}
    {/* <div className="col-span-3 row-span-2 row-start-2">4</div> */}
    {/* <div className="col-span-3 row-span-2 col-start-4 row-start-2">5</div> */}
    {/* <div className="col-span-3 row-span-2 col-start-7 row-start-2">6</div> */}
    {/* <div className="col-span-5 row-span-2 row-start-4">7</div> */}
    {/* <div className="col-span-2 row-span-2 col-start-6 row-start-4">8</div> */}
    {/* <div className="col-span-2 row-span-2 col-start-8 row-start-4">9</div> */}
    {/* <div className="col-span-2 row-span-2 row-start-6">10</div> */}
    {/* <div className="col-span-2 row-span-2 col-start-3 row-start-6">11</div> */}
    {/* <div className="col-span-5 row-span-2 col-start-5 row-start-6">12</div> */}
    {/* <div className="col-span-3 row-span-2 row-start-8">13</div> */}
    {/* <div className="col-span-3 row-span-2 col-start-4 row-start-8">14</div> */}
    {/* <div className="col-span-3 row-span-2 col-start-7 row-start-8">15</div> */}
    {/* <div className="col-span-3 row-span-2 row-start-10">16</div> */}
    {/* <div className="col-span-3 row-span-2 col-start-4 row-start-10">17</div> */}
    {/* <div className="col-span-3 row-span-2 col-start-7 row-start-10">18</div> */}
    {/* <div className="col-span-3 row-span-2 row-start-12">19</div> */}
    {/* <div className="col-span-3 row-span-2 col-start-4 row-start-12">20</div> */}
    {/* <div className="col-span-3 row-span-2 col-start-7 row-start-12">21</div> */}
    {/* <div className="col-span-3 row-span-2 row-start-14">22</div> */}
</div>
  );
}

export default Dashboard;
