import { useEffect, useState } from "react";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import SearchBar from "../../../../Components/Utils/SearchBar";
import {
  CancelButton,
  CreateButton,
  FilterButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import DataTable from "../../../../Components/Others/DataTable";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { useFormik } from "formik";
import * as yup from 'yup';
import { NuDate } from "../../../../Components/Utils/Input";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import Importdoc from "./Importdoc";
import ModalContainer from "../../../../Components/Others/ModalContainer";
import MultiEditST from "./MultiEditST";
import { GetAccess } from "../../../../Components/Utils/roles";
import useFetchMDIndex from "../../../../Components/Hooks/useFetchMDIndex";

function ShipmentTrackingIndex() {
 
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(20);
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const {value:PortOfDischarge} = useFetchMDIndex(`/dropdown/dropdown-details/`,'PortOfDischarge',showFilterDialog)
  const {value:PortOfLoading} = useFetchMDIndex(`/dropdown/dropdown-details/`,'PortOfLoading',showFilterDialog)
  const {value:ShippingMethod} = useFetchMDIndex(`/dropdown/dropdown-details/`,'ShippingMethod',showFilterDialog)
  const {value:ShippingStatus, getData: refShippingStatus } = useFetchMDIndex(`/dropdown/dropdown-details/`,'ShippingStatus',showFilterDialog)
  const {value:IdentityType} = useFetchMDIndex(`/dropdown/dropdown-details/`,'ShipIdentityType',showFilterDialog)

  const [filterProps, setFilterProps] = useState({
    shippedOnFrom:"",
    shippedOnTo:"",
    ETAFrom:"",
    ETATo:"",
    shipmentStatus:"",
    ETDFrom:"",
    ETDTo:"",
    identityType:"",
    modeOfShipment:"",
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:"",
    shippedOnFrom:"",
    shippedOnTo:"",
    ETAFrom:"",
    ETATo:"",
    shipmentStatus:"",
    ETDFrom:"",
    ETDTo:"",
    identityType:"",
    modeOfShipment:"",
  });

  const clearFilter = ()=> {
    setParams({...params,  shippedOnFrom:"",
      shippedOnTo:"",
      ETAFrom:"",
      ETATo:"",
      shipmentStatus:"",
      ETDFrom:"",
      ETDTo:"",
      identityType:"",
      modeOfShipment:"",})
      setFilter(false)
      formik.values.shippedOnFrom=""
      formik.values.shippedOnTo=""
      formik.values.ETAFrom=""
      formik.values.ETATo=""
      formik.values.shipmentStatus=""
      formik.values.ETDFrom=""
      formik.values.ETDTo=""
      formik.values.identityType=""
      formik.values.modeOfShipment=""     
}

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,shippedOnFrom:value.shippedOnFrom, 
        shippedOnTo:value.shippedOnTo,
        ETAFrom:value.ETAFrom,
        ETATo:value.ETATo,
        shipmentStatus:value.shipmentStatus,
        ETDFrom:value.ETDFrom,
        ETDTo:value.ETDTo,
        identityType:value.identityType,
        modeOfShipment:value.modeOfShipment,
        })
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        shippedOnFrom:"",
    shippedOnTo:"",
    ETAFrom:"",
    ETATo:"",
    shipmentStatus:"",
    ETDFrom:"",
    ETDTo:"",
    identityType:"",
    modeOfShipment:"",
      });
    }
  })

  const { data, totalCount, getData } = UseGetDataSearch("/shipping/",params);
  console.log(data);

  const defaultHeader = [
    {
      title: "YPR",
      width: "9%",
      isLink: true,
      id: "shippingId",
      link: '/shipping/shipment-tracking/view/',
      field: "prRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Shipping No.",
      width: "5%",
      field: "ref",
      isLink: true,
      id: "shippingId",
      link: '/shipping/shipment-tracking/view/',
      textAlign: "center",
      rowAlign: "center",
    },
    
    {
      title: "PO No.",
      width: "9%",
      field: "poRef",
      isLink: true,
      id: "shippingId",
      link: '/shipping/shipment-tracking/view/',
    
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Shipping Agent",
      width: "9%",
      field: "agentName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "ETD",
      width: "9%",
      field: "ETD",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "ETA",
      width: "9%",
      field: "ETA",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Status",
      width: "9%",
      field: "shipmentStatus",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "shippingId",
        removeValue: "Shipment Tracking",
        url: '/shipping/',
        moduleName: "Shipment Tracking",
        from: "/shipping/shipment-tracking",
        editLink: "/shipping/shipment-tracking/edit/",
        viewLink: "/shipping/shipment-tracking/view/",
      },
    },
  ];

  let availableHeaders = [
    {
      title: "YPR",
      width: "9%",
      isLink: true,
      id: "shippingId",
      link: '/shipping/shipment-tracking/view/',
      field: "prRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Shipping No.",
      width: "5%",
      field: "ref",
      isLink: true,
      id: "shippingId",
      link: '/shipping/shipment-tracking/view/',
      textAlign: "center",
      rowAlign: "center",
    },
    
    {
      title: "PO No.",
      width: "9%",
      field: "poRef",
      isLink: true,
      id: "shippingId",
      link: '/shipping/shipment-tracking/view/',
    
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Shipping Agent",
      width: "9%",
      field: "agentName",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Delivery Note Reference",
    //   width: "9%",
    //   field: "dnRef",
    //   type:"date",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Booking Date",
      width: "9%",
      field: "bookingDate",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Delivery Date By PO",
      width: "9%",
      field: "deliveryDatebyPO",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Bill Number",
      width: "9%",
      field: "shipmentBill",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Shipping Method",
      width: "9%",
      field: "modeOfShipment",
      textAlign: "center",
      rowAlign: "center",
    },
  
    {
      title: "ETD",
      width: "9%",
      field: "ETD",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "ETA",
      width: "9%",
      field: "ETA",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Status",
      width: "9%",
      field: "shipmentStatus",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Actual Arrival Date",
      width: "9%",
      field: "actualArrivalDate",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Shipping On",
      width: "9%",
      field: "shippedOn",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Delivery Person ",
      width: "9%",
      field: "deliveryPersonName",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Identity Type",
      width: "9%",
      field: "identityType",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Identity Number",
      width: "9%",
      field: "identityNumber",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Vehicle Type",
      width: "9%",
      field: "vehicleType",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Vehicle Number",
      width: "9%",
      field: "vehicleNumber",
      textAlign: "center",
      rowAlign: "center",
    },
   
    
   
    {
      title: "Permit Requitred",
      width: "9%",
      field: "permitReq",
      isCondition: true,
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Insurance Provider",
    //   width: "9%",
    //   field: "insuranceProvider",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Insurance Type",
    //   width: "9%",
    //   field: "insuranceType",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Coverage Start Date",
    //   width: "9%",
    //   field: "coverageStartDate",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Coverage End Date",
    //   width: "9%",
    //   field: "coverageEndDate",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Coverage Amount",
    //   width: "9%",
    //   field: "coverageAmount",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Insurance Premium",
    //   width: "9%",
    //   field: "insurancePremium",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Claim Contact Info",
    //   width: "9%",
    //   field: "claimContactInfo",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Comments",
    //   width: "9%",
    //   field: "comments",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
  

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "shippingId",
        removeValue: "Shipment Tracking",
        url: '/shipping/',
        moduleName: "Shipment Tracking",
        from: "/shipping/shipment-tracking",
        editLink: "/shipping/shipment-tracking/edit/",
        viewLink: "/shipping/shipment-tracking/view/",
      },
    },
  ];

  useEffect(() => {
    setParams({...params,page:page,per_page:pageCount})

  }, [page, pageCount]);

  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };

  const [showImportModal, setShowImportModal] = useState(false);

  const handleImportClick = () => {
    setShowImportModal(true);
  };

  const closeModal = () => {
    setShowImportModal(false);
  };


  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [listofNumbers, setListofNumbers] = useState([])
  const [fullList, setFullList] = useState([])

  const selectOneFunction = (value) =>{ 
    let NewId;
    if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
      NewId = listofNumbers.filter(Temp=> Temp !== value);
      setListofNumbers(NewId)
    }else{
      setListofNumbers([...listofNumbers,value])
    }
  }

  const selectMultipleFunction = (e) =>{ 
console.log(e.target.checked);
if(e.target.checked){
  setListofNumbers(fullList)
}else{
  setListofNumbers([])
}

  }

  useEffect(()=>{
    let tempArr = []
    data && data.forEach(i=>{
      console.log("I",i?.id);
      tempArr.push(i?.id)
    });
    setFullList(tempArr)
  },[data])

  
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Shipping",
             
            },
            {
              name: "Shipment Tracking",
            },
          ]}
        />
        <div className=" flex gap-2">
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />   <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
          {/* <CreateButton label="Import" handleClick={handleImportClick} /> */}
          {GetAccess("shipment_tracking_create") && <Link to="/shipping/shipment-tracking/create">
            <CreateButton label="Shipment Tracking" />
          </Link>}
        </div>
      </div>

      <CustomDataTable
      fetcher={getData}
        data={data}
        availableHeaders={availableHeaders}
        defaultHeader={defaultHeader}
        bodyHeight="max-h-[calc(100vh-260px)]"
        height="h-[calc(100vh-145px)]"
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title="Shipment Tracking List"
        storageName="ShipmentTrackingTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById="shippingId"
        editAccess="shipment_tracking_edit"      
        deteleAccess="shipment_tracking_delete" 
      />
      {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Shipment Tracking</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <p className="py-2 px-6 text-m">Shipped On</p>
                    <div className="flex justify-between  w-full ">
                    <NuDate
              label="From "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="shippedOnFrom"
              width="w-full "
            />
                    <NuDate
              label="To "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="shippedOnTo"
              width="w-full "
            />
             
            </div>
            <p className="py-2 px-6 text-m">ETA</p>
                    <div className="flex justify-between  w-full ">
                    <NuDate
              label="From "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="ETAFrom"
              width="w-full "
            />
                    <NuDate
              label="To "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="ETATo"
              width="w-full "
            />
             
            </div>
            <p className="py-2 px-6 text-m">ETD</p>
                    <div className="flex justify-between  w-full ">
                    <NuDate
              label="From "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="ETDFrom"
              width="w-full "
            />
                    <NuDate
              label="To "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="ETDTo"
              width="w-full "
            />
             
            </div>
            
            <FormikSelectTwo
              label="Identity Type"
              placeholder="Choose"
              Options={IdentityType}
              formik={formik}
              name="identityType"
              width="w-full "
            />
           <FormikSelectTwo
              label="Mode Of Shipment"
              placeholder="Choose"
              Options={ShippingMethod}
              formik={formik}
              name="modeOfShipment"
              width="w-full "
            />
              <FormikSelectTwo
              type="text"
              label="Shipping Status"
              placeholder="Shipping Status"
              Options={ShippingStatus}
              formik={formik}
              name="shipmentStatus"
              width="w-full "
            />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
          {showImportModal && (<Importdoc show={showImportModal} onClose={closeModal} getData={getData} />)}
          {showEdit && (<MultiEditST data={listofNumbers} getData={getData} onClose={()=>setShowEdit(false)}/>)}
          <ModalContainer
                visiable={showDelete}
                title="Warning"
                content={`Are you sure, All selected datas will be removed from Purchase Requests List!`}
                handleClick={() =>{}}
                closeModal={() => setShowDelete(false)}
          />
    </div>
  );
}

export default ShipmentTrackingIndex;
