const   colorCodes = [
    "#EABE6C",
    "#40A2D8", 

    "#BED754",
    "#ABC270",

    "#CE5A67", 
     "#7F5283",  
        "#DA0037", 
    "#D99879", 
    "#4F8A8B",  
     "#7776B3", 
      "#A5B68D",
        "#97BE5A",
    "#9B3922", 
    "#F7DED0", 
    "#BBE2EC", 
    "#B2C8BA", 
    "#9ACD32", 
    "#FF69B4", 
    "#4682B4", 
    "#6A5ACD"  
  ];

  export default colorCodes;