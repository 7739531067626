import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UseAddData from '../../../../Components/Hooks/useAddData';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDate, NuInput, NuTextArea } from '../../../../Components/Utils/Input';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import axiosInstance from '../../../../Services/axiosCommon';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import { useToast } from '../../../../Components/Others/ToastServices';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
import DeleteIcon from '../../../../Components/Icons/Delete';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];

function CreateGRN() {
  const currentDate = new Date().toISOString().split('T')[0];
  const { value: PurchaseType,getData:refPurchaseType} = useFetchMataData(`/dropdown/dropdown-details/`, 'GrnpurchaseType');
  const [items, setItems] = useState([]);
  const { data:deliveryNotes } = UseDropDown('/delivery-notes/drop-down');  
  const [loading, setLoading] = useState(false);

  const [division, setDivision] = useState([]);
  const { Oncancel, addFormData } = useAddFormData();
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const { data: vendor } = UseDropDown('/vendors/drop-down');
  const { data: user } = UseDropDown('/user/drop-down');
  const { data: PO } = UseDropDown('/PO/drop-down');
  const { data: PR } = UseDropDown('/PR/drop-down');
  const toast = useToast();

  const [image, setImage] = useState([]);

  const [newItemData, setNewItemData] = useState({
    orderedQty: "",
    productId: "",
    product_name: "",
    product_unit: "",
    receivedQty: "",
    remainingQty:"" ,
});
  const [createNew, setCreateNew] = useState({
    prId: "",
    purchaseType: "",
    receivedDate: currentDate || "",
    poId: "",
    vendorId: "",
    products: "",
    comments: "",
    ref:"",
    dnRef:"",
    busyGrnNo:""
  });

  const [error, setError] = useState('');
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      prId: yup.string().required('PR is required!'),
      poId: yup.string().required('Purchase Order is required!'),
      dnRef: yup.string().required('Delivery Note Ref. No is required!'),
      busyGrnNo: yup.string().required('Busy GRN No. No is required!')
    }),
    onSubmit: async (value, { resetForm }) => {
      setLoading(true); 
      if (polist.length === 0) {
        setError('Product table is empty');
        return;
      }
      value.products=polist
      let formData = new FormData();
      formData.append('json_data', JSON.stringify(value));
      // formData.append('itemImage', image.raw);
      if(selectedFile !== null){
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      }else{
        formData.append("attachments",[])
      } 

      addFormData('/grn/new-grn', formData, resetForm);
      setLoading(false)
    }
      // addData('/grn/new-grn', value, resetForm);
    // }
  });

  const handleDeleteItem = (indexToDelete) => {
    const updatedItems = polist.filter((_, index) => index !== indexToDelete);
    setPolist(updatedItems);
  };
  const [selectedFile, setSelectedFile] = useState(null);

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = []
    files.map(item=>{
      if (item.size < maxFileSize ){
        fileArr.push(item)
      }else{
        toast.open({
          type:"warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    })    
    let tempArr = []
    setSelectedFile(fileArr);
    files.map(item=>{
      if (item.size < maxFileSize ){
      let fileName = item?.name
      console.log('fname',fileName);
      let fileExtention =  fileName.split(".").reverse()[0]
      if(filetype?.includes(fileExtention)){
        tempArr.push(fileName)
      }else{
        let x = URL.createObjectURL(item)
        tempArr.push(x)
      }
    }
    })
    console.log(tempArr,'temp');
    setImage(tempArr)
  }
  const [purchaseOrder,setPurchaseOrder] = useState([]);

  const getPOByPR = () =>{
    axiosInstance
    .get(`/grn/PO/drop-down/${formik?.values?.prId}`)
    .then(res => {
         console.log("v",res?.data);
         let data = res.data;
            console.log(data,'dddd');
            let tempData = [];
            data.forEach((item) => {
                let tempObject = { value: item.id, label: item.name };
                tempData.push(tempObject);
            });
            res.data && setPurchaseOrder(tempData);
    }).catch((err)=>{
      console.log(err);
    })
  }

  const [polist, setPolist] = useState([]);

  const getPr = (poId) => {
    if (poId !== "") {
      axiosInstance
        .get(`/grn/po-products/${poId}`)
        .then(res => {
          let data = res?.data?.products;
          setPolist(data);
          formik.setFieldValue('vendorId', res?.data?.vendorId || '');
        })
        .catch(err => {
          console.log(err, "err");
        });
    }
  };

  useEffect(() => {
    if (formik.values.prId) {
      getPOByPR();
    }
    if (formik.values.poId) {
      getPr(formik.values.poId);
    }
  }, [formik.values.poId,formik?.values?.prId]);

  
  const handleQuantityChange = (index, newReceivedQty) => {
    const updatedItems = [...polist];
    if (newReceivedQty <= updatedItems[index].orderedQty) {
      updatedItems[index].receivedQty = newReceivedQty;
      updatedItems[index].remainingQty = updatedItems[index].orderedQty - newReceivedQty;
    }
    setPolist(updatedItems);
  };

  const handleIncrement = (index) => {
    const currentReceivedQty = polist[index].receivedQty;
    handleQuantityChange(index, Math.min(polist[index].orderedQty, currentReceivedQty + 1));
  };

  const handleDecrement = (index) => {
    const currentReceivedQty = polist[index].receivedQty;
    handleQuantityChange(index, Math.max(0, currentReceivedQty - 1));
  };

  const handleInputChange = (event, index) => {
    const value = Math.max(0, Math.min(Number(event.target.value), polist[index].orderedQty));
    handleQuantityChange(index, value);
  };

  useEffect(() => {
    if (polist.length > 0) {
      setError('');
    }
  }, [polist]);

  const removeImages = (value) => {
    console.log("value", value);
    let List = selectedFile.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFile([...List]);
    let tempArr = []
    List.map((item) =>{
      let fileName = item?.name
      // console.log('fname',fileName);
      // let fileExtention =  fileName.split(".").reverse()[0]
      // if(filetype?.includes(fileExtention)){
        tempArr.push(fileName)
      // }else{
      //   let x = URL.createObjectURL(item)
      //   tempArr.push(x)
      // }
    })
    setImage(tempArr)
  };

  return (
    <div className="w-full h-full py-2 px-4">
      <div className="flex justify-between">
        <Breadcrumb
          items={[
            { name: "GRN", link: "/store/grn" },
            { name: "Create" }
          ]}
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] mt-4 bg-white rounded-md overflow-hidden">
          <div className="w-full h-full flex justify-start content-start flex-wrap overflow-y-auto">
              <FormikSelectTwo
                type="text"
                label="Purchase Request"
                placeholder="Purchase Request "
                Options={PR}
                formik={formik}
                name="prId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
            <FormikSelectTwo
              type="text"
              label="Purchase Order"
              placeholder="Purchase Order"
              Options={purchaseOrder}
              formik={formik}
              name="poId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <FormikSelectTwo
              type="text"
              label="Supplier"
              placeholder="Supplier"
              Options={vendor}
              formik={formik}
              disabled={true}
              name="vendorId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
              <NuInput
                   type="text"
                   label="Delivery Notes Ref."
                   placeholder="Reference"
                   Options={deliveryNotes}
                   formik={formik}
                   name="dnRef"
                   width="w-full md:w-1/2 lg:w-1/3"
                   isRequired={true}
                 />
             {/* <NuInput
              label="GRN Reference"
              type="text"
              formik={formik}
              placeholder="GRN Reference"
              name="ref"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            /> */}
            {/* <DropDownAddMetaData
                label="Purchase Type"
                placeholder="Choose"
                Options={PurchaseType}
                formik={formik}
                name="purchaseType"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refPurchaseType}
                displayProperty={"Purchase Type"}
                propertyName={"GrnpurchaseType"}
              /> */}
                   
              <NuInput
                label="Busy GRN No."
                type="text"
                formik={formik}
                placeholder="Busy GRN No."
                name="busyGrnNo"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
              {/* <NuInput
                label="GRN Reffrence"
                type="text"
                formik={formik}
                placeholder="GRN Ref. (optional)"
                name="ref"
                width="w-full md:w-1/2 lg:w-1/3"             
              /> */}
            <NuDate
              label="Received Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="receivedDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              type="text"
              formik={formik}
              placeholder="Comments"
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
               <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  GRN Attachement :
  </p></div>
              
              <Attachement
                  handleChange={handleImageChange}
                  preview={image||''}
                  width="w-full"
                  label="Attach"
                  multiple={true}
                removeImages={removeImages}
                />
                        {/* </div> */}
                        {/* </div> */}
            <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-2"></div>
            <div className="w-full bg-white overflow-y-scroll mt-5">
              {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
              <div className="w-full py-3 bg-[#048178] flex justify-between rounded-lg px-4">
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

                <p className="w-[40%] text-left text-white text-xs font-medium">Product</p>
                <p className="w-[10%] text-center text-white text-xs font-medium">Unit</p>
                <p className="w-[15%] text-center text-white text-xs font-medium">Ordered Qty</p>
                <p className="w-[20%] text-center text-white text-xs font-medium">Received Qty</p>
                <p className="w-[15%] text-center text-white text-xs font-medium">Remaining Qty</p>
                <p className=" w-[5%] text-center text-white text-xs font-medium">
              Action
              </p>
              </div>
              {polist?.length>0?
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">

              {polist.map((List, index) => (
                <div key={index} className="w-full py-2 flex justify-between px-4 odd:bg-white even:bg-slate-100">
                  <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                  <p className="w-[40%] text-left text-[#606279] text-xs font-light">{List?.product_name}</p>
                  <p className="w-[10%] text-center text-[#606279] text-xs font-light">{List?.product_unit}</p>
                  <p className="w-[15%] text-center text-[#606279] text-xs font-light">{List?.orderedQty}</p>

                  <div className="w-[20%] text-center text-[#606279] text-xs font-light">
                    <button
                      type="button"
                      className="border rounded-full p-1 "
                      onClick={() => handleDecrement(index)}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      className="w-[20%] text-center border text-[#606279] text-xs font-light"
                      name="receivedQty"
                      value={List.receivedQty}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                    <button
                      type="button"
                      className="border rounded-full p-1 "
                      onClick={() => handleIncrement(index)}
                    >
                      +
                    </button>
                  </div>
                  <p className="w-[15%] text-center text-[#606279] text-xs font-light">{List?.remainingQty}</p>
                  <div className='w-[5%] text-center'> 
                 <button
                    onClick={() => handleDeleteItem(index)}
                    type="button"
                    className="text-red-500 cursor-pointer  ml-4 border-none"
                  >
                    <DeleteIcon
                     color='red'
                     height={4}
                    />
                  </button>
                  </div>
                </div>
                
              ))}
            </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

            </div>
            <div className="w-full my-4 mt-8 ">
              <div className="flex justify-end gap-4">
                 <CancelButton handleClick={() => { Oncancel(-1) }} />
                <SubmitButton isLoading={loading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateGRN;
