import React, { useEffect, useState } from "react";
import { BackButton, CancelButton, CreateButton, EditButton } from "../../../../Components/Utils/Buttons";
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import SideModal from "../../../../Components/Others/SideModel";
import { NormalHeading } from "../../../../Components/Utils/Heading";
import PdfLinkCq from "./Pdf/PdfLinkCq";
import NuDate from "../../../../Components/Utils/NuDate";
import ViewPdf from "./ViewPdf";
import Comments from "./CommentsPr";
import axiosInstance from "../../../../Services/axiosCommon";
import NuAmount from "../../../../Components/Utils/NuAmount";
import CommonComments from "../../../../Components/Utils/commonComments";
import ApprovalComponent from "../../../../Components/Utils/approvalComponent";
import { Tab, Tabs } from "../../../../Layouts/MainPage/Tabs";
import CQPdfOthers from "../../ViewPdfTemplate/CQPdfOthers";
import FilesView from "../../../../Components/Utils/FilesView";
import PDFViewWithAPI from "../../../../Components/Utils/PDFViewWithAPI";
import PDFViewer from "../../../../Components/Utils/PDFViewer";

const ViewCustomerQuotes = () => {
  const { quotesViewId } = useParams();
  const location = useLocation();
  const [data,setData] = useState(location.state.data);
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [showPdfComponent, setShowPdfComponent] = useState(false);
  const [showQuoteComparison, setShowQuoteComparison] = useState(false);
  const [finalProId,setFfinalProId]=useState([])
  const [sellingPrices,setSellingPrices]=useState({})
  const [productCount, setProductCount] = useState(0);

  const [dynamicHeaderCount, setDynamicHeaderCount] = useState(3);
  const [dataView, setDataView] = useState([]);

  const [stateValue, setStateValue] = useState([]);

  const [supplierList, setSuppliersList] = useState([]);
const [isLoading,setIsLoading]=useState(true)

  const dynamicHeaders = Array.from(
    { length: dynamicHeaderCount },
    (_, index) => (
      <React.Fragment key={`dynamic-header-${index}`}>
        <th className="col-span-5 w-[500px] border-r border-[#acaaaa]">
          <div className="w-full border border-x-0 border-y-0 bg-[#C4C2C2]-300  py-1">
            <div className="w-full py-1 flex justify-center items-center">
              <div className="px-2">
                {supplierList[index]?.name && supplierList[index]?.name || `Supplier ${index + 1}`}
              </div>
              <div className=" ml-2 h-7">
                <div className=" text-left font-medium text-[9px] leading-3">Ref : {supplierList[index]?.ref||"#"}</div>
                <div className=" text-left font-medium text-[9px] leading-3">Prepared By : <span className=" capitalize">{supplierList[index]?.preparedBy||"-"}</span> </div>
              </div>
            </div>

            <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] mx-auto">
            </div>
              <div>
                <th className="w-[200px] py-1 overflow-hidden">Price</th>
                <th className="w-[100px] py-1">GP</th>
                <th className="w-[200px] py-1">Selling Price</th>
              </div>
          </div>
        </th>
      </React.Fragment>
    )
  );

  const handleOpenCommentsModal = () => {
    setIsCommentsModalOpen(true);
  };

  const handleCloseCommentsModal = () => {
    setIsCommentsModalOpen(false);
  };

  const handlePdfIconClick = () => {
    setShowPdfComponent(true);
  };
  const closeFilterDialog = () => {
    setShowPdfComponent(false);
  };

  const closeQouteComparison = () => {
    setShowQuoteComparison(false);
  };

  function getViewQuotes(prId) {
    setIsLoading(true)

    axiosInstance
      .get(`/supplier-quotations/quot-compare/${prId}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          setDataView(res.data?.data?.products);
          setProductCount(res?.data?.data?.products?.length || 0);
          setDynamicHeaderCount( res.data?.data?.vendorNames ? res.data?.data?.vendorNames.length : 0);
          setSuppliersList(res.data?.data?.vendorNames);
          setStateValue(res.data?.data?.vendors);
          let vendorsItem =res.data?.data?.vendors||[]
          let TempValue =[]
          vendorsItem.forEach(i=>{
            if(i.isSelected){
              TempValue.push(i.id)
            }
          })
          setFfinalProId(TempValue)
          setIsLoading(false)

        }
      });
  }

  const id = quotesViewId; 
  const getUrl = `/customer-quotations/get-status`;
  const putUrl = `/customer-quotations/edit-status`; 
  const statusUrl = '/customer-quotations/all-status';

  const [revData,setRevData] = useState([])

  useEffect(() => {
    if(quotesViewId)getRevData(quotesViewId)
  },[quotesViewId])

  function getRevData(id){
    axiosInstance.get(`/customer-quotations/products-lists/${id}`).then(res=>{
      console.log("ree",res?.data?.data);
      if(res?.data?.data)setRevData(res?.data?.data)
      
    }).catch(err=>{
      console.log("err", err);
    })
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [filePathListSQ, setFilePathListSQ] = useState([]);
  
  
 
  
  useEffect(() => {
    if (
      data?.rfqAttachments !== null &&
      data?.rfqAttachments !== "None" &&
      data?.rfqAttachments !== "[]"
    ) {
      setFilePathList(data?.rfqAttachments?.length>0 ? data?.rfqAttachments : []);
      // console.log('image',createNew?.rfqAttachments);
      // setRemoveFilePathList(data?.productImage);
      // console.log('images',filePathList);
    }
  }, [data]);

  const downloadPDF = async () => {
    try {
        const response = await axiosInstance.get(`/customer-quotations/download-pdf/${quotesViewId}`, {
            responseType: 'blob' // Important to specify the response type as blob
        });

        // Create a Blob from the response
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

        // Create a link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = `${data.ref}.pdf`; // The filename you want for the downloaded file

        // Append the link to the body (required for Firefox)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

    } catch (error) {
        console.error('Error downloading the PDF:', error);
    }
};
  
const [viewPDF,setViewPDF] = useState(null);

const ViewPDF = async () => {
  try {
      const response = await axiosInstance.get(`/customer-quotations/download-pdf/${quotesViewId}`, {
          responseType: 'blob' // Important to specify the response type as blob
      });

      // Create a Blob from the response
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

      // Create a link element
      let Viewlink = window.URL.createObjectURL(pdfBlob);
      setViewPDF(Viewlink);

  } catch (error) {
      console.error('Error downloading the PDF:', error);
  }
};

useEffect(() => {
  ViewPDF()
},[])
 

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            {
              name: "Customer Quotation",
              link: "/sales/quotes",
            },
            {
              name: "View Customer Quotation",
            },
          ]}
        />
        <div className="flex gap-2">
          <Link to={"/sales/quotes"}>
            <BackButton link={true} />
          </Link>
          <CreateButton svg={false} handleClick={downloadPDF} label="Download as PDF" />
          {/* <PdfLinkCq
            data={data}
            fileName={`Customer_Quote_${data.ref}_${NuDate({
              value: Date(),
              format: "DD-MMM-YYY",
            })}.pdf`}
          /> */}
          <Link
            to={`/sales/quotes/edit/${quotesViewId}`}
            state={{ data: data, from: `/sales/quotes/view/${quotesViewId}` }}
          >
            <EditButton height="8" />
          </Link>
          {/* <button
            className={`cursor-pointer text-white bg-[#048178] rounded-md inline-flex w-auto h-8 px-2 flex-shrink-0 items-center justify-center`}
            onClick={handleOpenCommentsModal}
          >
            Comments
          </button> */}
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-125px)]  rounded-md overflow-hidden p-2   flex gap-2 text-[#111537]">
        <div className="w-[70%]  bg-white rounded-lg drop-shadow-lg py-1 overflow-hidden ">
       
        <div className="w-[98%]  bg-white px-2 mx-2 border border-gray py-2 rounded-lg flex justify-between items-center  z-10">  
        <div className="grid  items-center ">
        <div className="flex text-[14px] mb-1 text-[#111537] font-semibold w-[80%]">
            <p className="">{data.ref}</p>
            {/* <p className=""> : {data?.description || 'None'}</p> */}
            </div>
            <div className="flex gap-4">
            <p className="text-xs text-[#111537]">Date : {data?.createdAt ? (
    <NuDate value={data?.createdAt} format="DD-MMM-YYYY, hh:mm A" /> 
  ) : (
    "N/A"
  )}
         </p>
         <p className="text-xs text-[#111537]"> Created By : {data.preparedByName || 'None'}</p>
         {/* <p className="bg-[#579BB1] text-white items-center rounded-md text-xs p-[2px]"> Status : {data.status || 'None'}</p> */}

         </div>
         </div>
         {/* <div className="flex justify-between"> */}
          {/* <div className="flex gap-3 items-center">
            <NormalHeading title={"Customer Quotation"} /> :{" "}
            <p className="text-xs grid pt-5"> {data.ref} <span className="font-light text-[11px] px-2">{data?.date ? (
                  <NuDate value={data?.date} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</span></p>
               </div> */}
          <div className="gird gap-2  w-[20%] justify-end">
         
            <Link
              className="text-xs cursor-pointer text-white bg-[#F0DE36] rounded-md inline-flex w-auto h-5 mb-1 px-2 mr-2 flex-shrink-0 items-center justify-center"
              to={`/sales/quotes/viewcqcreate/${data?.id}`}
              state={{ data: data, from: `/sales/quotes/view/${data?.id}` }}
            >
              Rev.00
            </Link>
            <div className="border rounded-md mr-3  text-xs inline-flex w-auto  flex-shrink-0  justify-center">
            <p className="  bg-white  text-black  p-1 rounded-l-md  ">
              Status  </p><p className="bg-[#579BB1] text-white p-1 items-center rounded-r-md"> {data.status || 'None'}</p>
             
              </div>
          
        </div>
        </div>

        <div className="h-[calc(100vh-200px)] overflow-y-auto p-2 pb-8 ">

        <div className=" w-full flex flex-wrap  px-2 border border-gary mt-2  rounded-lg mb-2">
      
          <div className="w-full grid grid-cols-2 gap-x-8 ">
            {/* <div className="w-full flex px-2">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                Customer{" "}
              </p>
              <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
               : {data?.customerName || "-"}
              </p>
            </div> */}
             {/* <div className="w-full flex px-2">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
              Discount{" "}
              </p>
              <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
               : {data?.discount || "-"}
              </p>
            </div> */}

            <div className="w-full flex px-2">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                Validity
              </p>
              <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
               : {data?.validity || "-"}
              </p>
            </div>
            {/* <div className="w-full flex px-2">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                Availability
              </p>
              <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
               : {data?.availability || "-"}
              </p>
            </div> */}
            {/* <div className="w-full flex px-2">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                Quote Date
              </p>
              <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
               : {data?.date || "-"}
              </p>
            </div> */}

            {/* <div className="w-full flex px-2">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                Lead Time
              </p>
              <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
               : {data?.leadTime || "-"}
              </p>
            </div> */}
            {/* <div className="w-full flex px-2">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                Payment Method
              </p>
              <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
               : {data?.paymentMethod || "-"}
              </p>
            </div> */}
            <div className="w-full flex px-2">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                Delivery Terms
              </p>
              <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
               : {data?.deliveryTerms || "-"}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                Payment Terms
              </p>
              <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
               : {data?.paymentTerms || "-"}
              </p>
            </div>

            {/* <div className="w-full flex px-2">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                Purchase Requests
              </p>
              <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
               : {data?.prRef || "-"}
              </p>
            </div> */}
            <div className="w-full flex px-2">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
              Revision
              </p>
              <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
               : {data?.revisionCount || "No Revision"}
              </p>
              </div>
              {data?.isEmailSent===true && (
            <div className='w-full flex px-2'>
              <p className='w-1/3 py-2 text-xs font-medium text-[#111537]'>Email Sent Date & Time</p>
              <p className='w-[65%] py-2 pl-5 text-xs text-[#606279]'> : {data?.emailSentDateTime? (
     <NuDate value={data?.emailSentDateTime} format="DD-MMM-YYYY, hh:mm A" /> 
    ) : (
      "N/A"
    )}</p>
            </div>
            )}
            {/* <div className="w-full flex px-2">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                Prepared By{" "}
              </p>
              <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
               : {data?.preparedByName || "-"}
              </p>
            </div> */}
             {/* <div className="w-full flex px-2">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
               Approval Status
              </p>
              <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
               : {data?.approvalStatus || "-"}
              </p>
              </div> */}
            {/* <div className="w-full flex px-2">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                Status
              </p>
              <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
               : {data?.status || "-"}
              </p> 
              
            </div>*/}
            {/* {data?.reviewedByName && <div className="w-full flex px-2">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                Reviewed By{" "}
              </p>
              <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
               : {data?.reviewedByName || "-"}
              </p>
            </div>} */}
            {/* <div className="w-full flex px-2">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
              Verified By{" "}
              </p>
              <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
               : {data?.verifiedByName || "-"}
              </p> */}
              {/* { data.approvals.length > 0 &&(

              )} */}
            {/* </div> */}
            {/* <div className="w-full flex px-2">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
              Approved By{" "}
              </p>
              <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
               : {data?.approvedByName || "-"}
              </p>
            </div> */}
          </div>
        </div>
        <div className="flex mt-4 gap-2">
        <div className="w-[50%] ">
        <p className="  text-xs font-medium text-[#111537] p-1">
             Special Notes
            </p>
        <div className=" border border-gary  h-28 overflow-y-scroll rounded-lg items-center">
            {/* <p className=" p-1 text-xs font-medium text-[#111537]">
              Notes From Customer
            </p> */}
            <p className="  text-xs p-2 break-words text-[#606279]">
              {data.specialNotes || '-'}
            </p>
          </div>
          </div>
          <div className="w-[50%] ">
          <p className=" p-1 text-xs font-medium text-[#111537]">
            Supplier Quote Attachments
            </p>
          <div className=" border border-gary  overflow-y-scroll h-28 rounded-lg p-2">
         

          <div className="w-full flex gap-2 items-center flex-wrap mt-1 ">
              {data?.rfqAttachments &&
                filePathList.map((imgs, idx) => {
                  console.log("img", imgs);
                  return (
                    <FilesView fileView={imgs} key={idx}/>
                    // <div
                    //   className=" w-10 h-11 relative flex justify-center items-center rounded-lg"
                    //   key={idx}
                    //   style={{
                    //     border: "1px solid gray",
                    //     borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))",
                    //   }}
                    // >
                    //   <div
                    //     className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                    //     onClick={() => handleImageClick(imgs)}
                    //   >
                    //     <img src={imgs?.file} alt={imgs?.file} />
                    //   </div>
                    // </div>
                  );
                })}
            </div>
</div>
</div>
          {/* <div onClick={handlePdfIconClick} className="cursor-pointer m-2 border border-gary p-4 rounded-lg w-[10%]">
          <svg
            width="30"
            height="30"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
        </div> */}
        </div>
        {/* <div className="flex gap-3">{data?.approvals?.length>0 && data?.approvals?.map((item, idx)=>{
          return(
           <div key={idx}>
            <div className="p-2">
              <div className="flex w-52">
                <p className="w-25 text-xs py-1 pr-2 pb-2">{item.status=='approved'? 'Approved By :': 'Verified By :'}</p>
                <p className="text-xs text-[#606279] py-1 pb-2">{(item.actionByName)}</p>
              </div>
              <div className={`${item.status=="approved"? "w-28 h-28 rounded-full":"w-44 h-28"} flex justify-center items-center overflow-hidden`}>
                <img className={`${item.status=="approved"? "w-28 h-28":"w-44 h-28"}`} src={item.supportDocUrl[0]?.file} alt="Sup Doc"/>
              </div>
              </div>
           </div> 
          )
        })}</div> */}
        {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div> */}
       
          <div className=" w-full flex justify-end my-1">
          <span className="  rounded-md py-1 px-2 text-baseColor-primary bg-slate-300 text-xs cursor-pointer" onClick={()=>{setShowQuoteComparison(true); getViewQuotes(data?.prId) }}>View Quotes</span>
          </div>

        <Tabs>
{
  revData && revData.map((item,index)=>(
<Tab component={<>
        <div className="w-full bg-white  border border-gary  mt-4  rounded-lg ">
        <div className="w-full py-2 bg-baseColor-primary flex justify-between rounded-lg px-2">
            {/* <p className="w-[20%] text-left pl-1 text-white text-xs font-medium">
              Item Code
            </p> */}
                          <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N.</p>

            <p className="w-[50%] text-left text-white text-xs font-medium">
              Product
            </p>
            <p className="w-[10%] text-center text-white text-xs font-medium">
              Unit
            </p>
            <p className="w-[10%] text-center text-white text-xs font-medium">
              Qty
            </p>
            {/* <p className="w-[15%] text-center text-white text-xs font-medium">
              Lead Time
            </p> */}
            <p className="w-[15%] text-center text-white text-xs font-medium">
              Price
            </p>
            <p className="w-[15%] text-center text-white text-xs font-medium">
              Total
            </p>
          </div>
          {item.revProduct && item.revProduct?.map((List, index) => (
            <div
              key={index}
              className="w-full py-2 flex px-2 justify-between odd:bg-white even:bg-slate-100"
            >
               <p className="w-[5%]  text-left  text-[#606279] text-xs font-light ">
                                    {index + 1}
                                </p>
              {/* <p className="w-[20%] text-left pl-1 text-[#606279] text-xs font-light ">
                {List?.item_code || "-"}
              </p> */}
              <p className="w-[50%] text-left text-[#606279] text-xs font-light ">
                {List?.product_name || "-"} 
                <div className="text-[10px]">Lead Time - {List?.leadTime}</div>
              </p>
              <p className="w-[10%] text-center text-[#606279] text-xs font-light ">
                {List?.product_unit || "-"}
              </p>
              <p className="w-[10%] text-center text-[#606279] text-xs font-light ">
                {List?.quantity || "-"}
              </p>
              {/* <p className="w-[15%] text-center text-[#606279] text-xs font-light ">
                {List?.leadTime || "-"}
              </p> */}
             <p className="w-[15%] text-center text-[#606279] text-xs font-light">
  {Number(List?.price).toLocaleString(undefined, { 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2 
  }) || "0.00"}
</p>

             <p className="w-[15%] text-center text-[#606279] text-xs font-light">
  {((Number(List?.price) || 0) * (Number(List?.quantity) || 0))
    .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
</p>

            </div>
          ))}
           <div className="w-full flex px-4 justify-end">
            <p className="py-2 text-xs font-medium text-[#111537]">
              Total Price            </p>
            <p className=" py-2 text-xs font-medium text-[#606279]">
            : <NuAmount   value= {data.totalPrice || '-'} />
            </p>
          </div>
        </div>
        </>} active={index==0} key={index} >
  {item?.revName}
</Tab>
  ))
}
</Tabs>
        {/* <div className="w-full flex justify-end px-2 py-2">
          <div className="w-1/4 flex">
            <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
              Total Price :
            </p>
            <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              {data.totalPrice || "-"}
            </p>
          </div>
        </div> */}
        </div>
        </div>
        <div className="w-[30%] bg-white rounded-lg drop-shadow-lg py-1">
        <div className="h-[98%] overflow-y-scroll p-2 ">
        {/* <div className=" border border-gary   py-2 px-1 rounded-lg">
         <p className="text-xs text-[#111537] font-medium">Approval
         <span>
                   : {data.approvalStatus || "None"}
                </span>
         </p>
          <div className="flex gap-4 mt-2 justify-around">
          <p className=" px-2 py-2 bg-green-500 hover:cursor-pointer text-white items-center rounded-md text-xs text-center  h-7  ">
               Approve
              </p>
              <p className=" px-2 py-2 bg-red-500 hover:cursor-pointer text-white items-center rounded-md text-xs text-center  h-7  ">
              Decline
              </p>
          </div>
         </div> */}
<ApprovalComponent id={id} getUrl={getUrl} putUrl={putUrl} statusUrl={statusUrl} refSetData={setData} data={data} type={"CQ"} preUrlName={"customer-quotations"}/>

         <div className="w-full grid  border border-gary mt-4  rounded-lg">
          {/* <p className="text-xs text-[#111537] font-medium">Customer Detail</p> */}
          <div className="bg-baseColor-primary  p-2 rounded-t-lg">
            <p className="text-xs text-white font-medium">Customer Detail</p>

            </div>
              <div className="w-full flex px-2 justify-between">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                Customer
              </p>
              <p className="w-[65%] py-2 text-xs  text-[#606279] truncate">
            : {data?.customerName}
              </p>
            </div>
            <div className="w-full flex px-2 justify-between">
              <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                Purchase Request
              </p>
              <p className="w-[65%] py-2 text-xs  text-[#606279] truncate">
               : {data?.prRef || "-"}
              </p>
            </div>
           </div>
           <div className="w-full grid  border border-gary mt-4  rounded-lg ">
           <p className="text-xs text-white font-medium bg-baseColor-primary  p-2 rounded-t-lg">Documents </p>
           <div className=" w-full flex flex-wrap overflow-y-scroll h-16">
           {/* <div onClick={handlePdfIconClick} className="cursor-pointer m-2   ">
          <svg
            width="30"
            height="30"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
        </div> */}

        <div onClick={handlePdfIconClick} className="cursor-pointer m-1 w-auto h-[25px] flex items-center  justify-between  rounded p-[2px] border ">
          <svg
            width="20"
            height="20"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
          <p className='w-auto text-[9px]'>{data?.ref}</p>
        </div>
        </div>
        </div>
        {/* <div className="w-full grid  border border-gary mt-4  rounded-lg ">
           <p className="text-xs text-white font-medium bg-baseColor-primary  p-2 rounded-t-lg">Documents </p>
           <div className=" w-full flex flex-wrap overflow-y-scroll h-16">
            {
              relatedDoc && relatedDoc.map((doc,index) => {
                return(
                  <PDFViewWithAPI item={doc} key={index}/>
                )
              })
            }


        </div>
        </div> */}
<div className="w-full h-auto  flex  border border-gary mt-4 overflow-hidden rounded-lg">
         <CommonComments
            visible={true}
            // closeModal={handleCloseCommentsModal}
            title="Comments"
            mainId={quotesViewId}
          addurl={"/cq-comments/new-comment"}
          geturl={`/cq-comments/by-CQ/${quotesViewId}`}
       />
         </div>
        </div>
      </div>
</div>

      {showPdfComponent && (
 <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
 <div className="animate-scale-in relative w-[98%] h-[98%] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
   <button
              className="absolute top-2 right-2 text-white bg-red-600"
              onClick={() => setShowPdfComponent(false)}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <PDFViewer pdfFile={viewPDF}/>

      {/* <ViewPdf data={data} /> */}
      {/* <CQPdfOthers data={data}/> */}
      {/* <div className="flex justify-end gap-2 ">
                <CancelButton handleClick={closeFilterDialog} />
                <SubmitButton name="Apply" />
              </div> */}
    </div>
  </div>
)}

      {/* {isCommentsModalOpen && (
        <SideModal
          visiable={isCommentsModalOpen}
          closeModal={handleCloseCommentsModal}
          title="Comments"
          mainId={quotesViewId}
          addurl={"/cq-comments/new-comment"}
          geturl={`/cq-comments/by-CQ/${quotesViewId}`}
        />
      )} */}

{showQuoteComparison && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in w-[98vw] h-[96vh] bg-white py-4 px-6 rounded-md shadow-lg">
            <div className="w-full h-30 flex justify-between">
             <h2 className="text-sm 2xl:text-base font-semibold mb-4">View Quote Comparison</h2>
             <div className="w-6 h-6 bg-baseColor-primary rounded-sm cursor-pointer"  onClick={closeQouteComparison}>
                <p className="text-center py-1 text-white text-xs">X</p>
             </div>
            </div>
            <div className="overflow-x-auto w-[calc(100vw-280px)] rounded-lg">
        <div className=" w-full min-h-[calc(100vh-350px)] max-h-[calc(100vh-320px)]  overflow-y-scroll">

{
     (isLoading==true)?(
      // <div className="w-full h-full">
 <>
         <div className="w-full">
            {[...Array(5)].map((_, index) => (
              <div key={index} className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-200 h-10 w-10"></div>
                <div className="flex-1 space-y-6 py-1">
                  <div className="h-4 bg-slate-200 rounded"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-4 bg-slate-200 rounded col-span-2"></div>
                      <div className="h-4 bg-slate-200 rounded col-span-1"></div>
                    </div>
                    <div className="h-4 bg-slate-200 rounded"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
        // </div>
     )
     :
     (
      // <div className={ supplierList?.length>1 ? `w-[calc((100vw-100px)*1.5)]`:`w-[calc(100vw-250px)] overflow-x-scroll`}>
      <div className={supplierList?.length>10 ? `w-[calc((100vw-300px)*4.9)]`: supplierList?.length>6 ? `w-[calc((100vw-300px)*3.9)]`:supplierList?.length>4 ? `w-[calc((100vw-300px)*2.9)]`:supplierList?.length>1 ? `w-[calc((100vw-300px)*1.8)]`:`w-full overflow-x-scroll relative`}>
          <table className="table-auto relative  border-collapse border border-slate-300">
            <thead className="bg-baseColor-primary sticky top-0 text-white text-xs 2xl:text-sm ">
              <tr>
              <th colSpan={3} className=" py-1 sticky  left-0 bg-baseColor-primary z-10 border-r border-slate-100">
                  <div className=" flex  items-center">
                    <div className=" w-[80px] text-left pl-3">S.N</div>
                    <div className=" w-[250px] text-left">Product</div>
                    <div className=" w-[100px] text-left">Unit</div>
                    <div className=" w-[100px] text-left">Qty</div>
                  </div>
                </th> 
                
                {dynamicHeaders}
              </tr>
            </thead>
            <tbody className="">
              {dataView && dataView.map((item, index) => (
                <tr
                  key={index}
                  className="odd:bg-white even:bg-slate-50  text-xs 2xl:text-sm border-collapse border border-slate-300"
                >
                  <td colSpan={supplierList?.length>=1 ?3:0} className={`py-2 text-center sticky left-0 ${index%2!=0?"bg-slate-50":"bg-white"} `}>
                  <div className=" flex  items-center">
                    <div className=" w-[80px] text-left pl-3">{index+1}</div>
                    <div className=" w-[250px] text-left line-clamp-2">{item.name}</div>
                    <div className=" w-[100px] text-left">{item.unit}</div>
                    <div className=" w-[100px] text-left">{item.requestedQty}</div>
                  </div>
                  </td>

                  {supplierList.map((sub, index) => {
                    const supplier = stateValue.find(
                      (i) => i.productId === item.id && i.quoteId === sub.quoteId
                    );
                    if (supplier) {
                      return (
                        <React.Fragment key={`dynamic-cell-${index}-${index}`}>

                          <td className="col-span-5">
                            <div className="w-full">
                              {/* <td
                            className={`py-2 text-center border-collapse border  border-slate-300`}
                          >
                            {supplier.currencyLabel || "-"}
                          </td> */}
                              <td
                                className={`py-2 text-center border-collapse border-x border-y-0 w-[200px] ${supplier.isLow == true && supplier.isHigh == true
                                  ? "bg-green-100"
                                  : supplier.isHigh == true
                                    ? "bg-red-100"
                                    : supplier.isLow == true
                                      ? "bg-green-100"
                                      : ""
                                } border-slate-300`}
                              >
                                <input
                                  type="checkbox"
                                  checked={supplier.isSelected}
                                  disabled={true}
                                  // onChange={(e) =>
                                  //   handleChange(
                                  //     e,
                                  //     supplier.vendorId,
                                  //     supplier.productId
                                  //   )
                                  // }
                                />
                                {<NuAmount
                                  value={
                                    parseFloat(supplier?.LCSP).toFixed(2) || 0
                                  }
                                /> || "-"}
                              </td>

                              {/* <td
                                className={`py-2 text-center border-collapse border-x border-y-0 w-[200px]  border-slate-300`}
                              >
                                <div
                                  className=" w-full h-full flex justify-evenly px-1"
                                >
                                  <input
                                    type="number"
                                    className="w-full px-2 py-1 text-center focus:outline-none rounded cursor-pointer bg-slate-200"
                                    value={parseFloat(supplier.landedCost)}
                                    onChange={(e) => handleLCChange(e, supplier.vendorId, supplier.productId)
                                    }
                                  />
                                <NuAmount
                                    value={
                                      supplier?.landedCost || "0.00"
                                    }
                                  /> 
                                </div>
                              </td> */}
                              <td
                                className={`py-2 text-center border-collapse border-x border-y-0 w-[100px] border-slate-300`}
                              >
                                <div
                                  className=" w-full h-full  flex justify-evenly px-1"
                                >
                                  <input
                                    type="number"
                                    className="w-full py-1 px-2 text-center focus:outline-none rounded  bg-slate-200"
                                    value={parseFloat(supplier.gp)}
                                    disabled={true}
                                    // onChange={(e) => handleGPChange(e, supplier.vendorId, supplier.productId)
                                    // }
                                  />
                                  {/* <NuAmount value={supplier?.gp || "0.00"} /> */}
                                </div>
                              </td>
                              <td
                                className={`py-2 text-center border-collapse border-x border-y-0 w-[200px] border-slate-300`}
                              >
                                <div
                                  className=" w-full h-full"
                                >
                                  <NuAmount value={supplier?.sellingPrice || 0} />
                                </div>
                              </td>
                            </div>
                          </td>

                         
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={`dynamic-cell-${index}-${index}`}>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className=" border-slate-300"></td>
                        </React.Fragment>
                      );
                    }
                  })}
                </tr>
              ))}
                  <tr className="bg-white text-xs 2xl:text-sm  font-bold">
  <td colSpan={3} className="py-2 text-center sticky left-0 bg-white z-[0]">
  
  </td>

  {supplierList.map((sub, supplierIndex) => {
  
    const totalPrice = stateValue
      .filter(i => i.quoteId === sub.quoteId) 
      .reduce((total, supplier) => total + parseFloat(supplier.sellingPrice || 0), 0); 

    return (
      <React.Fragment key={`total-row-${supplierIndex}`}>
        <td className="w-[500px]">
          <div className="w-full">
           
            <td className={`py-2 text-center  w-[200px] `}>
             
            </td>
            <td className={`py-2 text-center  w-[100px] `}>
             
            </td>
            <td className="py-2 text-center  w-[200px] px-2 ">
              <div className="flex gap-2 text-[10px] text-gray-500 justify-center">
                <span>Total:</span>
                <NuAmount value={totalPrice} /> 
              </div>
            </td>
          </div>
        </td>
      </React.Fragment>
    );
  })}
</tr>

            </tbody>
          </table>
          </div>
     )
}
        </div>
      </div>
          </div>
        </div>
)}
    </div>
  );
};

export default ViewCustomerQuotes;
