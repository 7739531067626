import React from 'react';
import { TextMediumSM, TextMediumXS } from '../../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';

export default function TopValueDashboard() {
  const { data, loading } = UseGetDashBoardData(`/dashboard/topCustomer`);
  
  return (
    <div className="col-span-2  bg-white h-[40vh] p-4 pb-10 rounded-lg shadow-lg overflow-hidden mb-4">
      <div className="w-full  px-1 py-2 rounded-md ">
        <TextMediumSM content="Top Customers By Value" color="text-[#4F4768]" />
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>

      </div>
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full mb-1">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <div className="w-full h-[96%] overflow-y-scroll pb-4">
          <table className="w-full ">
          <thead className="sticky top-0 bg-white shadow-sm">

              <tr>
                <th className="text-left px-2 py-1"><TextMediumSM content="Customer" /></th>
                <th className="text-left px-2 py-1"><TextMediumSM content="Amount" /></th>
                
              </tr>
            </thead>
            <tbody>
                
                {data && data.map((item, index) => (
                <tr className="odd:bg-white even:bg-slate-50  " key={index}>
                  <td className="px-2 py-2"><TextMediumXS content={item?.customerName || "N/A"} /></td>
                  <td className="px-2 py-2">
  <TextMediumXS content={item?.totalSpent ? Number(item.totalSpent).toFixed(2) : "N/A"} />
</td>
     </tr>
              ))}
            </tbody>
          </table>
        </div>
       
      )}
    </div>
  );
}

