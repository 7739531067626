import React, { useEffect, useMemo, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDataTime, NuDate, NuInput, NuSelect, NuTextArea, NuTime } from '../../../../Components/Utils/Input';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import Delete from '../../../../Components/Icons/Delete';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import axiosInstance from '../../../../Services/axiosCommon';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';
import { TextMediumBase } from '../../../../Components/Text/MediumText';
function CreateCustomerQuotes() {
  // const {value:Availablity ,getData:refAvailablity} = useFetchMataData(`/dropdown/dropdown-details/`,'Availablity_CustomerQuotes')
  // const {value:DeliveryTerm ,getData:refDeliveryTerm} = useFetchMataData(`/dropdown/dropdown-details/`,'DeliveryTerm_CustomerQuotes')
  // const {value:PaymentTerms ,getData:refPaymentTerms} = useFetchMataData(`/dropdown/dropdown-details/`,'PaymentTerms_CustomerQuotes')
  // const {value:PaymentMethod ,getData:refPaymentMethod} = useFetchMataData(`/dropdown/dropdown-details/`,'PaymentMethod_CustomerQuotes')
  // const {value:Validity ,getData:refValidity} = useFetchMataData(`/dropdown/dropdown-details/`,'Validity_CustomerQuotes')
  // const {value:leadTime , getData:refleadTime} = useFetchMataData(`/dropdown/dropdown-details/`,'leadTime_customerQuotes')
  const { ViewId } = useParams();
  const location = useLocation();
  let pathFile = location.pathname;
  const [startLoading,setStartLoading]=useState( true)


  // const { data: Items } = UseDropDown('/items/drop-down');
  // const { data: customers } = UseDropDown('/customers/drop-down');

  // const [image, setImage] = useState({ preview: "", raw: "" });
  // const [division, setDivision] = useState([]);
  // const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  // const [items, setItems] = useState([]);
  // const [newItemData, setNewItemData] = useState({
  //           supQuotProductId: "",
  //           quantity: "",
  //           price: "",
  //           comments: ""
  //  });

  // const handleAdd = () => {
  //   const newItem = {
  //     supQuotProductId: formik.values.supQuotProductId,
  //     comments: formik.values.comments,
  //     price: formik.values.price,
  //     quantity: formik.values.quantity
  //   };
  //   setItems([...items, newItem]);
  //   // Update local storage
  //   setNewItemData({
  //   supQuotProductId: "",
  //   comments: "",
  //   price: "",
  //   quantity: ""
  //   });
  //   formik.values.supQuotProductId = '';
  //   formik.values.quantity ='';
  //   formik.values.price = '';
  //   formik.values.comments =''
  // };

  // const handleDeleteItem = (indexToDelete) => {
  //   const updatedItems = items.filter((_, index) => index !== indexToDelete);
  //   setItems(updatedItems);
    // const updatedItem = updatedItems.map(item => ({
    //   supQuotProductId: item.supQuotProductId,
    //   quantity: item.quantity,
    //   price: item.price
    // }));
    // setItems(updatedItem);

  //   const removedItemPrice = parseFloat(prlist[indexToDelete].price || 0);
  //   const removedItemQuantity = parseFloat(prlist[indexToDelete].quantity || 0);
  //   const newTotalPrice = formik.values.totalPrice - (removedItemPrice * removedItemQuantity);
  
  //   // 4. Update the formik state for totalPrice using setFieldValue:
  //   formik.setFieldValue('totalPrice', newTotalPrice);
  //   console.log("Updated totalPrice:", newTotalPrice);
 
  // };
  
  const { data: PR } = UseDropDown('/PR/drop-down');

  const formik = useFormik
    ({
      enableReinitialize: true,
      initialValues: {prId:''},
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
      }
    });
   
//   const { data: user } = UseDropDown('/user/drop-down');
//   const [subQuot,setSubQuot] = useState([]);
// const listSubQuotes = useMemo(() => {
//   axiosInstance
//   .get(`/supplier-quotations/quot-products/drop-down/${formik?.values?.prId}`)
//   .then((res) => {
//     let list = res.data;
//     let array = [];
//     list &&
//       list.forEach((i) => {
//         let num = { value: i?.id, label: i?.productName };
//         array.push(num);
//       });
//     res.data && setSubQuot(array);
//   })
//   .catch((error) => {
//         console.error('Error fetching data:', error);
//       });
// }, [formik?.values?.prId]);

// const [prlist, setPrlist] = useState([]);


// const getPr = (prId) => {
//   if (prId !== "") {
//     axiosInstance
//       .get(`/supplier-quotations/selected-products/drop-down/${prId}`)
//       .then(res => {
//         let data = res?.data?.data?.products;

//             let tPrice=0.00
//            for (let i=0;i<data?.length;i++){
//             tPrice = parseFloat(tPrice) + (parseFloat(data[i].price) * parseFloat(data[i].quantity));
//            }
//           //  setTotalPrice(tPrice);
//           formik.setFieldValue('totalPrice', tPrice);

//         setPrlist(data);
//         setItems(data);
         
//         formik.setFieldValue('paymentTerms', res?.data?.data?.customerId || '');
//         formik.setFieldValue('deliveryTerms', res?.data?.data?.deliveryTerms || '');
//         setStartLoading(false);
//       })
//       .catch(err => {
//         console.log(err, "err");
//       });
//   }
// };

// useEffect(() => {
//   const totalPrice = prlist.reduce((total, item) => {
//     return total + (parseFloat(item.price) * parseFloat(item.quantity || 0));
//   }, 0);

//   formik.setFieldValue('totalPrice', totalPrice);
// }, [prlist]);

// useEffect(() => {
//   if (formik.values.prId) {
//     getPr(formik.values.prId);
  

//   }
// }, [formik.values.prId]);

// const handlePriceChange = (e, index) => {
//   const updatedItems = [...prlist];
//   updatedItems[index].price = e.target.value;
//   setPrlist(updatedItems);
// };



// const handleQuantityChange = (index, newQuantity) => {
//   const updatedItems = [...prlist];
//   updatedItems[index].quantity = newQuantity;
//   setPrlist(updatedItems);
// };

// const handleIncrement = (index) => {
//   handleQuantityChange(index, prlist[index].quantity + 1);
// };

// const handleDecrement = (index) => {
//   handleQuantityChange(index, Math.max(1, prlist[index].quantity - 1));
// };

// const handleInputChange = (event, index) => {
//   const value = Math.max(Number(event.target.value));
//   handleQuantityChange(index, value);
// };
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            // { name: "Sales",
            // link: "/sales/quotes" },
            {
              name: "Customer Quotation",
              link: "/sales/quotes",
            },
            {
              name: "Create Customer Quotation",
            },
          ]}
        />
        <BackButton />
      </div>
      <FormikSelectTwo
              label="Purchase Request"
              placeholder="Choose"
              Options={PR}
              formik={formik}
              name="prId"
              width="w-full md:w-1/2 lg:w-1/3"
        />
      <div className=" w-full h-8 flex items-center px-1 ">
      <>
          <Link
            to={`/sales/quotes/create`}
            state={{data:{prId:formik.values.prId}}}
            className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${
              `/sales/quotes/create` == pathFile
                ? "border-b-2 border-baseColor-primary"
                : "border-b-2 border-transparent"
            }   `}
          >
            {"Create Quote"}
          </Link>
        </>
      <>
          <Link
            to={`/sales/quotes/create/viewqoutes`}
            state={{data:{prId:formik.values.prId}}}
            className={`mx-3 pb-1 text-xs 2xl:text-sm font-medium text-baseColor-primary ${
              `/sales/quotes/create/viewqoutes` == pathFile
                ? "border-b-2 border-baseColor-primary"
                : ""
            }   `}
          >
            {"Quote Comparison"}
          </Link>
        </>
       
      </div>
      <div className=' w-full h-[calc(100vh-250px)] '>
        <Outlet context={{ prId: formik.values.prId }} />
      </div>
    </div>
  );
}

export default CreateCustomerQuotes;