import React from "react";
import PrFromCustomer from "./PrFromCustomer";
import QuotationToCustomer from "./QuotationToCustomer";
import SalesOrderFromCustomer from "./SalesOrderFromCustomer";
import Invoice from "./Invoice";
import InvoiceIncoming from "./Incoming";

function Approval() {
  return (
    <div className=" w-full h-full p-2 bg-white rounded-lg shadow-lg overflow-hidden mb-4">
      <p className="text-sm text-[#4F4768] font-medium ">Approval</p>
      <div className="flex w-full ">
        <PrFromCustomer />
        <div className="border-r border-[1px] border-slate-200/60 border-solid" />
        <QuotationToCustomer />

        {/* <div className='border-r border-[1px] border-slate-200/60 border-solid'/>

     <InvoiceIncoming/>
      <div className='border-r border-[1px] border-slate-200/60 border-solid'/>

    <Invoice/> */}
      </div>
    </div>
  );
}

export default Approval;
