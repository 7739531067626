
import React, { useEffect, useMemo, useState } from "react";
import {CreateButton} from "../../../../Components/Utils/Buttons";
import ModalContainer from "../../../../Components/Others/ModalContainer";
import {TextMediumXS,} from "../../../../Components/Text/MediumText";
import { TextSM, TextXS } from "../../../../Components/Text/NormalText";
import axiosInstance from "../../../../Services/axiosCommon";
import NuAmount from "../../../../Components/Utils/NuAmount";
import { useToast } from "../../../../Components/Others/ToastServices";
import { useCurrency } from "../../../../Context/CurrencyContext";


function ViewQuotesByPr({viewqoutesId,startLoading=true,fullview=false,setFullView=()=>{}}) {
  const toast = useToast();
  const [vendorNameSelected, setVendorNameSelected] = useState("")
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMarginModalOpen, setIsMarginModalOpen] = useState(false);
  const [marginValue, setMarginValue] = useState(0);
  const [marginChange, setMarginChange] = useState("");
  const [createNew, setCreateNew] = useState({
    currencyId: null,
    duttyCost: 0,
    saberCost: 0,
    tptCost: 0,
    landedCost: 0,
    otherCost: 0,
    itemCount: 0,
  });

  const [total, setTotal] = useState(0);
  const [totalPerProduct, setTotalPerProduct] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [vendorValue, setVendorValue] = useState()
  const [finalProId,setFfinalProId]=useState([])
  const [sellingPrices,setSellingPrices]=useState({})

  const [dynamicHeaderCount, setDynamicHeaderCount] = useState(3);
  const [data, setData] = useState([]);

  const [stateValue, setStateValue] = useState([]);
  const [supplierList, setSuppliersList] = useState([]);
const [isLoading,setIsLoading]=useState(true)

  function getData(prId,vendorId) {
console.log("vendor",vendorId);
    axiosInstance.get(`/supplier-quotations/calculation/${prId}/${vendorId}`).then((res) => {
      setCreateNew({
        duttyCost: res?.data?.data?.duttyCost || 0,
        tptCost: res?.data?.data?.tptCost || 0,
        landedCost: res?.data?.data?.landedCost || 0,
        otherCost: res?.data?.data?.otherCost || 0,
        saberCost: res?.data?.data?.saberCost || 0,
        itemCount: res?.data?.data?.itemCount || 0,
        currencyId: res?.data?.data?.currencyId,
      });
    })
    .catch(err => {
      console.log(err, "err");
  })
  }
  useEffect(() => {
    getData();
  }, []);

  const handleLCChange = (e, supId, proId) => {
    let List = [...stateValue];
    let value = List.find((i) => i.vendorId === supId && i.productId === proId);
    if (value) {
      value.landedCost = parseFloat(e.target.value).toFixed(2);

      // Calculate the selling price ((unitPrice+landingCost)*100)/(100-GP)
      let unitPrice = !isNaN(value.convertedPrice) ? parseFloat(value.convertedPrice) : 0;
      let LC = !isNaN(value.landedCost) ? parseFloat(value.landedCost) : 0;
      let LCSellingPrice = parseFloat(LC+unitPrice).toFixed(2);
      let gp = !isNaN(value.gp) ? parseFloat(value.gp).toFixed(2) : 0;
      value.sellingPrice = (LCSellingPrice*100)/(100-gp);
      
      setStateValue(List);
      updateSellingPricesLC(value.id, LC)
      console.log("selingPrice...", sellingPrices);
      
    }
  };

  const updateSellingPricesLC = (id, LC) => {
    setSellingPrices((prevPrices) => ({
        ...prevPrices,
        [id]: {
            ...prevPrices[id],  // Preserve existing landedCost and other properties
            landedCost: LC              // Update the gp value
        }
    }));
};




  function getViewQuotes(prId) {
    setIsLoading(true)

    axiosInstance
      .get(`/supplier-quotations/quot-compare/${prId}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          setData(res.data?.data?.products);
          setProductCount(res?.data?.data?.products?.length || 0);
          setDynamicHeaderCount( res.data?.data?.vendorNames ? res.data?.data?.vendorNames.length : 0);
          setSuppliersList(res.data?.data?.vendorNames);
          console.log(res.data?.data?.vendorNames,"kknma");
          
          setStateValue(res.data?.data?.vendors);
          let vendorsItem =res.data?.data?.vendors||[]
          let TempValue =[]
          vendorsItem.forEach(i=>{
            if(i.isSelected){
              TempValue.push(i.id)
            }
          })
          setFfinalProId(TempValue)
          setIsLoading(false)

        }
      });
  }
  

  const [currency,setCurrency] = useState([]);
  async function getCurrency() {
    axiosInstance.get('/currency/').then(res => {
        console.log('daataa',res.data.data);
        let data = res.data.data;
        console.log(data,'dddd');
        let tempData = [];
        data.forEach((item) => {
            let tempObject = { value: item.id, label: item.currency };
            tempData.push(tempObject);
        });
        res.data.data && setCurrency(tempData);
    }).catch(err => {
        console.log(err, "err");
    })
  }

  const [vatRate,setVatRate] = useState();
  async function getVatRate() {
    axiosInstance.get('/vat/').then(res => {
        console.log('vat',res.data.data);
        // let data = res.data.data;
        res.data.data && setVatRate(res.data.data.vatRate);
    }).catch(err => {
        console.log(err, "err");
    })
  }

  useEffect(() => {
    getCurrency();
    getVatRate();
    getViewQuotes(viewqoutesId);
  }, [viewqoutesId]);

  useEffect(() => {
    setTotalPerProduct(total / setCreateNew.itemCount);
  }, [total]);


  function saveSellingPrice(){
    axiosInstance.put(`/supplier-quotations/quot-compare/selling-price/${viewqoutesId}`,sellingPrices).then(res=>{
      if (res.data?.status === true) {
        toast.open({
          type: "success",
          message: "Success",
          description: res.data?.msg || "Selling Price updated.",
        });
      } else if (res.data?.status === false) {
        toast.open({
          type: "error",
          message: "Failed",
          description: res.data.error || "Selling Price not updated.",
        });
      } else {
        toast.open({
          type: "warning",
          message: "Failed",
          description: res.data?.msg || "Selling Price not updated.",
        });
      }
    }).catch(err=>{
      console.log(err,"err");
      toast.open({
        type: "error",
        message: "Error",
        description: "Network error.",
      });
    })
  }

  const submitEdit = ()=>{
    saveSellingPrice();
    // saveProducts();
  }
;
const { currencySymbol } = useCurrency();

  const dynamicHeaders = Array.from(
    { length: dynamicHeaderCount },
    (_, index) => (
      <React.Fragment key={`dynamic-header-${index}`}>
        <th className=" max-w-[550px] z-0 border-r border-[#acaaaa]">
          <div className="w-full border border-x-0 border-y-0 bg-[#C4C2C2]-300  py-1">
            <div className="w-full py-1 flex justify-center items-center">
              <div className="px-2">
                {supplierList[index]?.name && supplierList[index]?.name || `Supplier ${index + 1}`}
              </div>
              <div className="cursor-pointer"
                onClick={async () => {
                  await getData(viewqoutesId, supplierList[index]?.id)
                  let tempvalue = stateValue.filter(item=>item.vendorId == supplierList[index]?.id)
                  setVendorValue(tempvalue)
                  setVendorNameSelected(supplierList[index]?.name||"Supplier")
                  setIsModalOpen(true)
                }}>
                {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="size-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V13.5Zm0 2.25h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V18Zm2.498-6.75h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V13.5Zm0 2.25h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V18Zm2.504-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5Zm0 2.25h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V18Zm2.498-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5ZM8.25 6h7.5v2.25h-7.5V6ZM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0 0 12 2.25Z" />
                </svg> */}
              </div> 
              <div className=" ml-2 h-7">
                <div className=" text-left font-medium text-[9px] leading-3">Ref : {supplierList[index]?.ref||"#"}</div>
                <div className=" text-left font-medium text-[9px] leading-3">Prepared By : <span className=" capitalize">{supplierList[index]?.preparedBy||"-"}</span> </div>
              </div>
              
            </div>

            <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] mx-auto">
            </div>

            <div>
              <th className="w-[200px] px-2 py-1 overflow-hidden">Unit Price 
                <div className="text-xs">({currencySymbol})</div>
               
            </th>
              <th className="w-[150px] px-2 py-1">
                  Landing Cost
              </th>
              <th className="w-[200px] px-2 py-1 ">Total Price</th>
            </div>
          </div>
        </th>
      </React.Fragment>
    )
  );




 
  return (
    <div className="w-full h-full px-4 py-2">
     
      <div className="w-full overflow-x-auto max-w-full bg-white rounded-lg">
        <div className=" w-full min-h-[calc(100vh-350px)] max-h-[calc(100vh-300px)] overflow-y-scroll">

{
     (isLoading==true  || startLoading==true)?(
 <>
         <div className="w-full">
            {[...Array(5)].map((_, index) => (
              <div key={index} className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-200 h-10 w-10"></div>
                <div className="flex-1 space-y-6 py-1">
                  <div className="h-4 bg-slate-200 rounded"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-4 bg-slate-200 rounded col-span-2"></div>
                      <div className="h-4 bg-slate-200 rounded col-span-1"></div>
                    </div>
                    <div className="h-4 bg-slate-200 rounded"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
     )
     :
     (
      <div className={supplierList?.length>10 ? `w-[calc((100vw-300px)*4.9)]`: supplierList?.length>6 ? `w-[calc((100vw-300px)*3.9)]`:supplierList?.length>4 ? `w-[calc((100vw-300px)*2.9)]`:supplierList?.length>1 ? `w-[calc((100vw-300px)*1.8)]`:`w-full overflow-x-scroll relative`}>
          <table className="table-auto relative  border-collapse border border-slate-300 z-0">
            <thead className="bg-baseColor-primary sticky top-0 text-white text-xs 2xl:text-sm z-[1]">
              <tr>
                <th colSpan={supplierList?.length>1 ?3:0} className=" py-1 sticky  left-0 bg-baseColor-primary z-[1] border-r border-[#acaaaa]">
                  <div className=" flex  items-center">
                    <div className=" w-[80px] text-left pl-3">S.N.</div>
                    <div className=" w-[300px] text-left">Product</div>
                    <div className=" w-[100px] text-left">Unit</div>
                    <div className=" w-[100px] text-left">Qty</div>
                  </div>
                </th>                
                {dynamicHeaders}
              </tr>
            </thead>
            <tbody className="">
              {data && data.map((item, index) => (
                <tr
                  key={index}
                  className="odd:bg-white even:bg-slate-50  text-xs 2xl:text-sm border-collapse border border-slate-300"
                >
                  <td colSpan={supplierList?.length>1 ?3:0} className={`py-2 text-center sticky left-0 ${index%2!=0?"bg-slate-50":"bg-white"} z-[0] `}>
                  <div className=" flex  items-center">
                    <div className=" w-[80px] text-left pl-3">{index+1}</div>
                    <div className=" w-[300px] text-left line-clamp-2">{item.name}</div>
                    <div className=" w-[100px] text-left">{item.unit}</div>
                    <div className=" w-[100px] text-left">{item.requestedQty}</div>
                  </div>
                  </td>
                  {supplierList.map((sub, index) => {
                    const supplier = stateValue.find(
                      (i) => i.productId === item.id && i.quoteId === sub.quoteId
                    );
                    if (supplier) {
                      return (
                        <React.Fragment key={`dynamic-cell-${index}-${index}`}>

                          <td >
                            <div className="w-full">
                            <td
  className={`py-2 text-center border-collapse border-x border-y-0 w-[200px] px-2 border-slate-300`}
>
  <div>
   {supplier?.convertedPrice || 0} 
    <div className="text-[10px] text-gray-500">
      {supplier?.leadTime || "Lead Time : None"} 
    </div>
  </div>
</td>

                              <td
                                className={`py-2 text-center border-collapse border-x border-y-0  w-[150px] px-2  border-slate-300`}
                              >
                                <div
                                  className=" w-full h-full flex justify-evenly px-1"
                                >
                                  <input
                                    type="number"
                                    className="w-full px-2 py-1 text-center focus:outline-none rounded bg-slate-200"
                                    value={parseFloat(supplier.landedCost)}
                                    disabled={true}
                                    onChange={(e) => handleLCChange(e, supplier.vendorId, supplier.productId)
                                    }
                                  />
                                </div>
                              </td>

                              <td
                                className={`py-2 text-center border-collapse border-x border-y-0 w-[200px] px-2 ${supplier.isLow == true && supplier.isHigh == true
                                  ? "bg-green-100"
                                  : supplier.isHigh == true
                                    ? "bg-red-100"
                                    : supplier.isLow == true
                                      ? "bg-green-100"
                                      : ""
                                } border-slate-300`}
                              >
                                <div
                                  className=" w-full h-full"
                                >
                                  {supplier?.LCSP || 0}
                                </div>
                              </td>
                            </div>
                          </td>

                         
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={`dynamic-cell-${index}-${index}`}>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className=" border-slate-300"></td>
                        </React.Fragment>
                      );
                    }
                  })}
                </tr>
              ))}
         <tr className="bg-white text-xs 2xl:text-sm border-collapse border border-slate-300 font-bold">
  <td colSpan={supplierList.length>1?3:0} className="py-2 text-center sticky left-0 z-[0] bg-white">
  </td>

  {supplierList.map((sub, supplierIndex) => {
    const totalLandingCost = stateValue
      .filter(i => i.quoteId === sub.quoteId)
      .reduce((total, supplier) => total + parseFloat(supplier.landedCost || 0), 0);

    const totalPrice = stateValue
      .filter(i => i.quoteId === sub.quoteId)
      .reduce((total, supplier) => total + parseFloat(supplier.LCSP || 0), 0);

    return (
      <React.Fragment key={`total-cell-${supplierIndex}`}>
        <td className=" max-w-[550px] ">
          <div className="w-full">
            <td
              className={`py-2 text-center border-collapse border-x border-y-0 w-[200px] px-2 border-slate-300`}
            ></td>
            <td className="py-2 text-center border-collapse border-x border-y-0 w-[150px] px-2 border-slate-300">
              <div className="flex gap-2 text-[10px] text-gray-500 justify-center">
                <span>Total:</span>
                <NuAmount value={totalLandingCost} />
              </div>
            </td>

            <td className="py-2 text-center border-collapse border-x border-y-0 w-[200px] px-2 border-slate-300">
              <div className="flex gap-2 text-[10px] text-gray-500 justify-center">
                <span>Total:</span>
                <NuAmount value={totalPrice} />
              </div>
            </td>
          </div>
        </td>
      </React.Fragment>
    );
  })}
</tr>


            </tbody>
          </table>
          
          </div>
     )
}
        </div>
      </div>
        <div className=" w-full flex items-center px-4 h-[60px]">
          <div className=" w-[calc(84vw-128px)]">
          <div className=" flex gap-3 py-2 flex-wrap">
            {/* <TextMediumXS color="text-red-700 py-2" content="Notes : "/>
              <span className=" flex gap-1 items-center">
                <TextSM color="text-blue-700" otherStyles="leading-3" content="You can edit Landed cost. " />
              </span> */}
            </div>
            <div className=" flex gap-3 flex-wrap">
              <span className=" flex gap-1 items-center">
                <div className=" w-4 h-4 border border-solid border-green-500 rounded-full bg-green-100"></div>
                <TextXS content="Lowest Price  ," />
              </span>

              <span className=" flex gap-1 py-2 items-center">
                <div className=" w-4 h-4 border border-solid border-red-500 rounded-full bg-red-100"></div>
                <TextXS content="Highest Price. " />
              </span>
            </div>
          </div>
          <div className=" w-44 flex gap-3 justify-end">
            {/* <CreateButton label="Save" handleClick={submitEdit} svg={false} /> */}
          </div>
        </div>
        
        <ModalContainer
        visiable={isModalOpen}
        title=""
        hasButton={false}
        content={
          <>
          <div className=" w-full relative">
          <div className="w-full -mt-3 mb-2 flex justify-between items-center">
            <p className=" font-semibold text-sm">Vat & Gross</p>
            <button
             className="absolute top-0 right-0 text-white bg-red-600"
             onClick={() => setIsModalOpen(false)}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
          </div>
            <div className=" ">
           <p className=" mb-2 text-sm font-medium"> Supplier : {vendorNameSelected||"-" }</p>
            <table className="w-full border-collapse items-center">
  <thead>
    <tr className="bg-gray-200 text-[12px]">
      {['S.No', 'Product Name', 'Currency', 'VAT Rate', 'VAT Amount', 'Gross Amount'].map((header, index) => (
        <th key={index} className="border border-black p-2 text-left font-bold">{header}</th>
      ))}
    </tr>
  </thead>
  <tbody>
  {vendorValue?.length > 0 || vatRate?.length > 0 ? (
  vendorValue.map((product, index) => (
    <tr key={index} className="border-y border-black text-[12px]">
      <td className="border border-black p-2">{index + 1}</td>
      <td className="border border-black p-2">{product?.productName || 'N/A'}</td>
      <td className="border border-black p-2">{product?.currencyLabel || 'N/A'}</td>
      <td className="border border-black p-2">{vatRate !== undefined ? `${vatRate}%` : '0%'}</td>
      <td className="border border-black p-2">
        {typeof product?.vat === 'number' ? product?.vat?.toFixed(2) : '0.00'}
      </td>
      <td className="border border-black p-2">
        {typeof product?.gross === 'number' ? product?.gross?.toFixed(2) : '0.00'}
      </td>
    </tr>
  ))
    ) : (
      <tr>
        <td colSpan="6" className="border border-black p-2 text-center">No data available.</td>
      </tr>
    )}
  </tbody>
</table>


            </div>

          </div>
          </>
        }
        handleClick={() => {
         setVendorValue([])
          setIsEditing(false);
          setIsModalOpen(false); 
        }}
        closeModal={() => {
         setVendorValue([])

          setIsEditing(false);
          setIsModalOpen(false);
        }}
      />

    <ModalContainer
        visiable={fullview}
        title=""
        hasButton={false}
        content={
          <>
           <div className=" w-full relative h-[90vh]">
           <div className="w-full -mt-3 mb-2 flex justify-between items-center">
            <p className=" font-semibold text-sm">Full View</p>
            <button
             className="absolute top-0 right-0 text-white bg-red-600"
             onClick={() => setFullView(false)}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
          </div> 
          <div className="w-full overflow-x-auto max-w-full bg-white rounded-lg">
        <div className=" w-full min-h-[calc(100vh-350px)] max-h-[calc(100vh-300px)] overflow-y-scroll">

{
     (isLoading==true  || startLoading==true)?(
 <>
         <div className="w-full">
            {[...Array(5)].map((_, index) => (
              <div key={index} className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-200 h-10 w-10"></div>
                <div className="flex-1 space-y-6 py-1">
                  <div className="h-4 bg-slate-200 rounded"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-4 bg-slate-200 rounded col-span-2"></div>
                      <div className="h-4 bg-slate-200 rounded col-span-1"></div>
                    </div>
                    <div className="h-4 bg-slate-200 rounded"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
     )
     :
     (
      <div className={supplierList?.length>10 ? `w-[calc((100vw-300px)*4.9)]`: supplierList?.length>6 ? `w-[calc((100vw-300px)*3.9)]`:supplierList?.length>4 ? `w-[calc((100vw-300px)*2.9)]`:supplierList?.length>1 ? `w-[calc((100vw-300px)*1.8)]`:`w-full overflow-x-scroll relative`}>
          <table className="table-auto relative  border-collapse border border-slate-300 z-0">
            <thead className="bg-baseColor-primary sticky top-0 text-white text-xs 2xl:text-sm z-[1]">
              <tr>
                <th colSpan={supplierList?.length>1 ?3:0} className=" py-1 sticky  left-0 bg-baseColor-primary z-[1] border-r border-[#acaaaa]">
                  <div className=" flex  items-center">
                    <div className=" w-[80px] text-left pl-3">S.N</div>
                    <div className=" w-[300px] text-left">Product</div>
                    <div className=" w-[100px] text-left">Unit</div>
                    <div className=" w-[100px] text-left">Qty</div>
                  </div>
                </th>                
                {dynamicHeaders}
              </tr>
            </thead>
            <tbody className="">
              {data && data.map((item, index) => (
                <tr
                  key={index}
                  className="odd:bg-white even:bg-slate-50  text-xs 2xl:text-sm border-collapse border border-slate-300"
                >
                  <td colSpan={supplierList?.length>1 ?3:0} className={`py-2 text-center sticky left-0 ${index%2!=0?"bg-slate-50":"bg-white"} z-[0] `}>
                  <div className=" flex  items-center">
                    <div className=" w-[80px] text-left pl-3">{index+1}</div>
                    <div className=" w-[300px] text-left line-clamp-2">{item.name}</div>
                    <div className=" w-[100px] text-left">{item.unit}</div>
                    <div className=" w-[100px] text-left">{item.requestedQty}</div>
                  </div>
                  </td>
                  {supplierList.map((sub, index) => {
                    const supplier = stateValue.find(
                      (i) => i.productId === item.id && i.quoteId === sub.quoteId
                    );
                    if (supplier) {
                      return (
                        <React.Fragment key={`dynamic-cell-${index}-${index}`}>

                          <td >
                            <div className="w-full">
                              <td
                                className={`py-2 text-center border-collapse border-x border-y-0 w-[200px] px-2  border-slate-300`}
                              >
                                {
                                    supplier?.convertedPrice || 0
                                  }
                                
                                 <div className="text-[10px] text-gray-500">
      {supplier?.leadTime || "Lead Time : None"} 
    </div>
                              </td>

                              <td
                                className={`py-2 text-center border-collapse border-x border-y-0  w-[150px] px-2  border-slate-300`}
                              >
                                <div
                                  className=" w-full h-full flex justify-evenly px-1"
                                >
                                  <input
                                    type="number"
                                    className="w-full px-2 py-1 text-center focus:outline-none rounded bg-slate-200"
                                    value={parseFloat(supplier.landedCost)}
                                    disabled={true}
                                    onChange={(e) => handleLCChange(e, supplier.vendorId, supplier.productId)
                                    }
                                  />
                                </div>
                              </td>

                              <td
                                className={`py-2 text-center border-collapse border-x border-y-0 w-[200px] px-2 ${supplier.isLow == true && supplier.isHigh == true
                                  ? "bg-green-100"
                                  : supplier.isHigh == true
                                    ? "bg-red-100"
                                    : supplier.isLow == true
                                      ? "bg-green-100"
                                      : ""
                                } border-slate-300`}
                              >
                                <div
                                  className=" w-full h-full"
                                >
                                  {supplier?.LCSP || 0}
                                </div>
                              </td>
                            </div>
                          </td>

                         
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={`dynamic-cell-${index}-${index}`}>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className="border-collapse border-x border-y-0 border-slate-300"></td>
                          <td className=" border-slate-300"></td>
                        </React.Fragment>
                      );
                    }
                  })}
                </tr>
              ))}
               <tr className="bg-white text-xs 2xl:text-sm border-collapse border border-slate-300 font-bold">
  <td colSpan={supplierList.length>1?3:0} className="py-2 text-center sticky left-0 z-[0] bg-white">
  </td>

  {supplierList.map((sub, supplierIndex) => {
    const totalLandingCost = stateValue
      .filter(i => i.quoteId === sub.quoteId)
      .reduce((total, supplier) => total + parseFloat(supplier.landedCost || 0), 0);

    const totalPrice = stateValue
      .filter(i => i.quoteId === sub.quoteId)
      .reduce((total, supplier) => total + parseFloat(supplier.LCSP || 0), 0);

    return (
      <React.Fragment key={`total-cell-${supplierIndex}`}>
        <td className=" max-w-[550px] ">
          <div className="w-full">
            <td
              className={`py-2 text-center border-collapse border-x border-y-0 w-[200px] px-2 border-slate-300`}
            ></td>
            <td className="py-2 text-center border-collapse border-x border-y-0 w-[150px] px-2 border-slate-300">
              <div className="flex gap-2 text-[10px] text-gray-500 justify-center">
                <span>Total:</span>
                <NuAmount value={totalLandingCost} />
              </div>
            </td>

            <td className="py-2 text-center border-collapse border-x border-y-0 w-[200px] px-2 border-slate-300">
              <div className="flex gap-2 text-[10px] text-gray-500 justify-center">
                <span>Total:</span>
                <NuAmount value={totalPrice} />
              </div>
            </td>
          </div>
        </td>
      </React.Fragment>
    );
  })}
</tr>

            </tbody>
          </table>
          
          </div>
     )
}
        </div>
      </div>
           </div> 
          </>
        }
        handleClick={() => {setFullView(false)}}
        closeModal={() => setFullView(false)}
    />
    </div>

    
  );
}

export default ViewQuotesByPr;