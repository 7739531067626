import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useEffect, useState } from "react";
import Input, { NuInput, NuSelect, NuTextArea } from "../../../../Components/Utils/Input";
import UseEditData from "../../../../Components/Hooks/useEditData";
import { useLocation } from "react-router-dom";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import axiosInstance from "../../../../Services/axiosCommon";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";

const EditRequest = () => {
  const {value:Unit} = useFetchMataData(`/dropdown/dropdown-details/`,'Unit_item')
  const {value:RequestFor} = useFetchMataData(`/dropdown/dropdown-details/`,'RequestFor')
  const {value:PurposeOfRequest} = useFetchMataData(`/dropdown/dropdown-details/`,'PurposeOfRequest')
  const {value:RequestStatus,getData:refRequestStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'RequestStatus')
  const [loading, setLoading] = useState(false);

  const { Oncancel, editData, contextHolder, isLoading } = UseEditData();
    const location = useLocation();
    const data = location.state.data;
    const from = location.state.from;

  const [createNew, setCreateNew] = useState({
    requestFor: data?.requestFor || "",
    purpose: data?.purpose || "",
    isReturnable: data?.isReturnable || "",
    item: data?.item || "",
    comments: data?.comments || "",
    quantity: data?.quantity || "",
    unit: data?.unit || "",
    approvedBy: data?.approvedBy || "",
    reviewedBy : data?.reviewedBy || "",
    preparedBy: data?.preparedBy || "",
    issueedBy: data?.issueedBy || "",
    status: data?.status || ""

  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      item: yup.string().required('Product is required!'),
      quantity: yup.string().required('Quantity is required!'),

    }),
    onSubmit: async (value, { resetForm }) => {
      setLoading(true)
      editData(`/requests/edit-request/${data.requestId}`, value, resetForm, from)
      setLoading(false)

      
    },
    
  });
  const { data: user } = UseDropDown('/user/drop-down');
  const { data: items } = UseDropDown('/items/drop-down');

  useEffect(() => {
    if (formik.values.item) {
      const selectedProduct = items.find((i) => i.value === formik.values.item);
      if (selectedProduct) {
        formik.setFieldValue('unit', selectedProduct.unit);
      }
    }
  }, [formik.values.item, items]);
  
  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
    <Breadcrumb
         items={
          [
            { name: "Store",
            link: "/store/requests" }, 
          {
            name: "Request",
            link: "/store/requests"
          },
          {
            name: "Edit Request"
          }]
         }
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
                     <FormikSelectTwo
                      label="Product"
                      placeholder="Choose"
                      Options={items}
                      formik={formik}
                      name="item"
                      width="w-full md:w-1/2 lg:w-1/3"
                      isRequired={true}
                    />
                     {/* <FormikSelectTwo
                label="Unit"
                placeholder="Choose"
                Options={Unit}
                formik={formik}
                name="unit"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
                    <NuInput
                      type="number"
                      label="Quantity"
                      placeholder="Quantity"
                      formik={formik}
                      isRequired={true}
                      name="quantity"
                      width="w-full md:w-1/2 lg:w-1/3"
                    />
          <FormikSelectTwo
              type="text"
              label="Request For"
              placeholder="Choose"
              Options={RequestFor}
              formik={formik}
              name="requestFor"
              width="w-full md:w-1/2 lg:w-1/3"
            />  
             <FormikSelectTwo
              type="text"
              label="Purpose Of Request"
              placeholder="Choose"
              Options={PurposeOfRequest}
              formik={formik}
              name="purpose"
              width="w-full md:w-1/2 lg:w-1/3"
            />  
                    <FormikSelectTwo
                      type="text"
                      label="Is Returnable"
                      placeholder="Choose"
                      Options={[
                        { value: true , label: "Yes"},
                        { value: false , label: "No"},
                      ]}
                      formik={formik}
                      name="isReturnable"
                      width="w-full md:w-1/2 lg:w-1/3"
                    />
                   
                    <FormikSelectTwo
                      type="text"
                      label="Issued By"
                      placeholder="Choose"
                      Options={user}
                      formik={formik}
                      name="issueedBy"
                      width="w-full md:w-1/2 lg:w-1/3"
                    /> 
                     <DropDownAddMetaData
                label="Status"
                placeholder="Choose"
                Options={RequestStatus}
                formik={formik}
                name="status"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refRequestStatus}
                displayProperty={"Status"}
                propertyName={"RequestStatus"}
              />   
                     {/* <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Choose"
              Options={RequestStatus}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
            />                 */}
                    <NuTextArea
                      label="Comments"
                      placeholder="comments"
                      formik={formik}
                      name="comments"
                      width="w-full md:w-1/2 lg:w-1/3"
                    />            
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{Oncancel(-1)}} />
                <SubmitButton isLoading={loading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditRequest;
