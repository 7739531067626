import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

const RequestFromCustomer = () => {
  // Sample dummy data
  const dummyData = {
    customer_request_salesman_wise: [
      {
        salesman: 'John Doe',
        catA: 10,
        catB: 20,
        catC: 15,
      },
      {
        salesman: 'Jane Smith',
        catA: 25,
        catB: 10,
        catC: 5,
      },
      {
        salesman: 'Michael Brown',
        catA: 15,
        catB: 30,
        catC: 10,
      },
      {
        salesman: 'Michael Brown',
        catA: 15,
        catB: 30,
        catC: 10,
      }, {
        salesman: 'Michael Brown',
        catA: 15,
        catB: 30,
        catC: 10,
      },
    ],
  };

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // Aggregate totals for catA, catB, and catC
    const totalCatA = dummyData.customer_request_salesman_wise.reduce((sum, item) => sum + item.catA, 0);
    const totalCatB = dummyData.customer_request_salesman_wise.reduce((sum, item) => sum + item.catB, 0);
    const totalCatC = dummyData.customer_request_salesman_wise.reduce((sum, item) => sum + item.catC, 0);

    const formattedData = [
      { name: 'Category A', value: totalCatA },
      { name: 'Category B', value: totalCatB },
      { name: 'Category C', value: totalCatC },
    ];

    setChartData(formattedData);
  }, []);

  const COLORS = ['#0088FE', '#AF2655', '#FFBB28'];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="max-w-[250px] bg-white shadow-md rounded-lg p-[5px]">
          <p className="text-xs font-medium">{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="col-span-2 bg-white h-[40vh] w-full p-4 pb-10 rounded-lg drop-shadow-lg mb-4">
      <div className="flex justify-between items-center mb-2">
        <p className="text-xs text-[#4F4768] font-medium">
          Request From Customer Salesmen-wise (Category Totals)
        </p>
      </div>
      <div className="w-full flex h-[89%]">
        <div className="w-1/2 flex justify-center items-center">
          <ResponsiveContainer width="100%" height={150}>
            <PieChart>
              <Pie
                data={chartData}
                cx="25%"
                cy="50%"
                innerRadius={30}
                outerRadius={60}
                fill="#8884d8"
                dataKey="value"
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      
      <div className="w-1/2 overflow-y-scroll mt-4">
        <div className="w-full sticky top-0 bg-white shadow-sm flex justify-start gap-2 pt-2 text-xs">
          <p className="w-[40%]">Salesman</p>
          <p className="w-[15%]">Cat A</p>
          <p className="w-[15%]">Cat B</p>
          <p className="w-[15%]">Cat C</p>
        </div>
        {dummyData.customer_request_salesman_wise.map((item, index) => (
          <div key={index} className="flex items-center text-xs mb-1">
            <p className="w-[40%]">{item.salesman}</p>
            <p className="w-[15%] text-center">{item.catA}</p>
            <p className="w-[15%] text-center">{item.catB}</p>
            <p className="w-[15%] text-center">{item.catC}</p>
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};

export default RequestFromCustomer;
