import React from "react";
import {
  TextMediumSM,
  TextMediumXS,
} from "../../../../../Components/Text/MediumText";
import UseGetDashBoardData from "../../../../../Components/Hooks/useDashboardData";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

function PoSupplier() {
  const { data, loading } = UseGetDashBoardData(`/dashboard/PO-to-supplier`);

  // const totalRequests = data?.customer_category_wise?.reduce(
  //   (total, item) => total + (item.count || 0),
  //   0
  // );
  const item = {
    not_yet_shipped: 0,
    received: 0,
    status: true,
    this_month: 2,
    total: 2,
    under_clearance: 0,
    under_shipment: 0,
  };
  const chartData = [
    { name: "Received", value: item.received },
    { name: "Under Shipment", value: item.under_shipment },
    { name: "Under Clearance", value: item.under_clearance },
    { name: "Not Yet Shipped", value: item.not_yet_shipped },
  ];

  const COLORS = ['#987D9A', '#6C946F', '#FFB200', '#77E4C8'];

  return (
    <div className="col-span-2 bg-white h-[40vh] p-4  rounded-lg shadow-lg overflow-hidden mb-4">
      <div className="w-full px-1 py-[2px] rounded-md flex justify-between items-center p-4">
        <TextMediumSM
          content={"Purchase Order To Supplier "}
          color="text-[#4F4768]"
        />
        <div className="bg-[#95D2B3] px-2 py-0.5 rounded-md">
          <p className="text-[#333333] text-[12px] font-semibold ">
            {item?.total || 0.00}
          </p>
        </div>
        {/* <TextMediumSM content={` ${totalRequests || 0}`} className="font-bold text-[20px] text-[#333333]" /> */}
      </div>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>
      <p className='pl-4 items-center text-xs 2xl:text-sm'>This month : <span className='text-[#048178] '>{item?.this_month || "0"}</span>
      </p>
      {loading ? (
        <div className="p-4 max-w-sm w-full flex flex-col justify-center items-center h-full">
          <div className="animate-pulse flex space-x-4 w-full">
            <div className="flex-1 space-y-3 py-1">
              <div className="h-3 bg-slate-200 rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                  <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                </div>
                <div className="h-3 bg-slate-200 rounded"></div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                  <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-[98%] overflow-y-scroll">
        <div className="flex">
          <div className="w-1/3 flex justify-center items-center">
            <ResponsiveContainer width="100%" height={150}>
              <PieChart>
                <Pie
                  data={chartData}
                  cx="50%"
                  cy="50%"
                  innerRadius={30}
                  outerRadius={50}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {chartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="w-[70%] flex flex-col justify-center">
            {chartData.map((item, index) => (
              <div
                key={index}
                className="flex  flex-wrap items-center text-xs mb-1"
              >
                <span
                  className="inline-block w-2 h-2 rounded-full mr-2"
                  style={{ backgroundColor: COLORS[index % COLORS.length] }}
                ></span>
                <p className="w-[50%] ">{item.name}</p>
                <p className="w-[20%]">: {item.value}</p>
                {/* <p className="1/4"> {item.amount}</p> */}
              </div>
            ))}
          </div>
         

        </div>
        </div>
        
      )}
    </div>
  );
}

export default PoSupplier;
