import React from "react";
import UseGetDashBoardData from "../../../../Components/Hooks/useDashboardData";
import { FaCheckCircle, FaHourglassHalf } from 'react-icons/fa';
import PrFromCustomer from "./PrFromCustomer";
import QuotationToCustomer from "./QuotationToCustomer";
import SalesOrderToCustomer from "./SalesOrderToCustomer";
import PuschaseOrderToSupplier from "./PuschaseOrderToSupplier";
import GRNApproval from "./GRNApproval";
import DeliveryNotesApproval from "./DeliveryNotesApproval";
import InvoiceApproval from "./InvoiceApproval";

function Approvals() {
  // const { data, loading } = UseGetDashBoardData('');

  return (
    <div className="col-span-4 bg-white max-h-[55vh] p-2 mb-5  rounded-lg shadow-lg overflow-y-auto">
      <p className="text-xs 2xl:text-sm font-medium pl-2 text-[#4F4768]">Approvals</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2 mb-4"></div>

      <div className="flex flex-wrap gap-1 overflow-y-auto">
       <PrFromCustomer/>
      <div className='border-r border-[1px] border-slate-200/60 border-solid'/>

        <QuotationToCustomer
        />
      <div className='border-r border-[1px] border-slate-200/60 border-solid'/>

        <PuschaseOrderToSupplier
        />
      <div className='border-r border-[1px] border-slate-200/60 border-solid'/>

        <GRNApproval
        />
      <div className='border-r border-[1px] border-slate-200/60 border-solid'/>

        <DeliveryNotesApproval
        />
       
      </div>
    </div>
  );
}


export default Approvals;
