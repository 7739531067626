import React, { useEffect, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { Link ,useLocation, useParams} from 'react-router-dom';
import { useFormik } from 'formik';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import * as yup from 'yup';
import { NuDataTime, NuInput, NuTextArea,NuDate } from "../../../../Components/Utils/Input";
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import axiosInstance from '../../../../Services/axiosCommon';

function EditEquipments() {
  const {value:EquipmentType} = useFetchMataData(`/dropdown/dropdown-details/`,'EquipmentType')
  const {value:EquipmentBrand} = useFetchMataData(`/dropdown/dropdown-details/`,'EquipmentBrand')
  const {value:EquipmentManufacturer} = useFetchMataData(`/dropdown/dropdown-details/`,'EquipmentManufacturer')
  const {value:EquipmentUnit} = useFetchMataData(`/dropdown/dropdown-details/`,'EquipmentUnit')
  const {value:EquipmentWarrentyPeriod} = useFetchMataData(`/dropdown/dropdown-details/`,'EquipmentWarrentyPeriod')
  const { equipmentEditId } = useParams();
  const [loading, setLoading] = useState(false);

    const [image, setImage] = useState({ preview: "", raw: "" });
    const location = useLocation();
    const data = location.state.data;
    const from = location.state.from;
    console.log(location.state,'state');
    // const [division, setDivision] = useState([]);
    const { Oncancel, editFormData,contextHolder,isLoading } = useEditFormData();
    const { data:users} = UseDropDown("/user/drop-down");

    const [createNew, setCreateNew] = useState({
      companyId: 1,
      equipmentType: data?.equipmentType,
      serialNo: data?.serialNo,
      brand: data?.brand,
      manufacturer: data?.manufacturer,
      description: data?.description,
      unit: data?.unit,
      warrentyExpDate: data?.warrentyExpDate,
      warrentyPeriod: data?.warrentyPeriod,
      calibReq:data?.calibReq,
      calibExpDate:data?.calibExpDate,
      comments:data?.comments,
      quantity:data?.quantity,
   

    })
    
    // useEffect(()=>{
    //   let objectlength=Object.keys(data).length;
    //   console.log(objectlength,"length");
      
    //   if(objectlength>1){
    //     setCreateNew( {companyId: 1,
    //       equipmentType: data?.equipmentType || "",
    //       serialNo: data?.serialNo || "" ,
    //       brand: data?.brand || "" ,
    //       manufacturer: data?.manufacturer || "" ,
    //       description: data?.description || "" ,
    //       unit: data?.unit || "" ,
    //       warrentyExpDate: data?.warrentyExpDate || "" ,
    //       warrentyPeriod: data?.warrentyPeriod || "" ,
    //       calibReq:data?.calibReq || "" ,
    //       calibExpDate:data?.calibExpDate || "" ,
    //       comments:data?.comments || "" ,
    //       quantity:data?.quantity || "" ,
    //   })
    //   }else if(objectlength == 1){
    //     console.log(data,"kk");
    //     getById(equipmentEditId)
  
    //   }
      
  
    // },[data])
  
    // function getById(quotesEditId){
    //   axiosInstance.get(`/equipments/${equipmentEditId}`).then(res=>{
    //       setCreateNew({
    //         companyId: 1,
    //         equipmentType: res?.data?.data?.equipmentType || "",
    //         serialNo: res?.data?.data?.serialNo || "" ,
    //         brand: res?.data?.data?.brand || "" ,
    //         manufacturer: res?.data?.data?.manufacturer || "" ,
    //         description: res?.data?.data?.description || "" ,
    //         unit: res?.data?.data?.unit || "" ,
    //         warrentyExpDate: res?.data?.data?.warrentyExpDate || "" ,
    //         warrentyPeriod: res?.data?.data?.warrentyPeriod || "" ,
    //         calibReq:res?.data?.data?.calibReq || "" ,
    //         calibExpDate:res?.data?.data?.calibExpDate || "" ,
    //         comments:res?.data?.data?.comments || "" ,
    //         quantity:res?.data?.data?.quantity || "" ,
    //       })
       
          
    //   }).catch(err=>{
    //       console.log("error",err);
          
    //   })
    // }
  

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          setLoading(true); 

            editFormData(`/equipments/edit-equipment/${data.equipmentId}`, value, resetForm, from);
            setLoading(false)
        }
    })
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
   
    <Breadcrumb
          items={[
            {
              name: "Equipments",
              link: "/maintenance/equipment",
            },
            {
              name: "Edit Equipment",
            },
          ]}
        />
    <BackButton/>
    </div> 
   <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full py-2 flex justify-start content-start flex-wrap  overflow-y-auto">
       
          <FormikSelectTwo
              label="Equipment Type"
              placeholder="Choose"
              formik={formik}
              Options={EquipmentType}
              name="equipmentType"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Serial No"
              placeholder="Serial No"
              formik={formik}
              name="serialNo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
           <FormikSelectTwo
                type="text"
                label="Brand"
                placeholder="Choose"
                Options={EquipmentBrand}
                formik={formik}
                name="brand"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            <FormikSelectTwo
                type="text"
                label="Manufacturer"
                placeholder="Choose"
                Options={EquipmentManufacturer}
                formik={formik}
                name="manufacturer"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            
            {/* <NuInput
              type="text"
              label="Description"
              placeholder="Description"
              formik={formik}
              name="description"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
             <FormikSelectTwo
                type="text"
                label="Unit"
                placeholder="Choose"
                Options={EquipmentUnit}
                formik={formik}
                name="unit"
                width="w-full md:w-1/2 lg:w-1/3"
              />
           

            <NuInput
              type="number"
              label="Quantity"
              placeholder="Quantity"
              formik={formik}
              name="quantity"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <NuDate
              label="Warrenty Expiration Date"
              placeholder="Warrenty Expiration Date"
              formik={formik}
              name="warrentyExpDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <FormikSelectTwo
                type="text"
                label="Warrenty Period"
                placeholder="Choose"
                Options={EquipmentWarrentyPeriod}
                formik={formik}
                name="warrentyPeriod"
                width="w-full md:w-1/2 lg:w-1/3"
              />
          
            <FormikSelectTwo
              label="Calibration Req"
              placeholder="Calibration Req"
              formik={formik}
              Options={[
                {
                  value: true,
                  label: "yes",
                },
                {
                  value: false,
                  label: "no",
                },
              ]}
              name="calibReq"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <NuDate
              label="Calibration ExpDate"
              placeholder="Calibration ExpDate"
              formik={formik}
              name="calibExpDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuTextArea
              label="Description"
              placeholder="Description"
              formik={formik}
              name="description"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             
             <div className="w-full my-4 mt-8 ">
              <div className="flex justify-end gap-4">
                <CancelButton handleClick={()=>{Oncancel(-1)}} />
                <SubmitButton isLoading={loading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
 </div>
  )
}

export default EditEquipments