import React, { useEffect, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import UseEditData from '../../../../Components/Hooks/useEditData';
import * as yup from 'yup';
import { NuDate, NuInput, NuTextArea } from '../../../../Components/Utils/Input';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import axiosInstance from '../../../../Services/axiosCommon';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import { useToast } from '../../../../Components/Others/ToastServices';
import Attachement, { videotype } from '../../../../Components/Utils/CustomAttrachmentupload';
import DeleteIcon from '../../../../Components/Icons/Delete';
import CloseIcon from '../../../../Components/Icons/Close';
import Video from '../../../../Components/Icons/Video';
import Doc from '../../../../Components/Icons/Doc';
import Xls from '../../../../Components/Icons/Xls';
import Txt from '../../../../Components/Icons/Txt';
import Pdf from '../../../../Components/Icons/Pdf';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';
const filetype = [
  "docx",
  "doc",
  "xlsx",
  "xls",
  "pdf",
  "txt",
  "pptx",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "flv",
  "mkv",
];
function EditGRN() {
  const {value:GrnStatus ,getData:refGrnStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'GrnStatus')
  const {value:PurchaseType} = useFetchMataData(`/dropdown/dropdown-details/`,'GrnpurchaseType')
  const {value:ApprovalStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'ApprovalStatus_GRN')

  const { data: vendor } = UseDropDown('/vendors/drop-down');
  const { data: user } = UseDropDown('/user/drop-down');
  const { data: PO } = UseDropDown('/PO/drop-down');
  const { data: PR } = UseDropDown('/PR/drop-down');
  const { Oncancel, editFormData, contextHolder } = useEditFormData();
  const [image, setImage] = useState([]);
  const [remove, setRemove] = useState([]);
  const [items, setItems] = useState([]);
  const { data:deliveryNotes } = UseDropDown('/delivery-notes/drop-down');  
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  const [dataa,setData] = useState(data);

  const [fileRemoveList, setFileRemoveList] = useState([]);
  const [newItemData, setNewItemData] = useState({
            orderedQty: "",
            productId: "",
            product_name: "",
            product_unit: "",
            receivedQty: "",
            remainingQty:"" ,
           
             
  });
  const [createNew, setCreateNew] = useState({
    prRef: data?.prRef,
    purchaseType: data?.purchaseType,
    receivedDate: data?.receivedDate,
    vendorId: data?.vendorId,
    poId: data?.poId,
    comments: data.comments,
    status: data.status,
    productId: data.productId,
    orderedQty: data.orderedQty,
    receivedQty: data.receivedQty,
    remainingQty: data.remainingQty,
    approvalStatus: data.approvalStatus,
    dnRef: data.dnRef,
    busyGrnNo: data.busyGrnNo,


   


  })
  const handleDelete = (productId) => {
    const updatedquoteProducts = dataa?.products.filter(item => item.productId !== productId);
console.log(updatedquoteProducts,"pp");
    setData(prevState => ({
      ...prevState,
      products: updatedquoteProducts
    }));
    // setRemove([...remove,productId]);
  };

  const [error,setError] = useState('')
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      poId: yup.string().required('Purchase Order is required!'),
      busyGrnNo: yup.string().required('Busy GRN No. No is required!'),
      dnRef: yup.string().required('Delivery Note Ref. No is required!'),
      prRef: yup.string().required('PR is required!')
    }),

    onSubmit: async (value, { resetForm }) => {
      setLoading(true); 
      if (dataa.products.length === 0) {
        setError('Product table is empty');
        return;
      }
      let formData = new FormData();
      const valueWithItems = {
        ...value,
        products: dataa.products,
 
        filesToRemove: fileRemoveList,
      };
     
      formData.append("json_data", JSON.stringify(valueWithItems));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      editFormData(`/grn/edit-grn/${data.id}`, formData, resetForm, from);
      setLoading(false)
    },
      // editData(`/grn/edit-grn/${data.grnId}`, value, resetForm, from)
   
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [removefilePathList, setRemoveFilePathList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const toast = useToast();

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        // let fileExtention = fileName.split(".").reverse()[0];
        // if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        // } else {
        //   let x = URL.createObjectURL(item);
        //   tempArr.push(x);
        // }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  useEffect(() => {
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      console.log(data.attachments, "ghjkllgd");
      setFilePathList(data?.attachments);
     
      setRemoveFilePathList(data?.attachments);
     
    }
  }, [data]);

  function UpdateAttachmentList(item) {
    let filterList = filePathList.filter((itemList) => itemList.file !== item);
    let removeFile = filePathList.find((itemList) => itemList.file === item);
    console.log("llll", filterList);
    setFileRemoveList([...fileRemoveList, removeFile.file]);
    setFilePathList(filterList);
  }

  function handleImageClick(img) {
    setSelectedImage(img);
    setIsModalOpen(true);
  }
 

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedProducts = [...dataa.products];
    const intValue = parseInt(value);

    if (name === 'receivedQty' && intValue <= updatedProducts[index].orderedQty) {
      updatedProducts[index][name] = intValue;
      updatedProducts[index].remainingQty = updatedProducts[index].orderedQty - updatedProducts[index].receivedQty;
    }

    setData(prevState => ({
      ...prevState,
      products: updatedProducts
    }));
  };

  const handleIncrement = (index, field) => {
    const updatedProducts = [...dataa.products];
    if (field === 'receivedQty' && updatedProducts[index][field] < updatedProducts[index].orderedQty) {
      updatedProducts[index][field] += 1;
      updatedProducts[index].remainingQty = updatedProducts[index].orderedQty - updatedProducts[index].receivedQty;
    }
    setData(prevState => ({
      ...prevState,
      products: updatedProducts
    }));
  };

  const handleDecrement = (index, field) => {
    const updatedProducts = [...dataa.products];
    if (updatedProducts[index][field] > 0) {
      updatedProducts[index][field] -= 1;
      updatedProducts[index].remainingQty = updatedProducts[index].orderedQty - updatedProducts[index].receivedQty;
    }
    setData(prevState => ({
      ...prevState,
      products: updatedProducts
    }));
  };
  useEffect(() => {
    if (dataa.products.length > 0) {
      setError('');
    }
  }, [dataa.products]);

  const removeImages = (value) => {
    console.log("value", value);
    let List = selectedFile.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFile([...List]);
    let tempArr = []
    List.map((item) =>{
      let fileName = item?.name
      // console.log('fname',fileName);
      // let fileExtention =  fileName.split(".").reverse()[0]
      // if(filetype?.includes(fileExtention)){
        tempArr.push(fileName)
      // }else{
      //   let x = URL.createObjectURL(item)
      //   tempArr.push(x)
      // }
    })
    setImage(tempArr)
  };

  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
    <Breadcrumb
          items={[
            // { name: "Store" },

            {
              name: "GRN",
              link: "/store/grn"
            },

            { name: "Edit" },

          ]} />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] mt-4 bg-white rounded-md overflow-hidden">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto">
          {/* <FormikSelectTwo
                type="text"
                label="Purchase Request"
                placeholder="Purchase Request "
                Options={PR}
                formik={formik}
                name="prId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
                disabled={true}
              /> */}
               <NuInput
                   type="text"
                   label="Purchase Request"
                   placeholder="Purchase Request"
                   Options={PR}
                   formik={formik}
                   name="prRef"
                   disabled={true}
                   width="w-full md:w-1/2 lg:w-1/3"
                   isRequired={true}
                 />
          <FormikSelectTwo
              type="text"
              label="Purchase Order"
              placeholder="Purchase Order"
              Options={PO}
              formik={formik}
              disabled={true}
              name="poId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <FormikSelectTwo
              type="text"
              label="Supplier"
              placeholder="Supplier"
              Options={vendor}
              disabled={true}
              formik={formik}
              name="vendorId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
   <NuInput
                   type="text"
                   label="Delivery Notes Ref."
                   placeholder="Reference"
                   Options={deliveryNotes}
                   formik={formik}
                   name="dnRef"
                   width="w-full md:w-1/2 lg:w-1/3"
                   isRequired={true}
                 />
            {/* <FormikSelectTwo
              type="text"
              label="Purchase Type"
              placeholder="Purchase Type"
              Options={PurchaseType}
              formik={formik}
              name="purchaseType"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
              <NuInput
                label="Busy GRN No."
                type="text"
                formik={formik}
                placeholder="Busy GRN No."
                name="busyGrnNo"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
             <NuDate
            label="Received Date "
            placeholder="dd/mm/yyyy"
            formik={formik}
            name="receivedDate"
            width="w-full md:w-1/2 lg:w-1/3"
            />
                 {/* <FormikSelectTwo
            type="text"
            label="Approval Status"
            placeholder="Approval Status"
            Options={ApprovalStatus}
           formik={formik}
           name="approvalStatus"
           width="w-full md:w-1/2 lg:w-1/3"
          />       */}
           <DropDownAddMetaData
                label="Status"
                placeholder="Choose"
                Options={GrnStatus}
                formik={formik}
                name="status"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refGrnStatus}
                displayProperty={"Status"}
                propertyName={"GrnStatus"}
              />
            {/* //   <FormikSelectTwo
            //   type="text"
            //   label="Status"
            //   placeholder="Status"
            //   Options={GrnStatus}
            //   formik={formik}
            //   name="status"
            //   width="w-full md:w-1/2 lg:w-1/3"
            // /> */}
            <NuTextArea
              label="Comments"
              type="text"
              formik={formik}
              placeholder="Comments"
              name="comments"
              new_width="w-full md:w-2/3"

              width="w-full md:w-1/2 lg:w-1/3"
            />
              <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  GRN Attachement :
  </p></div>
             <div className="md:flex justify-between  px-3 w-full">
              <div className="w-full flex gap-2 items-center flex-wrap">
                {data?.attachments &&
                  filePathList.map((imgs, idx) => {
                    console.log("img", imgs);
                    let Ext = imgs?.file_name.split(".").reverse()[0];

                    return (
                      <div
                      className=" w-20 h-9 my-2 relative flex justify-center items-center rounded-lg"
                      key={idx}
                      style={{
                        border: "1px solid gray",
                        borderColor:
                          "rgb(148 163 184 / var(--tw-bg-opacity))",
                      }}
                    >
                      <div
                        className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                        onClick={() => UpdateAttachmentList(imgs?.file)}
                      >
                        <CloseIcon color="white" height="8" width="8" />
                      </div>
                      <div
                        className=" w-20 h-9 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                        onClick={() => handleImageClick(imgs)}
                      >
                        
                        <div className=" w-8 h-8">
                {videotype.includes(Ext) ? <Video /> : null}

                {Ext === ("docx" || "doc"||"txt") ? (
                  <Doc />
                ) : Ext === ("xlsx" || "xls") ? (
                  <Xls />
                ) : Ext === "txt" ? (
                  <Txt />
                ) : Ext === "pdf" ? (
                  <Pdf />
                ) : Ext === "pptx" ? (
                  <>No file</>
                ) : 
                <img src={imgs?.file} alt="#" width="120%" />

                }
                </div>
                <p className=" w-[50px] text-[11px] truncate">{imgs?.file_name}</p>
                      </div>
                    </div>
                    );
                  })}

                <Attachement
                  handleChange={handleImageChange}
                  preview={image || ""}
                  width="w-full md:w-1/2"
                  label="Attach"
                  multiple={true}
                  removeImages={removeImages}
                />
              </div>
            </div>
 <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-2"></div>
            <div className="w-full bg-white overflow-y-scroll mt-5">
            {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
              <div className="w-full py-3 bg-[#048178] flex justify-between rounded-lg px-4">
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

                <p className="w-[40%] text-left text-white text-xs font-medium">Product</p>
                <p className="w-[10%] text-center text-white text-xs font-medium">Unit</p>
                <p className="w-[15%] text-center text-white text-xs font-medium">Ordered Qty</p>
                <p className="w-[20%] text-center text-white text-xs font-medium">Received Qty</p>
                <p className="w-[15%] text-center text-white text-xs font-medium">Remaining Qty</p>
                <p className=" w-[5%] text-center text-white text-xs font-medium">
              Action
              </p>
              </div>
              {dataa?.products?.length>0?
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">

              {dataa?.products.map((List, index) => (
                <div key={index} className="w-full py-2 flex justify-between px-4 odd:bg-white even:bg-slate-100">
                  <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                  <p className="w-[40%] text-left text-[#606279] text-xs font-light">{List?.product_name}</p>
                  <p className="w-[10%] text-center text-[#606279] text-xs font-light">{List?.product_unit}</p>
                  <p className="w-[15%] text-center text-[#606279] text-xs font-light">{List?.orderedQty}</p>
                 
                  <div className="w-[20%] text-center text-[#606279] text-xs font-light">
                    <button
                      type="button"
                      className="border rounded-full p-1"
                      onClick={() => handleDecrement(index,'receivedQty')}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      className="w-[20%] text-center border text-[#606279] text-xs font-light"
                      name="receivedQty"
                      value={List.receivedQty}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                    <button
                      type="button"
                      className="border rounded-full p-1 "
                      onClick={() => handleIncrement(index, 'receivedQty')}
                    >
                      +
                    </button>
                  </div>
                  <p className="w-[15%] text-center text-[#606279] text-xs font-light">{List?.remainingQty}</p>
                  <div className='w-[5%] text-center'> 
                 <button
                    onClick={() => handleDelete(List?.productId)}
                    type="button"
                    className="text-red-500 cursor-pointer  ml-4 border-none"
                  >
                    <DeleteIcon
                     color='red'
                     height={4}
                    />
                  </button>
                  </div>
                </div>
              ))}
            </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

            </div>

            <div className="w-full my-4 mt-8 ">
              <div className="flex justify-end gap-4">
                 <CancelButton handleClick={() => { Oncancel() }} />
                <SubmitButton isLoading={loading} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditGRN