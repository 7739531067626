import React, { useEffect, useMemo, useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { Link } from "react-router-dom";
import Dashboard from "../../../../Components/Icons/Dasboard";
import { useFormik } from "formik";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import * as yup from "yup";
import {
  NuDate,
  NuInput,
  NuMobileNumber,
  NuSelect,
  NuTextArea,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import axiosInstance from "../../../../Services/axiosCommon";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";

function CreateInvoiceOutgoing() {
  const [loading, setLoading] = useState(false);

  const currentDate = new Date().toISOString().split('T')[0];
  const {value:Tax ,getData:refTax} = useFetchMataData(`/dropdown/dropdown-details/`,'Tax_invoicesIncoming')
  const {value:ModeOfPayment ,getData:refModeOfPayment} = useFetchMataData(`/dropdown/dropdown-details/`,'ModeOfPayment_invoicesIncoming')
  const {value:Division ,getData:refDivision} = useFetchMataData(`/dropdown/dropdown-details/`,'Division_invoicesIncoming')
   const { data: PR } = UseDropDown('/PR/drop-down');

  const { data: CO } = UseDropDown('/customer-orders/drop-down');
  const { data: SO } = UseDropDown('/sales-order/drop-down');

  const [image, setImage] = useState({ preview: "", raw: "" });
  // const [division, setDivision] = useState([]);
  const [customerName, setCusName] = useState(null);
  const [customerEmail, setCusEmail] = useState(null);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  const { data: customer } = UseDropDown("/customers/drop-down");
  const [soTotal,setSoTotal] = useState([]);
  const [createNew, setCreateNew] = useState({
    date: currentDate || "",
    amount: "",
    tax: "",
    totalAmount: "",
    modeOfPayment: "",
    paymentDueDate: currentDate || "",
    paymentReceivedOn: currentDate || "",
    comments: "",
    customerId: "",
    // "status": "Pendding",
    division: "",
    ref: "",
    coId: "",
    taxAmount:""
  });
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      prId: yup.string().required('Purchase Request is required!'),

      coId: yup.string().required('Sales Order is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      setLoading(true); 
      addFormData("/invoices-outgoing/new-invoice", value, resetForm);
      setLoading(false)
    },
  });

  useMemo(() => {
    if (formik.values.tax != 0 || formik.values.tax != "") {
      let finalAmount = (formik.values.amount / 100) * formik.values.tax;
      formik.values.totalAmount = formik.values.amount + finalAmount;
    } else {
      formik.values.totalAmount = formik.values.amount;
    }
  }, [formik.values.amount, formik.values.tax]);

  const [Colist, setColist] = useState([]);
  // console.log("bnmbnm",Colist);


  const getPo = (coId) => {
    if (coId !== "") {
      axiosInstance
        .get(`/sales-order/${coId}`)
        .then(res => {
          console.log("nbnb",res?.data?.data?.products);
          let data = res?.data?.data?.products;
          let name = res.data?.data?.customerName;
          let email = res.data?.data?.customerEmail;
          let total = res?.data?.data?.subTotal.toFixed(2)
          setColist(data);
          name && setCusName(name);
          email && setCusEmail(email);
          total && formik.setFieldValue('amount',total)
          formik.setFieldValue('customerId',res.data?.data?.customerId)
          formik.setFieldValue('totalAmount',total)
        })
        .catch(err => {
          console.log(err, "err");
        });
    }
  };

  useEffect(() => {
    if (formik.values.coId) {
      getPo(formik.values.coId);
    }
  }, [formik.values.coId]);

  useEffect(() => {
    const amount = parseFloat(formik.values.amount || 0);
    const tax = parseFloat(formik.values.tax || 0);
    
    const taxAmount = ((amount)* tax/100);  
    
    formik.setFieldValue("taxAmount", taxAmount.toFixed(2));
    formik.setFieldValue("totalAmount", (amount + taxAmount).toFixed(2));
  }, [formik.values.amount, formik.values.tax]);
  

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            {
              name: "Invoice-Outgoing",
              link: "/sales/invoice_outgoing",
            },
            {
              name: "create",
            },
          ]}
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-autoh-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
          <FormikSelectTwo
                type="text"
                label="Purchase Request"
                placeholder="Purchase Request "
                Options={PR}
                formik={formik}
                name="prId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
                // disabled={true}
              />
          <FormikSelectTwo
              type="text"
              label="Sales Order"
              placeholder="Sales Order"
              Options={SO}
              formik={formik}
              name="coId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />

            {/* <FormikSelectTwo
              type="text"
              label="Customer"
              placeholder="Customer"
              Options={customer}
              formik={formik}
              name="customerId"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            {/* <NuInput
              type="number"
              label="Invoice Reference"
              placeholder="Invoice Reference"
              formik={formik}
              name="ref"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuDate
              label="Invoice Date"
              placeholder="Invoice Date"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
 {/* <NuDate
              label="Received Date"
              placeholder=" Received Date"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuInput
              // value={soTotal}
              type="number"
              label="Amount"
              placeholder=" Amount"
              formik={formik}
              name="amount"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Tax (%)"
                placeholder="Choose"
                Options={Tax}
                formik={formik}
                name="tax"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refTax}
                displayProperty={"Tax (%)"}
                propertyName={"Tax_invoicesIncoming"}
              />
        <NuInput
  type="text"
  label="Tax Amount"
  placeholder="Tax Amount"
  formik={formik}
  name="taxAmount"
  width="w-full md:w-1/2 lg:w-1/3"
  disabled={true}
/>
            <NuInput
              type="number"
              label="Total Amount"
              placeholder="Total Amount"
              formik={formik}
              name="totalAmount"
              width="w-full md:w-1/2 lg:w-1/3"
              disabled={true}
            />

           
            <NuDate
              label="Payment DueDate"
              placeholder="Payment DueDate"
              formik={formik}
              name="paymentDueDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Payment ReceivedOn"
              placeholder="Payment ReceivedOn"
              formik={formik}
              name="paymentReceivedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Mode Of Payment"
                placeholder="Choose"
                Options={ModeOfPayment}
                formik={formik}
                name="modeOfPayment"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refModeOfPayment}
                displayProperty={"Mode Of Payment"}
                propertyName={"ModeOfPayment_invoicesIncoming"}
              />
            
 {/* <DropDownAddMetaData
                label="Division"
                placeholder="Choose"
                Options={Division}
                formik={formik}
                name="division"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refDivision}
                displayProperty={"Division"}
                propertyName={"Division_invoices"}
              />
             */}
           
            <NuTextArea
              label="comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              new_width="w-full md:w-2/3"

              width="w-full md:w-1/2 lg:w-1/3"
            />
            <div className="w-full flex px-6">
            {formik.values.coId && (
              <>
                <div className="w-full md:w-1/2 lg:w-1/3 flex px-6">
                  <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                    Customer Name
                  </p>
                  <p className="w-1/2 py-3 pl-5  text-xs text-[#606279]">
                    {customerName}
                  </p>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/3 flex px-6">
                  <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                    Customer Email
                  </p>
                  <p className="w-1/2 py-3 pl-5  text-xs text-[#606279]">
                    {customerEmail}
                  </p>
                </div>
              </>
            )}
            </div>
             <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
            <div className="w-full bg-white overflow-y-scroll mt-5">
              <div className="w-full py-3 bg-[#048178] flex justify-between rounded-lg px-2">
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

                <p className="w-[50%] text-left text-white text-xs font-medium">Product</p>
                <p className="w-[10%] text-center text-white text-xs font-medium">Unit</p>
                <p className="w-[15%] text-center text-white text-xs font-medium"> Quantity</p>
                <p className="w-[15%] text-center text-white text-xs font-medium">Price</p>
                <p className="w-[15%] text-center text-white text-xs font-medium">Total</p>
              
              </div>
              {Colist?.length>0?
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">

              {Colist.map((List, index) => (
                <div key={index} className="w-full py-2 flex justify-between px-2 odd:bg-white even:bg-slate-50">
                  <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                  <p className="w-[50%] text-left text-[#606279] text-xs font-light">{List?.product_name}</p>
                  <p className="w-[10%] text-center text-[#606279] text-xs font-light">{List?.unit}</p>
                  <p className="w-[15%] text-center text-[#606279] text-xs font-light">{List?.quantity}</p>
                  <p className="w-[15%] text-center text-[#606279] text-xs font-light">{List?.price}</p>
                  <p className=" w-[15%] text-center text-[#606279] text-xs font-light">
                  {(List.price * List.quantity ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
                  
                  </p>
                </div>
              ))}
            </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

            </div>

            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={loading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateInvoiceOutgoing;
