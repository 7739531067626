import React, { useEffect, useState } from "react";
import DataTable from "../../../../Components/Others/DataTable";
import {
  CancelButton,
  CreateButton,
  FilterButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { Link } from "react-router-dom";
import SearchBar from "../../../../Components/Utils/SearchBar";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import { useFormik } from "formik";
import * as yup from 'yup';
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import { GetAccess } from "../../../../Components/Utils/roles";
import useFetchMDIndex from "../../../../Components/Hooks/useFetchMDIndex";

function EquipmentIndex() {
  
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(20);
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const {value:EquipmentType} = useFetchMDIndex(`/dropdown/dropdown-details/`,'EquipmentType',showFilterDialog)
  const {value:EquipmentBrand} = useFetchMDIndex(`/dropdown/dropdown-details/`,'EquipmentBrand',showFilterDialog)
  const {value:EquipmentManufacturer} = useFetchMDIndex(`/dropdown/dropdown-details/`,'EquipmentManufacturer',showFilterDialog)
  const {value:EquipmentUnit} = useFetchMDIndex(`/dropdown/dropdown-details/`,'EquipmentUnit',showFilterDialog)

  const [filterProps, setFilterProps] = useState({
    calibReq:"",
    brand:"",
    unit:"",
    equipmentType:"",
    manufacturer:"",
  });

  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:"",
    calibReq:"",
    brand:"",
    unit:"",
    equipmentType:"",
    manufacturer:"",
  });
  const { data, totalCount, getData } = UseGetDataSearch("/equipments/",params);
  console.log(data, "dddd");

  const clearFilter = ()=> {
    setParams({...params, calibReq:"",
    brand:"",
    unit:"",
    equipmentType:"",
    manufacturer:"",})
    formik.values.calibReq=""
    formik.values.brand=""
    formik.values.unit=""
    formik.values.equipmentType=""
    formik.values.manufacturer=""

      setFilter(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,calibReq:value.calibReq, brand:value.brand, unit:value.unit, equipmentType:value.equipmentType,manufacturer:value.manufacturer})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        calibReq:"",
        brand:"",
        unit:"",
        equipmentType:"",
        manufacturer:"",
      });
    }
  })

  const defaultHeader = [
    {
      title: "ID",
      width: "5%",
      isLink: true,
      field: "equipmentId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Type",
      width: "9%",
      isLink: true,
      id: "equipmentId",
      link: '/maintenance/equipment/view/',
      field: "equipmentType",
      textAlign: "center",
      rowAlign: "center",
    },
   
   
    {
      title: "Brand",
      width: "9%",
      field: "brand",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Status",
    //   width: "9%",
    //   field: "status",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
   
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "equipmentId",
        removeValue: "Equipments",
        url: "/equipments/",
        moduleName: "Equipments",
        from: "/maintenance/equipment",
        editLink: "/maintenance/equipment/edit/",
        viewLink: "/maintenance/equipment/view/",
      },
    },
  ];

  let availableHeaders = [
    {
      title: "ID",
      width: "5%",
      isLink: true,
      field: "equipmentId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Type",
      width: "9%",
      isLink: true,
      id: "equipmentId",
      link: '/maintenance/equipment/view/',
      field: "equipmentType",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Manufacturer",
      width: "9%",
      field: "manufacturer",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Brand",
      width: "9%",
      field: "brand",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Unit",
      width: "9%",
      field: "unit",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Quantity",
      width: "9%",
      field: "quantity",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Warrenty Period",
      width: "9%",
      field: "warrentyPeriod",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Warrenty Expiration Date",
      width: "9%",
      field: "warrentyExpDate",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Calibration Request",
      width: "9%",
      field: "calibReq",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Calibration Expiration Date",
      width: "9%",
      field: "calibExpDate",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Description",
      width: "9%",
      field: "description",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Status",
    //   width: "9%",
    //   field: "status",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Comments",
    //   width: "9%",
    //   field: "comments",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "equipmentId",
        removeValue: "Equipments",
        url: "/equipments/",
        moduleName: "Equipments",
        from: "/maintenance/equipment",
        editLink: "/maintenance/equipment/edit/",
        viewLink: "/maintenance/equipment/view/",
      },
    },
  ];

  useEffect(() => {
    getData();
  }, [page, pageCount]);

  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Maintenance",
            },
            {
              name: "Equipment",
            },
          ]}
        />
        <div className=" flex gap-2">
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />
          <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
          {/* <CreateButton label="Import" /> */}
          {GetAccess("equipment_create") && <Link to="/maintenance/equipment/create/">
            <CreateButton label="Equipment" />
          </Link>}
        </div>
      </div>

      <CustomDataTable
      fetcher={getData}
        data={data}
        availableHeaders={availableHeaders}
        defaultHeader={defaultHeader}
        bodyHeight="max-h-[calc(100vh-260px)]"
        height="h-[calc(100vh-145px)]"
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title="Equipment List"
        storageName="EquipmentTable"
        getById="equipmentId"
        editAccess="equipment_edit"      
        deteleAccess="equipment_delete" 
      />
        {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Equipment</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <FormikSelectTwo
              label="Calibration Req"
              placeholder="Calibration Req"
              formik={formik}
              Options={[
                {
                  value: true,
                  label: "yes",
                },
                {
                  value: false,
                  label: "no",
                },
              ]}
              name="calibReq"
              width="w-full "
            />
             <FormikSelectTwo
                type="text"
                label="Unit"
                placeholder="Choose"
                Options={EquipmentUnit}
                formik={formik}
                name="unit"
                width="w-full "
              />
               <FormikSelectTwo
                type="text"
                label="Manufacturer"
                placeholder="Choose"
                Options={EquipmentManufacturer}
                formik={formik}
                name="manufacturer"
                width="w-full "
              />
               <FormikSelectTwo
                type="text"
                label="Brand"
                placeholder="Choose"
                Options={EquipmentBrand}
                formik={formik}
                name="brand"
                width="w-full "
              />
              <FormikSelectTwo
              label="Equipment Type"
              placeholder="Choose"
              formik={formik}
              Options={EquipmentType}
              name="equipmentType"
              width="w-full "
            />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
    </div>
  );
}
export default EquipmentIndex;
