import React, { useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { Link } from "react-router-dom";
import Dashboard from "../../../../Components/Icons/Dasboard";
import { useFormik } from "formik";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import * as yup from "yup";
import {
  NuInput,
  NuMobileNumber,
  NuTextArea,
  NuUpload,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import useFetchMetaStar from "../../../../Components/Hooks/useFetchMetaStar";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";
import { CountryMIN } from "../../../../assets/CountryMini";

function CreateCustomers() {
  const { value: Industry, getData: refIndustry } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Industry_Customer"
  );
  const { value: Desigination, getData: refDesigination } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Desigination_Customer"
  );
  const { value: customerRating } = useFetchMetaStar(
    `/dropdown/dropdown-details/`,
    "customerRating_Customer"
  );
  const { value: customerType, getData: refcustomerType } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "customerType_Customer"
  );
  const { value: Category, getData: refCategory } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "category_Customer"
  );
  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState({ preview: "", raw: "" });
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  const [createNew, setCreateNew] = useState({
    customerType: "",
    companyName: "",
    email: "",
    phone: "",
    countryCode: "",
    website: "",
    industry: "",
    billingAddress: "",
    shippingAddress: "",
    crNum: "",
    vatNum: "",
    ratings: "",
    paymentTerm: "",
    creditLimit: "",
    comments: "",
    country: "",
    city: "",
    state: "",
    district: "",
    postalCode: "",
    cpName: "",
    cpEmail: "",
    designation: "",
    cpCountryCode: "",
    cpPhone: "",
    category: "",
    billingAddress1: "",
    billingAddress2: "",
    billing_city: "",
    billing_country: "",
    billing_postal_code: "",
    billing_state: "",
    shippingAddress1: "",
    shippingAddress2: "",
    shipping_country: "",
    shipping_city: "",
    shipping_state: "",
    shipping_postal_code: "",
  });
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
  };



  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      phone: yup
        .string()
        .matches(/^\d+$/, "Phone number must contain only digits")
        .min(9, "Phone number must be at least 9 digits")
        .max(10, "Phone number must be at most 10 digits")
        .required("Phone number is required"),
      email: yup.string().required("Email is required!"),
      companyName: yup.string().required("Company Name is required!"),
    }),
    onSubmit: async (value, { resetForm }) => {
      setLoading(true);
      let formData = new FormData();
      formData.append("json_data", JSON.stringify(value));
      formData.append("customerImage", image.raw);

      addFormData("/customers/new-customer", formData, resetForm);
      setLoading(false);
    },
  });


  const [useAddress,setAddress] = useState(false);
  function handleAddress(e){
    setAddress(e.target.checked);
    if(e.target.checked){
      formik.values.shippingAddress1 = formik.values.billingAddress1
      formik.values.shippingAddress2 = formik.values.billingAddress2
      formik.values.shipping_country = formik.values.billing_country
      formik.values.shipping_city = formik.values.billing_city
      formik.values.shipping_state = formik.values.billing_state
      formik.values.shipping_postal_code = formik.values.billing_postal_code
    }else{
      formik.values.shippingAddress1 = ""
      formik.values.shippingAddress2 = ""
      formik.values.shipping_country = ""
      formik.values.shipping_city = ""
      formik.values.shipping_state =  ""
      formik.values.shipping_postal_code = ""
    }
  }

  

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between mb-4">
        <Breadcrumb
          items={[
            // { name: "Sales" },

            {
              name: "Customers",
              link: "/sales/customers",
            },

            { name: "Create" },
          ]}
        />
        <BackButton />
      </div>
      <div className="w-full h-[calc(100vh-140px)] overflow-y-auto">
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mx-auto bg-white rounded-md pb-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
              <div className="w-full">
                <p className="py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Customer Details :
                </p>
              </div>

              <NuInput
                type="text"
                label="Company Name"
                placeholder="Company Name"
                formik={formik}
                name="companyName"
                isRequired={true}
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <DropDownAddMetaData
                label="Industry"
                placeholder="Choose"
                Options={Industry}
                formik={formik}
                name="industry"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refIndustry}
                displayProperty={"Industry"}
                propertyName={"Industry_Customer"}
              />

              <DropDownAddMetaData
                label="Customer Type"
                placeholder="Choose"
                Options={customerType}
                formik={formik}
                name="customerType"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refcustomerType}
                displayProperty={"Customer Type"}
                propertyName={"customerType_Customer"}
              />

              <NuMobileNumber
                nunCode="countryCode"
                label="Phone"
                placeholder="Phone"
                formik={formik}
                name="phone"
                isRequired={true}
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuInput
                type="email"
                label="Email"
                placeholder="Email"
                formik={formik}
                name="email"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
              <NuInput
                type="text"
                label="website"
                placeholder="website"
                formik={formik}
                name="website"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="number"
                label="C.R Num"
                placeholder="C.R Num"
                formik={formik}
                name="crNum"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuInput
                type="number"
                label="VAT Num"
                placeholder="VAT Num"
                formik={formik}
                name="vatNum"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="Payment Term"
                placeholder="payment Term"
                formik={formik}
                name="paymentTerm"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuInput
                type="number"
                label="Credit Limit"
                placeholder="Credit Limit"
                formik={formik}
                name="creditLimit"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <DropDownAddMetaData
                label="Category"
                placeholder="Choose"
                Options={Category}
                formik={formik}
                name="category"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refCategory}
                displayProperty={"Category"}
                propertyName={"category_Customer"}
              />

              <FormikSelectTwo
                label="Ratings"
                placeholder="Ratings"
                Options={customerRating}
                formik={formik}
                name="ratings"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuUpload
                label="Attachment"
                width="w-full md:w-1/2 lg:w-1/3"
                onChange={handleChangeImg}
              />

              <div className="w-full">
                <p className="py-2 px-4 text-xs 2xl:text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Contact Person Details :
                </p>
              </div>

              <NuInput
                type="text"
                label="Name"
                placeholder="Name"
                formik={formik}
                name="cpName"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <DropDownAddMetaData
                label="Desigation"
                placeholder="Choose"
                Options={Desigination}
                formik={formik}
                name="designation"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refDesigination}
                displayProperty={"Desigation"}
                propertyName={"Desigination_Customer"}
              />

              <NuMobileNumber
                nunCode="cpCountryCode"
                label="Phone"
                placeholder="Phone"
                formik={formik}
                name="cpPhone"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuInput
                type="email"
                label="Email"
                placeholder="Email"
                formik={formik}
                name="cpEmail"
                width="w-full md:w-1/2 lg:w-1/3"
              />


            </div>
          </div>
          <div className="w-full mx-auto  bg-white rounded-md  mt-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
            <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                 Billing Address :
                </p>
              </div>

              <NuInput
                type="text"
                label="Street Address Line 1"
                placeholder="Street Address Line 1"
                formik={formik}
                name="billingAddress1"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="Street Address Line 2"
                placeholder="Street Address Line 2"
                formik={formik}
                name="billingAddress2"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="City"
                placeholder="City"
                formik={formik}
                name="billing_city"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="State / Province"
                placeholder="State / Province"
                formik={formik}
                name="billing_state"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="number"
                label="Postal / Zip Code"
                placeholder="Postal / Zip Code"
                formik={formik}
                name="billing_postal_code"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Country"
                placeholder="Country"
                Options={CountryMIN}
                formik={formik}
                name="billing_country"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              
            </div>
            <div className=" px-4 pb-2 flex gap-1">
                  <input type="checkbox" checked={useAddress} onChange={handleAddress} />
                  <label className=" text-xs">Use Billing Address as Shipping Address</label>
                </div>
          </div>

          <div className="w-full mx-auto  bg-white rounded-md  mt-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
            <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Shipping Address :
                </p>
              </div>

              <NuInput
                type="text"
                label="Street Address Line 1"
                placeholder="Street Address Line 1"
                formik={formik}
                name="shippingAddress1"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="Street Address Line 2"
                placeholder="Street Address Line 2"
                formik={formik}
                name="shippingAddress2"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="City"
                placeholder="City"
                formik={formik}
                name="shipping_city"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="State / Province"
                placeholder="State / Province"
                formik={formik}
                name="shipping_state"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="number"
                label="Postal / Zip Code"
                placeholder="Postal / Zip Code"
                formik={formik}
                name="shipping_postal_code"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Country"
                placeholder="Country"
                Options={CountryMIN}
                formik={formik}
                name="shipping_country"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuTextArea
                label="Comments"
                placeholder="comments"
                formik={formik}
                name="comments"
                width="w-full md:w-1/2 "
                new_width="lg:w-2/3"
              />

              <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
                <div className="md:flex justify-end gap-4">
                  <CancelButton
                    handleClick={() => {
                      Oncancel(-1);
                    }}
                  />
                  <SubmitButton isLoading={loading} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateCustomers;
