import React, { useEffect, useRef, useState } from 'react'
import axiosInstance from '../../Services/axiosCommon';
import socketIOClient from "socket.io-client";
import moment from 'moment';
import NuDate from '../../Components/Utils/NuDate';
import logout from '../../Components/Utils/Logout';
import ModalContainer from '../../Components/Others/ModalContainer';
import BellIcon from '../../Components/Icons/BellIcon';
import DecryptData from '../../Components/Utils/Decrypt';
import LanguageSelector from '../../Components/Others/LanguageSelector';
import { Link } from 'react-router-dom';
import Dashboard from '../../Components/Icons/Dasboard';
import { useCurrency } from '../../Context/CurrencyContext';
import socketAxiosInstance from '../../Services/socketAxiosCommon';
import { GetDashboardRole } from '../../Components/Utils/roles';

const socket = socketIOClient(window.SOCKET_SERVER_URL, {
  transports: ['websocket','polling']  // Force WebSocket as the primary transport
});

function Navbar() {
  const userDetails = DecryptData("sLiAtEdReSuEdArT")||"";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notifCount, setNotifCount] = useState(0);
  const {updateCurrency} =useCurrency();
  
  const [open, setOpen] = useState(false);
  const [noti, setNoti] = useState([]);
  const [title, setTitle] = useState({
    Name: "",
    Country: ""
  })

  const connectSocket = () => {
    socket.connect();

    const id = userDetails?.id; // Adjust this based on your actual structure
    const userEmail = userDetails?.email;
    // console.log('details',id,userEmail);

    const joinData = {
      userId: id,
      username: userEmail,
    };
    console.log("details", joinData);
    socket.emit("join_notif_room", joinData);
  };
  const disConnectSocket = () => {
    const id = userDetails?.id; // Adjust this based on your actual structure
    const userEmail = userDetails?.email;

    const disconnectData = {
      id: id,
      username: userEmail,
    };
    socket.emit("leave_notif_room", disconnectData);
    socket.disconnect();
  };

  function getCompanyName() {
    axiosInstance.get(`/company-info/1`).then(res => {
      console.log(res.data.data?.compLogo);
      res.data?.data && setTitle({
        Name: res.data?.data?.companyName,
        Country: res.data.data?.compLogo?.file|| "",
      })
      updateCurrency(res.data.data?.currency)
    }).catch(err => {
      console.log(err);
    })
  }

  function getNotifications(userId){
    socketAxiosInstance.get(`/notification/${userId}`)
    .then(res => {
      if (res?.data?.status===true){
        let data = res?.data?.data
        
        data && setNoti(data?.notifications || []);
        data && setNotifCount(data?.count || 0);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  useEffect(() => {
    getCompanyName();
    getNotifications(userDetails?.id);
    connectSocket();
    return () => {
      disConnectSocket();
    };
  }, []);

  useEffect(() => {
    // console.log("test");
    socket.on("receive_notification", (data) => {
      console.log("new ..... notification", data);
      setNotifCount((prev)=>prev+1);
      setNoti((prev)=>[data,...prev])
      // Update the state based on the received data
    });
    socket.on("error", (error) => {
      console.error("Socket error:", error);
    });
  }, [socket]);

  const onClose = () => {
    setOpen(false);
  };


  const Space = ({ children }) => {
    return <div className="flex">{children}</div>;
  };
  
  const Badge = ({ count, children }) => {
    return (
      <div className="relative inline-block">
        {children}
        {count > 0 && (
          <span className="absolute top-0 p-1 right-0 bg-red-500 text-white w-4 h-4 flex items-center justify-center rounded-full text-[8px]">
            {count}
          </span>
        )}
      </div>
    );
  };
  
  const Dropdown = ({ overlay, children, trigger }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };
  
      if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isOpen]);
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  
    const handleTrigger = () => {
      if (trigger === 'click') {
        toggleDropdown();
      }
      // You can add support for other trigger events here if needed
    };
  
    return (
      <div className="relative inline-block" onClick={handleTrigger} ref={dropdownRef}>
        <div>{children}</div>
        {isOpen && (
          <div className="absolute right-[100%] top-[70%] w-48 bg-white rounded-md shadow-lg z-10">
            {overlay}
          </div>
        )}
      </div>
    );
  };

  const getUnreadCount = () => {
    return noti.filter((noti) => !noti.is_read).length;
  };

  function Menu() {
    const handleRead = async (id) => {
      axiosInstance
        .put(`/notification/update/is_read/${id}`, {
          is_read: 1,
        })
        .then((res) => {
          setNoti((prev) => 
            prev.map((noti) =>
              noti?.notificationId === id
                ? { ...noti, is_read: true }
                : noti
            )
          );
          setNotifCount(getUnreadCount());
        });
    };

    function ClearAll() {
      axiosInstance.delete(`/notification/delete`).then((res) => {
        setNoti([]);
        setNotifCount(0);
      }).catch(err => { })
    }

    function MarkAll() {
      axiosInstance.get(`/notification/update/mark_all_as_read`).then((res) => {
        setNoti((prev) => 
          prev.map((noti) => {
            return ({ ...noti, is_read: true })
          }
          )
        );
        setNotifCount(0);
      }).catch(err => { })
    }


    return (
      <>
        <div className="w-72 h-96  bg-white shadow-lg rounded-md overflow-hidden">
          <div className=" w-full h-10 bg-slate-200 flex justify-between items-center">
            <p className="font-bold ml-2">Notification</p>
            <div className=" flex items-center gap-2">
              {notifCount > 0 ? <button className=" outline-none border-none text-xs font-normal px-2 py-1 rounded bg-mainColor-300 text-white cursor-pointer bg-[#048178]" onClick={MarkAll}>Marks as read</button> : null}
              {noti.length > 0 ? <button className=" outline-none border-none text-xs font-normal px-2 py-1 rounded bg-mainColor-300 text-white cursor-pointer bg-[#048178]" onClick={ClearAll}>Clear All</button> : null}
            </div>
          </div>
          <div className="w-full h-[90%] overflow-y-scroll">
            {noti?.map((notification,idx) => {
              return (
                <>
                  <div
                    className={`${notification?.is_read ? "bg-white" : " bg-slate-100"} w-full my-1 p-1 rounded gap-1 flex justify-between items-center cursor-pointer`}
                    onClick={() => handleRead(notification?.notificationId)}
                  >
                    <div className="px-2 w-[15%]">
                      {/* <NuAvatarGroup userdetails={[{name:`${notification?.sender_fName}`,imageUrl:`${notification?.sender_imageUrl}`}]} />    */}
                      <div className="w-8 h-8 bg-baseColor-primary  flex justify-center items-center text-white font-medium text-lg rounded-full overflow-hidden">
                        {
                          notification.sender_imageUrl ?
                            <img
                              src={
                                notification.sender_imageUrl
                                  ? notification.sender_imageUrl
                                  : null
                              }
                              width="50px"
                              alt={`${notification.sender_imageUrl}`}
                            /> :
                            `${notification?.name?.charAt(0)}`
                        }

                      </div>
                    </div>
                    <div className={`w-[85%] ${notification?.is_read ? " font-normal " : "font-medium  "}`}>
                      {/* <p className='font-semibold'>Admin <span className='font-normal'>to</span> Login Person</p> */}
                      <p className="pb-1 text-ellipsis text-slate-900 leading-5 text-xs">
                        {notification?.msg}
                      </p>
                      <p className="text-[10px] text-slate-700">
                        {moment(notification?.date).format("DD-MMM-YYYY")} at{" "}
                        <span className="text-[8px] text-slate-700">
                          <NuDate
                            value={`${notification?.date} ${notification?.time}`}
                            format="hh:mm a"
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </>
    );
  }

  const handleLogOut = async () => {
    setIsModalOpen(true);
  };
console.log(userDetails,"kjhgvc");


  return (
    <nav className=' w-full flex justify-between'>
            <div className=" w-4 lg:w-6/12 h-full p-3">
        <div className=" hidden h-full items-center lg:flex">{
          <div className=" flex justify-center items-center gap-2">
            {title.Country &&<div className="max-w-[150px] h-10 overflow-hidden flex justify-center items-center">
  <img src={title.Country} style={{ height: "40px", width: "auto" }} alt="#fff" />
</div>}
            <span className=" text-base capitalize font-medium">{title.Name || " "}</span>
           
          </div>

        }</div>
        {/* <div className=" lg:hidden cursor-pointer" onClick={showDrawer}>
        <MenuOutlined />
      </div> */}
      </div>
      <div className=" flex justify-center items-center">
      <div className='px-2'>
        <Link to={`${ GetDashboardRole("")?"dashboard":GetDashboardRole("sales")?"/dashboard/sales":GetDashboardRole("procurement")?"/dashboard/procurement":GetDashboardRole("store")?"/dashboard/stores":"#"}`}>
        <Dashboard/>
        </Link>
      </div>
        {/* <div className=' mr-6'>
          <LanguageSelector/>
        </div> */}
        <div className="mr-6">
          <Space>
            <Badge count={notifCount}>
              <Dropdown overlay={<Menu />} trigger="click">
                <div className=' px-1  flex justify-center items-center  cursor-pointer bg-slate-100  h-7 rounded-lg'>
                  <BellIcon/>
                </div>
              </Dropdown>
            </Badge>
          </Space>
        </div>

        <div class=" md:p-2 flex items-center">
          <div className="dropdown inline-block relative">
            <button className=" text-gray-700 outline-none border-none bg-transparent font-semibold md:px-4 flex items-center">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
              </svg>
              <div className=" relative w-9 h-9 mx-2 rounded-full bg-white overflow-hidden">
                <div className="absolute z-10 bg-transparent top-0 left-0 w-full h-9 border-4 rounded-full border-solid border-[#016c62]"></div>
                <div className="absolute z-20 bottom-0 right-0 bg-transparent w-1/2 h-9 border-8 rounded-r-full border-solid border-white -[#F4F2F8]"></div>
                <div className="absolute z-30 w-full [calc(36px-4px)] h-full [calc(36px-4px)] flex justify-center text-slate-900 capitalize items-center bg-transparent">
                {userDetails.image ? (
                    userDetails.image
                  ) : (
                    userDetails?.name?.charAt(0)
                  )}       </div>
              </div>
              <div className=" hidden md:flex flex-col leading-none">
                <p className="text-sm font-semibold">
                  {userDetails?.name || " "}
                  {/* {userDetails?.userLastName || " "} */}
                </p>
              </div>
            </button>
            <ul className="dropdown-menu pt-2 leading-none z-50 absolute hidden shadow-sm">
            <li className="list-none">
                <Link
                  to={`/profile`}
                  className="rounded-t bg-baseColor-teritary font-medium hover:bg-baseColor-quartary py-2  px-4 block whitespace-no-wrap"
                  href="#"
                >
                  Profile
                </Link>
              </li>

              <li className="list-none cursor-pointer">
                <div
                  className="rounded bg-baseColor-teritary font-medium hover:bg-baseColor-quartary py-2  px-4 block whitespace-no-wrap"
                  href="#"
                  onClick={handleLogOut}
                >
                  Log Out
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>
      <ModalContainer
       visiable={isModalOpen}
       title="Want to Logout?"
       content="Are you sure to logout from this app!"
       handleClick={()=>logout()}
       closeModal={()=>setIsModalOpen(false)}
      />
    </nav>
  )
}

export default Navbar