import React, { useEffect, useMemo, useState } from 'react'
import DeleteIcon from '../../../../Components/Icons/Delete';
import { NuDate, NuInput, NuTextArea, NuUpload } from '../../../../Components/Utils/Input';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import * as yup from 'yup';
import axiosInstance from '../../../../Services/axiosCommon';
import { useNavigate } from 'react-router-dom';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';
import { TextMediumBase } from '../../../../Components/Text/MediumText';
import MultiSelect from '../../../../Components/Utils/MultiSelect';
import NuAmount from '../../../../Components/Utils/NuAmount';
import EditIcon from '../../../../Components/Icons/Edit';
import { useCurrency } from '../../../../Context/CurrencyContext';

function CreateSalesOrders() {
  const currentDate = new Date().toISOString().split('T')[0];
  const { currencySymbol } = useCurrency();
  const {value:ApprovalStatus ,getData:refApprovalStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'ApprovalStatus_SalesOrder')
  const {value:CustomerDiscount ,getData:refCustomerDiscount} = useFetchMataData(`/dropdown/dropdown-details/`,'CustomerDiscount_SalesOrder')
  const {value:PaymentStatus ,getData:refPaymentStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'PaymentStatus_SalesOrder')
  const {value:TermsAndConditions ,getData:refTermsAndConditions} = useFetchMataData(`/dropdown/dropdown-details/`,'termsAndConditions_SalesOrder')
  const {value:ShippingMethod ,getData:refShippingMethod} = useFetchMataData(`/dropdown/dropdown-details/`,'shippingMethod_SalesOrder')
  const { data: RFQAssignee } = UseDropDown('/user/procurement/drop-down');
  const { data: Salesman } = UseDropDown('/user/salesman/drop-down');

  const { data: user } = UseDropDown('/user/drop-down');
  const { data: PR } = UseDropDown('/PR/drop-down');
  const { data: Customers } = UseDropDown('/customers/drop-down');
  const [salesPerson,setSalesPerson] = useState([]);

  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState({ preview: "", raw: "" });
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  const [Colist, setColist] = useState([]);
  const [items, setItems] = useState([]);
  console.log(items,"ii");
  const  [isEditQua,setIsEditQua] = useState(false);
  const [newItemData, setNewItemData] = useState({
            quoteProductId:"",
            productId:"",
            orderedQty:"",
            quoteQty:"",
            price:"",
            product_name:"",
   });

  const [createNew, setCreateNew] = useState({
    type: "",
    cusOrderRef: "",
    busySoNo: "",
    cusQuoteId: "",
    orderReceivedOn: currentDate || "",
    assignee: "",
    salesPerson: "",
    deadLine: currentDate || "",
    notesFromCus: "",
    date: currentDate || "",
    prId: "",
    ref: "",
    email: "",
    customerId: "",
    comments: "",
    cusDiscount: "",
    shippingInstruction: "",
    shippingMethod: "",
    billingAddress: "",
    deliveryAddress: "",
    termsAndConditions: "",
    approvalStatus: "",
    approvedBy: "",
    subTotal: 0.0,
    paymentStatus: "",
    requestedDeliveryDate: currentDate || "",
    requestedBy: "",
    products: "",
    quoteProductId: "",
    productId: "",
    quoteQty: "",
    product_name: "",
    receivedOn: currentDate || "",
  });
   const [error,setError] = useState(''); 
  const handleAdd = () => {
    const newItem = {
       price: formik.values.price,
       quoteQty: formik.values.quoteQty,
       productId: formik.values.productId,
       quoteProductId: formik.values.quoteProductId,
      orderedQty: formik.values.orderedQty,
      product_name: formik.values.product_name

    };
    setItems([...items, newItem]);
    formik.values.subTotal = parseFloat(formik.values.subTotal) + (parseFloat(newItem.price) * parseFloat(newItem.orderedQty));

    setNewItemData({
    price: "",
    quoteProductId: "",
    orderedQty: "",
    quoteQty:"",
    productId:"",
    product_name:"",

    });
    formik.values.orderedQty ='';
    formik.values.quoteProductId = '';
    formik.values.price =''

  };

  const [deletedItem,setDeletedItem] = useState([]);
  const [deletedItemDropDown,setDeletedItemDropDown] = useState([]);
  const handleDeleteItem = (indexToDelete) => {
    const updatedItems = Colist.filter((_, index) => index !== indexToDelete);
    setColist(updatedItems);
    const deletedItems = Colist.filter((_, index) => index == indexToDelete);
    setDeletedItem((prevDeletedItems) => [...prevDeletedItems, ...deletedItems]);
    const removedItemPrice = parseFloat(Colist[indexToDelete].price || 0);
    const removedItemQuantity = parseFloat(Colist[indexToDelete].quantity || 0);
    const newSubTotal = formik.values.subTotal - (removedItemPrice * removedItemQuantity);
    formik.setFieldValue('subTotal', newSubTotal);
  };
  console.log("findgufu",deletedItem);
  useEffect(() => {
    let tempData = [];
    deletedItem.forEach((item) => {
      let tempObject = { value: item.productId, label: item.productName };
      tempData.push(tempObject);
    });
    setDeletedItemDropDown(tempData);
  }, [deletedItem]);
    console.log("dele",deletedItemDropDown);

    const handleAddAfterDel = () => {
      const selectedProductId = formik.values.productId;
    
      // Find the selected product's details from the deletedItemDropDown (or deletedItem array)
      const selectedProduct = deletedItem.find(
        (item) => item.productId === selectedProductId
      );
    
      if (selectedProduct) {
        // Add the selected product to prList
        setColist((prevPrList) => [...prevPrList, selectedProduct]);
    
        // Optionally, remove the selected product from the dropdown after adding it to prList
        setDeletedItem((prevDeletedItems) =>
          prevDeletedItems.filter((item) => item.productId !== selectedProductId)
        );
      }
    };

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
  }

    const formik = useFormik
    ({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          prId: yup.string().required('Purchase Request is requred!'),
          cusOrderRef: yup.string().required('Customer Order is requred!')
        }),
        onSubmit: async (value, { resetForm }) => {
          setLoading(true); 
          if (Colist.length === 0) {
            setError('Product table is empty');
            return;
          }
        let formData = new FormData();
          const valueWithItems = {
            ...value,
            products: Colist,
          };
          formData.append('json_data', JSON.stringify(valueWithItems));
          formData.append('attachments', image.raw);
  
          addFormData('/sales-order/new-sales-order', formData, resetForm);
          setLoading(false)
     }
   });

  
const [products, setProducts] = useState([])

  useMemo(()=>{
    if (formik.values.quoteProductId!==''){
      let tem_product = products.find((i)=> i.id == formik.values.quoteProductId);
      formik.values.price = tem_product && tem_product.price;
      formik.values.productId = tem_product && tem_product.productId;
      formik.values.quoteQty = tem_product && tem_product.quoteQty;
      formik.values.product_name = tem_product && tem_product.name


      console.log(tem_product,"oo");
    }
  },[formik.values.quoteProductId])
  

function getProduct(num){
  axiosInstance.get(`/sales-order/drop-down/by-PR/${num}`).then((response) => {
    let tempData =[]
    if(response.data){
      let x = response.data
      setProducts(x)

    }
  }).catch((error) => {})
 }

// const [product, setProduct] = useState([])

// function getProduct(num){
//   axiosInstance.get(`/sales-order/drop-down/by-PR/${num}`)
//   .then((response) => {
//     let tempData =[]
//     if(response.data){
//       let x = response.data

//       x.map((item) => {
//       let y = {
//         value: item.id,
//         label: item.name,
//       }
//       tempData.push(y)
//       })

//       setProduct(tempData)
//     }
//   }).catch((error) => {})
//  }

 useMemo(()=>{
  if(formik.values.prId){
    getProduct(formik.values.prId)
  }
 },[formik.values.prId])
 


//  const getCQ = (cusOrderRef) => {
//    if (cusOrderRef !== "") {
//      axiosInstance
//        .get(`/customer-orders/co-products/${cusOrderRef}`)
//        .then(res => {
//          let data = res.data?.products;

//          let tPrice=0.00
//          for (let i=0;i<data?.length;i++){
//           tPrice = parseFloat(tPrice) + (parseFloat(data[i].price) * parseFloat(data[i].quantity));
//          }
//         //  setsubTotal(tPrice);
//         formik.setFieldValue('subTotal', tPrice);
//         formik.setFieldValue('customerId', res?.data?.customerId || '');
//         formik.setFieldValue('email', res?.data?.customerEmail || '');
//         formik.setFieldValue('billingAddress', res?.data?.billingAddress || '');
//         formik.setFieldValue('deliveryAddress', res?.data?.shippingAddress || '');
//        //  formik.setFieldValue('deliveryTerms', res?.data?.data?.deliveryTerms || '');
    
//          setColist(data);
//        })
//        .catch(err => {
//          console.log(err, "err");
//        });
//    }
//  };

//  useEffect(() => {
//    if (formik.values.cusOrderRef) {
//      getCQ(formik.values.cusOrderRef);
//    }
//  }, [formik.values.cusOrderRef]);

 useEffect(() => {
   const subTotal = Colist.reduce((total, item) => {
     return total + (parseFloat(item.price) * parseFloat(item.quantity || 0));
   }, 0);

   formik.setFieldValue('subTotal', subTotal);
 }, [Colist]);

 useEffect(() => {
  if (Colist.length > 0) {
    setError('');
  }
}, [Colist]);

  const [customerQuotation,setCustomerQuotation] = useState([]);

  const getCQByPR = () =>{
    axiosInstance
    .get(`/customer-quotations/drop-down/${formik?.values?.prId}`)
    .then(res => {
         console.log("v",res?.data);
         let data = res.data;
            console.log(data,'dddd');
            let tempData = [];
            data.forEach((item) => {
                let tempObject = { value: item.id, label: item.name };
                tempData.push(tempObject);
            });
            res.data && setCustomerQuotation(tempData);
    }).catch((err)=>{
      console.log(err);
    })
  }
  useEffect(() => {
    if (customerQuotation?.length==1) {
      formik.setFieldValue('cusQuoteId', customerQuotation[0].value);
    }
  }, [customerQuotation]);

  const getSalesPersonByPR = () =>{
    axiosInstance
    .get(`/sales-order/sales-person/${formik?.values?.prId}`)
    .then(res => {
         console.log("sal",res?.data?.data);
         formik?.setFieldValue('salesPerson', res?.data?.data || '')
    }).catch((err)=>{
      console.log(err);
    })
  }

  const getProductByCQ = () =>{
    axiosInstance
    .get(`/customer-quotations/products/${formik?.values?.cusQuoteId}`)
    .then(res => {
      console.log('pro',res?.data);
      let data = res.data?.products;

         let tPrice=0.00
         for (let i=0;i<data?.length;i++){
          tPrice = parseFloat(tPrice) + (parseFloat(data[i].price) * parseFloat(data[i].quantity));
         }
        //  setsubTotal(tPrice);
        formik.setFieldValue('subTotal', tPrice);
        formik.setFieldValue('customerId', res?.data?.customerId || '');
        formik.setFieldValue('email', res?.data?.customerEmail || '');
        formik.setFieldValue('billingAddress', res?.data?.billingAddress || '');
        formik.setFieldValue('deliveryAddress', res?.data?.shippingAddress || '');
       //  formik.setFieldValue('deliveryTerms', res?.data?.data?.deliveryTerms || '');
    
         setColist(data);
    }).catch((err)=>{
      console.log(err);
    })
  }

  useEffect(()=>{
    if(formik?.values?.prId){
      getCQByPR();
      getSalesPersonByPR();
    }
    if(formik?.values?.cusQuoteId){
      getProductByCQ();
    }
  },[formik?.values?.prId,formik?.values?.cusQuoteId])

  const handleQuantityChange = (e, index) => {
    const updatedItems = [...Colist];
    updatedItems[index].quantity = e.target.value;
    setColist(updatedItems);
    // console.log("shsd",prlist);
  };

  const handlePriceChange = (e, index) => {
    const updatedItems = [...Colist];
    updatedItems[index].price = e.target.value;
    setColist(updatedItems);
    // console.log("shsd",prlist);
  };

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
    <Breadcrumb 
     items={[
      {
        name: "Sales Order",
        link: "/sales/sales-orders"
      },
      { name: "Create" },
 ]} />
    <BackButton/>
    </div>
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-mf mt-4 overflow-hidden ">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
          <FormikSelectTwo
                type="text"
                label="Purchase Request"
                placeholder="Purchase Request "
                Options={PR}
                formik={formik}
                name="prId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
              <FormikSelectTwo
                type="text"
                label="Customer Quotation"
                placeholder="Customer Quotation"
                Options={customerQuotation}
                formik={formik}
                name="cusQuoteId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              /> 
               <NuInput
                type="text"
                label="Busy SO No"
                placeholder="Busy SO No"
                formik={formik}
                name="busySoNo"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
               {formik.values.cusQuoteId && 
              <>
              <FormikSelectTwo
                disabled={true}
                type="text"
                label="Customer "
                placeholder="Customer"
                Options={Customers}
                formik={formik}
                name="customerId"
                width="w-full md:w-1/2 lg:w-1/3" />
                {/* <NuInput
                  label="Customer Email"
                  type="email"
                  formik={formik}
                  placeholder="example@email.com"
                  name="email"
                  width="w-full md:w-1/2 lg:w-1/3" /> */}
                  </>
              }
               <NuInput
                type="text"
                label="Customer Order Reference"
                placeholder="Customer Order  "
                formik={formik}
                name="cusOrderRef"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />          
               <NuDate
                label="Order Received On"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="orderReceivedOn"
                width="w-full md:w-1/2 lg:w-1/3"
              />
                  
              <FormikSelectTwo
                type="text"
                label="SO Type"
                placeholder="SO Type "
                Options={[
                  {value: "International", label: "International"},
                  {value: "Local", label: "Local"},
                  {value: "Ex-Stock", label: "Ex-Stock"},
                ]}
                formik={formik}
                name="type"
                width="w-full md:w-1/2 lg:w-1/3"
                // isRequired={true}
              />        
               {/* <NuInput
                label="Reference "
                type="text"
                formik={formik}
                placeholder="Reference"
                name="ref"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
             
             
              {/* <FormikSelectTwo
              // type="text"
              label="Assignee"
              placeholder="Assignee"
              Options={RFQAssignee}
              formik={formik}
              name="assignee"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <FormikSelectTwo
                type="text"
                label="Sales Person"
                placeholder="Choose"
                Options={Salesman}
                formik={formik}
                name="salesPerson"
                width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
              />
{/*              
              <NuDate
                label="Dead Line"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="deadLine"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
               {/* <NuDate
                label="Received On"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="receivedOn"
                width="w-full md:w-1/2 lg:w-1/3"
                // value={currentDate}
              /> */}
              {/* <NuDate
                label="Date"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="date"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
               {/* <NuInput
                label="Sub Total"
                type="number"
                formik={formik}
                placeholder="Sub Total"
                name="subTotal"
                width="w-full md:w-1/2 lg:w-1/3"
               
              /> */}
               {/* <DropDownAddMetaData
                label="Customer Discount"
                placeholder="Choose"
                Options={CustomerDiscount}
                formik={formik}
                name="cusDiscount"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refCustomerDiscount}
                displayProperty={"Customer Discount"}
                propertyName={"CustomerDiscount_SalesOrder"}
              />
                          
              <NuInput
                label="Shipping Instruction"
                type="text"
                formik={formik}
                placeholder="Shipping Instruction"
                name="shippingInstruction"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
               <DropDownAddMetaData
                label="Shipping Method"
                placeholder="Choose"
                Options={ShippingMethod}
                formik={formik}
                name="shippingMethod"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refShippingMethod}
                displayProperty={"Shipping Method"}
                propertyName={"shippingMethod_SalesOrder"}
              />
                
            
              {/* <NuInput
                label="Billing Address"
                type="text"
                formik={formik}
                placeholder="Billing Address"
                name="billingAddress"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuInput
                label="Delivery Address"
                type="text"
                formik={formik}
                placeholder="Delivery Address"
                name="deliveryAddress"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
             
               <DropDownAddMetaData
                label="Terms And Conditions"
                placeholder="Choose"
                Options={TermsAndConditions}
                formik={formik}
                name="termsAndConditions"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refTermsAndConditions}
                displayProperty={"Terms And Conditions"}
                propertyName={"termsAndConditions_SalesOrder"}
              />
               
               <DropDownAddMetaData
                label="Payment Status"
                placeholder="Choose"
                Options={PaymentStatus}
                formik={formik}
                name="paymentStatus"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refPaymentStatus}
                displayProperty={"Payment Status"}
                propertyName={"PaymentStatus_SalesOrder"}
              />
            
                <NuDate
                label="Requested Delivery Date"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="requestedDeliveryDate"
                width="w-full md:w-1/2 lg:w-1/3"
              />
                {/* <DropDownAddMetaData
                label="Approved Status"
                placeholder="Choose"
                Options={ApprovalStatus}
                formik={formik}
                name="approvalStatus"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refApprovalStatus}
                displayProperty={"Approved Status"}
                propertyName={"ApprovalStatus_SalesOrder"}
              /> */}
               <NuTextArea
              type="text"
              label="Billing Address"
              placeholder="Billing Address"
              formik={formik}
              name="billingAddress"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              type="text"
              label="Delivery Address"
              placeholder="Delivery Address"
              formik={formik}
              name="deliveryAddress"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
                label="Notes From Customer"
                type="text"
                formik={formik}
                placeholder="Notes From Customer"
                name="notesFromCus"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuTextArea
                label="Comments"
                type="text"
                formik={formik}
                placeholder="Comments"
                name="comments"
                width="w-full md:w-1/2 lg:w-1/3"
              />
             {/* <NuUpload
              label="Attachment"
              width="w-full md:w-1/2"
              onChange={handleChangeImg}
            /> */}
                <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>

                {deletedItemDropDown.length > 0 ?
              <div className='w-full flex justify-start gap-2'>
                 <FormikSelectTwo
                   type="text"
                   label="Product"
                   placeholder="Product"
                   Options={deletedItemDropDown}
                   formik={formik}
                   name="productId"
                   width="w-full md:w-1/2 lg:w-[67%]"
                 />
                 <button
                onClick={handleAddAfterDel}
                  type="button"
                 className=" p-1 mt-[44px] m-1 text-center h-8 cursor-pointer bg-[#048178] border border-solid border-white  text-xs font-medium  text-white  rounded-lg "
                   >
                Add
               </button>
              </div>
              :
              null
            }
         <div className='w-full bg-white overflow-y-scroll mt-5'>
         {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
              <div className='w-full py-3  bg-[#048178] flex justify-between rounded-lg px-6'>
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

              <p className=" w-[50%] text-left text-white text-xs font-medium"> Product</p>
              <p className=" w-[10%] text-center text-white text-xs font-medium">Unit</p>
              <p className=" w-[10%] text-center text-white text-xs font-medium">
                Quantity
              </p>
              <p className=" w-[10%] text-center text-white text-xs font-medium"> Price</p>
              <p className=" w-[10%] text-center text-white text-xs font-medium">Total</p>

              <p className=" w-[5%] text-center text-white text-xs font-medium flex justify-center">
                Action
                <span
                    className="pl-1 cursor-pointer"
                    onClick={() => {
                      setIsEditQua((prv) => !prv);
                    }}
                  >
                    {isEditQua === true && (
                      <EditIcon width={"14"} height={"14"} color="yellow" />
                    )}
                    {isEditQua === false && (
                      <EditIcon width={"14"} height={"14"} />
                    )}
                  </span>
              </p>
              </div>
              {Colist?.length>0?
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">

          {Colist.map((List, index) => {
            return (
                <div key={index} className="w-full py-3 flex justify-between px-6 odd:bg-white even:bg-slate-100">
                 <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                  <p className=" w-[50%] text-left text-[#606279] text-xs font-light">
                    {List?.productName}
                  </p>
                  <p className=" w-[10%] text-center text-[#606279] text-xs font-light">
                    {List?.unit}
                  </p>
                  <p className=" w-[10%] text-center text-[#606279] text-xs font-light">
                    <input
                          type="number"
                          className="w-10/12 mx-auto outline-none text-center"
                          name='quantity'
                          formik={formik}
                          value={List?.quantity || ""}
                          disabled={!isEditQua}
                          onChange={(e) => handleQuantityChange(e, index)}
                        />
                  </p>
                  <p className=" w-[10%] text-center text-[#606279] text-xs font-light">
                        <input
                          type="number"
                          className="w-10/12 mx-auto outline-none text-center"
                          name='price'
                          formik={formik}
                          value={List?.price || ""}
                          disabled={!isEditQua}
                          onChange={(e) => handlePriceChange(e, index)}
                        />
                    {/* {List?.price} */}
                  </p>
                  <p className=" w-[10%] text-center text-[#606279] text-xs font-light">
                  {(List.price * List.quantity ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
                  
                  </p>
                
                  <div className='w-[5%] text-center'> 
                   <button onClick={() => handleDeleteItem(index)} type="button" className="text-red-500 cursor-pointer  ml-4 border-none">
                    <DeleteIcon color='red' height={4} />
                  </button>
                  </div>
                </div>
              );
            })}
            </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

        </div>
        <div className='w-full my-6 flex justify-end items-center gap-4'>
              <TextMediumBase content={"Total Price : "} />
              <p className="text-sm"> {currencySymbol} </p>
              <p className="w-64 h-8 border rounded-md text-xs pl-2 py-2">
                <NuAmount defaultCurrency={false} value={formik?.values?.subTotal || 0} />
              </p> 
              {/* <NuInput
                label=""
                type="number"
                formik={formik}
                placeholder="Total Price"
                name="subTotal"
                width="w-full md:w-1/2 lg:w-1/3"
                disabled={true}
              /> */}
            </div>
            <div className="w-full my-4 mt-8 ">
              <div className="flex justify-end gap-4">
              <CancelButton handleClick={()=>{Oncancel(-1)}} />
                <SubmitButton isLoading={loading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
</div>
  )
}
export default CreateSalesOrders