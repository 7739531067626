import React, { useState } from 'react';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Rectangle } from 'recharts';
import UseGetDashBoardWithParams from '../../../../../Components/Hooks/useDashboardDataParams';

function Sales() {
  const [params, setParams] = useState({
    period:"monthly",

  });
  
  const { data, loading } = UseGetDashBoardWithParams(`/dashboard/targetBySalesMen`,params);
  const [selectedPeriod, setSelectedPeriod] = useState('Monthly');

  const chartData = data?.targets?.map(target => ({
    name: target?.salesman||"",
    target: target?.target||0,
    achieved: target?.price||0
  })) || [];



 

  const hanChange=(e)=>{
    setParams({
      period:e.target.value
    })
    setSelectedPeriod(e.target.value)
  }


  const periods = [
    { value: 'monthly', option: 'Monthly' },
    { value: 'quarterly', option: 'Quarterly' },
    { value: 'halfyearly', option: 'Half Yearly' },
    { value: 'yearly', option: 'Yearly' },
  ];

  const CustomTooltip = ({ active, payload, }) => {
    if (active && payload && payload.length) {
      return (
        <div className=" max-w-[250px] bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-lg p-[5px]">
          <table className=" w-full border-collapse ">
            {payload &&
            payload.map((p, index) => (
              <tr key={index} className=' text-xs'>
              <td style={{color:p?.fill}} className={` w-[60px]`}>{p?.name || "-"}</td>
              <td>
                : <span style={{color:p?.fill}} className=" font-semibold">{p.value||0}</span>
              </td>
            </tr>
            ))}
          </table>
        </div>
      );
    }
  
    return null;
  };
  

  return (
    <div className="col-span-2 bg-white h-[40vh] w-full p-4 rounded-lg drop-shadow-lg mb-4">
      <div className="flex justify-between items-center ">
        <p className="text-xs 2xl:text-sm text-[#4F4768] font-medium">Target / Sales</p>
        <div className="flex items-center">
          <select
            value={selectedPeriod}
            onChange={hanChange}
            className="border rounded-md p-1 text-sm text-[#ACACAC] font-normal"
          >
            {periods.map((period) => (
              <option key={period.value} value={period.value}>
              {period.option}
            </option>
            ))}
          </select>
        </div>
      </div>
      {loading ? (
        <div className="p-4 max-w-sm w-full flex flex-col justify-center items-center h-full">
          <div className="animate-pulse flex space-x-4 w-full">
            <div className="flex-1 space-y-3 py-1">
              <div className="h-3 bg-slate-200 rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                  <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                </div>
                <div className="h-3 bg-slate-200 rounded"></div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                  <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (

<ResponsiveContainer width="100%" height="90%">
      <BarChart data={chartData} margin={{ top: 0, right: 10, left: -25, bottom: 0 }}>
        <XAxis dataKey="name" fontSize={12} fontWeight={500} />
        <YAxis 
          tick={false} 
          label={{ value: 'Amount', angle: -90, position: 'insideLeft', textAnchor: 'middle', dy: -5, dx: 40 }} 
          fontSize={12}
        />
        <Tooltip content={<CustomTooltip />} cursor={{fill:"none"}} />
        <Legend 
          fontSize={8} 
          verticalAlign="top" 
          height={26} 
          iconSize={12} 
          iconType="circle" 
          wrapperStyle={{ fontSize: '12px' }} 
        />
        <Bar dataKey="target" fill="#8884d8" barSize={8} stackId="a" radius={[10, 10, 0, 0]} isAnimationActive={false} /> {/* Disabled hover effect */}
        <Bar dataKey="achieved" fill="#82ca9d" barSize={8} stackId="a" radius={[10, 10, 0, 0]} isAnimationActive={false} /> {/* Disabled hover effect */}
      </BarChart>
    </ResponsiveContainer>
      )}
    </div>
  );
}

export default Sales;
