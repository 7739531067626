import React, { useEffect, useState } from 'react';
import DataTable from '../../../../Components/Others/DataTable';
import { CancelButton, CreateButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import MoreOptions from '../../../../Components/Utils/MoreOptions';
import CustomDataTable from '../../../../Components/Others/CustomDataTable';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import { NuDate } from '../../../../Components/Utils/Input';
import Importdoc from './Importdoc';
import MultiEditRFQ from './MultiEditRFQ';
import ModalContainer from '../../../../Components/Others/ModalContainer';
import { GetAccess } from '../../../../Components/Utils/roles';
import useFetchMDIndex from '../../../../Components/Hooks/useFetchMDIndex';

function RFQIndex() {

  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(20)
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const {value:Status} = useFetchMDIndex(`/dropdown/dropdown-details/`,'status_RFQ',showFilterDialog)

  const [filterProps, setFilterProps] = useState({
    status:"",
    fromDate:"",
    toDate:"",
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:"",
    status:"",
    fromDate:"",
    toDate:"",

  });

  const clearFilter = ()=> {
    setParams({...params,status:"", fromDate:"",
    toDate:"",})
    formik.values.status=""
    formik.values.fromDate=""
    formik.values.toDate=""

      setFilter(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,status:value.status,fromDate:value.fromDate,toDate:value.toDate})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        status: "",
        fromDate:"",
        toDate:"",
      });
    }
  })
  const { data, totalCount, getData } = UseGetDataSearch( '/rfq/',params);

  const defaultHeader = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "id",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Reference",
    //   width: "10%",
    //   isLink: true,
    //   id: "id",
    //   link: '/procurement/rfq/view/',
    //   field: "ref",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "YPR",
      width: "10%",
      isLink: true,
      id: "id",
      link: '/procurement/rfq/view/',
      field: "prRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "RFQ Type",
      width: "10%",
      isLink: true,
      id: "id",
      link: '/procurement/rfq/view/',
      field: "rfqType",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Supplier",
      width: "10%",
      field: "vendorName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Prepared By",
      width: "10%",
      field: "preparedByName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"id",
        removeValue:"ref",
        url:`/rfq/`,
        moduleName:"RFQ",
        from:`/procurement/rfq`,
        editLink:"/procurement/rfq/edit/",
        viewLink:"/procurement/rfq/view/",
        EmailRequired: true,
        emailType: false
      }
    },
  ];
  const availableHeaders = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "id",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Reference",
    //   width: "10%",
    //   isLink: true,
    //   id: "id",
    //   link: '/procurement/rfq/view/',
    //   field: "ref",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "YPR",
      width: "10%",
      isLink: true,
      id: "id",
      link: '/procurement/rfq/view/',
      field: "prRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "RFQ Type",
      width: "10%",
      isLink: true,
      id: "id",
      link: '/procurement/rfq/view/',
      field: "rfqType",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Supplier",
      width: "10%",
      field: "vendorName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Local Purchaser",
      width: "10%",
      field: "purchaserName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Prepared By",
      width: "10%",
      field: "preparedByName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Customer Reference",
      width: "10%",
      field: "customerRfq",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Date",
    //   width: "10%",
    //   field: "date",
    //   type:"date",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
   
   
    
   
   
    // {
    //   title: "Reviewed By",
    //   width: "10%",
    //   field: "reviewedByName",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Approved By",
    //   width: "10%",
    //   field: "approvedByName",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
   
    // {
    //   title: "Verified By",
    //   width: "10%",
    //   field: "verifiedByName",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
   
    // {
    //   title: "Comments",
    //   width: "10%",
    //   field: "comments",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
   
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"id",
        removeValue:"ref",
        url:`/rfq/`,
        moduleName:"RFQ",
        from:`/procurement/rfq`,
        editLink:"/procurement/rfq/edit/",
        viewLink:"/procurement/rfq/view/",
        EmailRequired: true,
        emailType: false
      }
    },
  ];
  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])

   const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };

  const [showImportModal, setShowImportModal] = useState(false);

  const handleImportClick = () => {
    setShowImportModal(true);
  };

  const closeModal = () => {
    setShowImportModal(false);
  };

  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [listofNumbers, setListofNumbers] = useState([])
  const [fullList, setFullList] = useState([])

  const selectOneFunction = (value) =>{ 
    let NewId;
    if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
      NewId = listofNumbers.filter(Temp=> Temp !== value);
      setListofNumbers(NewId)
    }else{
      setListofNumbers([...listofNumbers,value])
    }
  }

  const selectMultipleFunction = (e) =>{ 
console.log(e.target.checked);
if(e.target.checked){
  setListofNumbers(fullList)
}else{
  setListofNumbers([])
}

  }



  useEffect(()=>{
    let tempArr = []
    data && data.forEach(i=>{
      console.log("I",i?.id);
      tempArr.push(i?.id)
    });
    setFullList(tempArr)
  },[data])

  return (
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
        <Breadcrumb items={[
          { name: "Procurement" },

          { name: "RFQ" },
        ]} />
        <div className=' flex gap-2'>
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />    <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
       
          {/*{GetAccess("rfq_create") && <CreateButton label='Import' handleClick={handleImportClick} /> }*/}
         { GetAccess("rfq_create") && <Link to="/procurement/rfq/create"><CreateButton label='RFQ' /></Link>}
        </div>
      </div>

      <CustomDataTable 
      fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeader}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-145px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title='RFQ List'
        storageName="RfqTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById="id"
        editAccess="rfq_edit"      
        deteleAccess="rfq_delete" 
        // EmailRequired={true}
        />
          {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter RFQ</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <p className="py-2 px-6 text-m">Date</p>
                    <div className="flex justify-between  w-full ">
             <NuDate
              label="From "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="fromDate"
              width="w-full "
            />
           <NuDate
              label="To "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="toDate"
              width="w-full "
            />
            </div>
            <FormikSelectTwo
            type="text"
            label="Status"
            placeholder="Status"
            Options={Status}
           formik={formik}
           name="status"
           width="w-full"
          />
                     
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
        {showImportModal && (<Importdoc show={showImportModal} onClose={closeModal} getData={getData} />)}
        {showEdit && (<MultiEditRFQ data={listofNumbers} getData={getData} onClose={()=>setShowEdit(false)}/>)}
        <ModalContainer
                visiable={showDelete}
                title="Warning"
                content={`Are you sure, All selected datas will be removed from Purchase Requests List!`}
                handleClick={() =>{}}
                closeModal={() => setShowDelete(false)}
            />
    </div>
  )
}

export default RFQIndex