import React from 'react'
import { TextMediumSM, TextMediumXS } from '../../../../../Components/Text/MediumText';
import { useState } from 'react';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import UseGetDashBoardWithParams from '../../../../../Components/Hooks/useDashboardDataParams';

function CostSavingByOrdersProDash() {
  const [selectedPeriod, setSelectedPeriod] = useState( 'Monthly');
  const [params, setParams] = useState({
    period:"monthly",

  });
  const { data, loading } = UseGetDashBoardWithParams('/dashboard/cost-save-by-order',params);
  //   const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
  //   const [selectedMonth, setSelectedMonth] = useState(currentMonth);     
  //       const handleMonthChange = (event) => {
  //         setSelectedMonth(event.target.value);
  //       };
        const dataDummy = data?.top_orders_by_value?.map(product => ({
            quotes:product.order,
          value: product.value,
          ordered: product.ordered_price,
          diffrence:product.difference,
        })) || [];
        const periods = [
          { value: 'monthly', option: 'Monthly' },
          { value: 'quarterly', option: 'Quarterly' },
          { value: 'halfyearly', option: 'Half Yearly' },
          { value: 'yearly', option: 'Yearly' },
        ];
        const hanChange=(e)=>{
          setParams({
            period:e.target.value
          })
          setSelectedPeriod(e.target.value)
        }
       
  return (
    <div className="col-span-2 bg-white h-[40vh] w-full p-4 rounded-lg drop-shadow-lg mb-4">
     <div className="flex justify-between items-center mb-2">
      <p className="text-xs 2xl:text-sm text-[#4F4768] font-medium">Cost Saving By Orders</p>
          <div className="flex items-center">
            <select
              value={selectedPeriod}
              onChange={hanChange}
              className="border rounded-md p-1 text-sm text-[#ACACAC]  font-normal"
            >
              {periods.map((period) => (
                <option key={period.value} value={period.value}>
                {period.option}
              </option>
              ))}
            </select>
          </div>
        </div>
        <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>

      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <div className="w-full h-[85%] overflow-y-scroll  pb-4">
        <table className="w-full">
          <thead className="sticky top-0 bg-white shadow-sm">
            <tr>
              <th className="text-left px-2 py-1"><TextMediumSM content="Quotes" /></th>
              <th className="text-left px-2 py-1"><TextMediumSM content="Value" /></th>
              <th className="text-left px-2 py-1"><TextMediumSM content="Ordered" /></th>
              <th className="text-left px-2 py-1"><TextMediumSM content="Diffrence" /></th>
          
            </tr>
          </thead>
          <tbody className=''>
            {dataDummy.map((value, index) => (
              <tr className="odd:bg-white even:bg-slate-50  " key={index}>
                <td className="px-2 py-2"><TextMediumXS content={value?.quotes || "N/A"} /></td>
                <td className="px-2 py-2"><TextMediumXS content={value?.value || "N/A"} /></td>
                <td className="px-2 py-2"><TextMediumXS content={value?.ordered || "N/A"} /></td>
                <td className="px-2 py-2"><TextMediumXS content={value?.diffrence || "N/A"} /></td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      )}
    </div>
  )
}

export default CostSavingByOrdersProDash;