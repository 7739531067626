import { useFormik } from "formik";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { BackButton, CancelButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import { useState } from "react";
import * as yup from 'yup';
import { NuDate, NuInput, NuMobileNumber, NuUpload } from "../../../../Components/Utils/Input";
import { useLocation } from "react-router-dom";
import useEditFormData from "../../../../Components/Hooks/useEditFormData";
import CustomImgUpload from "../../../../Components/Utils/CustomImgUpload";
import { currencySymbol } from "../../../../assets/CurrencySymbols";
import FormikSelectTwo from "../../../../Components/Utils/Select";


const EditCompanyInfo = () =>{
    const [image, setImage] = useState({ preview: "", raw: "" });
    const location = useLocation();
    const data = location?.state?.data;
    const from = location?.state?.from;
    const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
    console.log("cxz",data);
  const [createNew, setCreateNew] = useState({
    taxId: data?.taxId,
    companyName: data?.companyName,
    mobile: data?.mobile?.number,
    countryCode: data?.mobile?.countryCode,
    email: data?.email,
    website: data?.website,
    establishedOn: data?.establishedOn,
    currency: data?.currency,
    registrationNo: data?.registrationNo,
    businessIndustry: data?.businessIndustry,
    businessDesc: data?.businessDesc,
    phone: data?.phone,
    city: data?.address?.city,
    streetName: data?.address?.streetName,
    district: data?.address?.district,
    state: data?.address?.state,
    companyLogo: data?.compLogo?.file,
    country: data?.address?.country,
    postalCode: data?.address?.postalCode,
    currency: data?.currency,
    socialMediaLinks: "",
  })
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleDeleteImg = () => {
    setImage({
      preview: "",
      raw: "",
    });
    setCreateNew(prevState => ({ ...createNew, companyLogo: "" }))
    formik.values.companyLogo = ''
  }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
            let formData = new FormData();
            formData.append('json_data', JSON.stringify(value));
            formData.append('companyLogo', image.raw);
      
            editFormData(`/company-info/edit-company-details/1`, formData, resetForm, from);
        },
      });
    return(
        <div className='w-full h-full px-4 py-2'>
        <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            { 
                name: "Company Info",
                link: "/settings"
            },
            { name: "Edit" },
          ]} />
        <BackButton/>
          </div>
          <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-23vh)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto">
          <CustomImgUpload
            borderStyle="dashed"
            handleChange={handleChangeImg}
            label={'Company Logo'}
            preview={image.preview || createNew.companyLogo || ""}
            removeFunction={handleDeleteImg}
          />
            <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Company Info</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>

            <NuInput
              type="text"
              label="Company Name"
              placeholder="Company Name"
              formik={formik}
              name="companyName"
              width="w-full md:w-1/2 lg:w-1/3"
            />
              <NuInput
              type="email"
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              width="w-full md:w-1/2 lg:w-1/3"
            />
              <NuInput
              type="number"
              label="Phone"
              placeholder="Phone"
              formik={formik}
              name="phone"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuMobileNumber
              nunCode="countryCode"
              label="Mobile"
              placeholder="mobile"
              formik={formik}
              name="mobile"
               width="w-full md:w-1/2 lg:w-1/3"
            />
          
            <NuInput
              type="text"
              label="Website"
              placeholder="Website"
              formik={formik}
              name="website"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuDate
              label="Established On"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="establishedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
              <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Address</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>
      <NuInput
              type="text"
              label="Country"
              placeholder="Country"
              formik={formik}
              name="country"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="State"
              placeholder="State"
              formik={formik}
              name="state"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="District"
              placeholder="District"
              formik={formik}
              name="district"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="City"
              placeholder="City"
              formik={formik}
              name="city"
              width="w-full md:w-1/2 lg:w-1/3"
            />
      <NuInput
              type="text"
              label="Street "
              placeholder="Street "
              formik={formik}
              name="streetName"
                width="w-full md:w-1/2 lg:w-1/3"
            />
 <NuInput
              type="number"
              label="Zip / Postal Code"
              placeholder="Zip / Postal Code"
              formik={formik}
              name="postalCode"
              width="w-full md:w-1/2 lg:w-1/3"
            />
              <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Business</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>
      <NuInput
              type="text"
              label="Business Industry"
              placeholder="Business Industry"
              formik={formik}
              name="businessIndustry"
              width="w-full md:w-1/2 lg:w-1/3"
            />
           
           
            <NuInput
              type="number"
              label="Registration No."
              placeholder="Registration No"
              formik={formik}
              name="registrationNo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Tax Id"
              placeholder="Tax Id"
              formik={formik}
              name="taxId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <FormikSelectTwo
                  label="Currency"
                  placeholder="Choose"
                  Options={currencySymbol}
                  formik={formik}
                  name="currency"
                  width="w-full md:w-1/2 lg:w-1/3"
                />
            <NuInput
              type="text"
              label="Business Desc"
              placeholder="Business Desc"
              formik={formik}
              name="businessDesc"
              width="w-full md:w-1/2 lg:w-1/3"
            />
          
           
           
           
           
            {/* <NuUpload
              label="Attachment"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            /> */}
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton />
              </div>
            </div>
          </div>
          </div>
          </form>
         </div>
    )
}

export default EditCompanyInfo;