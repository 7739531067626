import { useEffect, useState } from "react";
import useFetchById from "../../../../Components/Hooks/useFetchById";
import { TextMediumBase, TextMediumSM, TextMediumXS } from "../../../../Components/Text/MediumText";
import { TextXS } from "../../../../Components/Text/NormalText";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { BackButton } from "../../../../Components/Utils/Buttons";
import socketAxiosInstance from "../../../../Services/socketAxiosCommon";
import axiosInstance from "../../../../Services/axiosCommon";
import EditIcon from "../../../../Components/Icons/Edit";
import { Link } from "react-router-dom";

const CompanyInfo = () => {
  const [data,setData] = useState([]);
  const GetData = async () => {
    await axiosInstance.get(`/company-info/1`).then(res => {
        console.log("bbb",res?.data?.data);
        res?.data?.data && setData(res?.data?.data)          
    }).catch((err)=>{
        console.log(err);
    })
}
useEffect(()=>{
   GetData();
},[])

  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Settings",
            },
            {
              name: "Company Info",
            },
          ]}
        />
        <BackButton />
      </div>
      <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto p-2 rounded-md overflow-y-scroll">
        <div className=" w-full bg-[#048178] px-2 py-[2px] rounded-md flex justify-between items-center">
          <TextMediumBase content={"Company Info"} color="text-white" />
          <Link to={"/settings/company/edit"} state={{ data: data, from: "/settings" }} >
            <EditIcon color="white"/>
          </Link>
        </div>
        <TextMediumBase content={"Company Profile :"} otherStyles="my-2" />
        <div className="w-full  border-b-2 flex items-center pb-6">
          <div className=" w-4/12 h-36 bg-gray-100 rounded-lg overflow-hidden flex justify-center items-center">
            {data?.compLogo?.file && <img src={data?.compLogo?.file} style={{ height: "140px", width: "auto" }} alt="#" className="" />}  
          </div>
          <div className="w-8/12 h-full  flex items-center  flex-wrap p-3 text-xs">
            <TempComponent width={"w-full"} label={"Company Name :"} value={data?.companyName||"-"} />
            <TempComponent width={"w-6/12"} label={"E-Mail :"} value={data?.email||"-"} />
            <TempComponent width={"w-6/12"} label={"Phone :"} value={data?.phone||"-"} />
            <TempComponent width={"w-6/12"} label={"Mobile :"} value={data?.mobile?.display||"-"} />
            <TempComponent width={"w-6/12"} label={"Website :"} value={data?.website||"-"} />
            <TempComponent width={"w-6/12"} label={"Established On :"} value={data?.establishedOn||"-"} />
          </div>
        </div>
        <TextMediumBase content={"Address :"} otherStyles="my-2" />
        <div className="w-full  border-b-2 flex items-center flex-wrap p-3 pb-6">
        <TempComponent width={"w-4/12"} label={"Street :"} value={data?.address?.streetName||"-"} />
        <TempComponent width={"w-4/12"} label={"City :"} value={data?.address?.city||"-"} />
        <TempComponent width={"w-4/12"} label={"District :"} value={data?.address?.district||"-"} />
        <TempComponent width={"w-4/12"} label={"State :"} value={data?.address?.state||"-"} />
        <TempComponent width={"w-4/12"} label={"Country :"} value={data?.address?.country||"-"} />
        <TempComponent width={"w-4/12"} label={"Zip/Postal Code :"} value={data?.address?.postalCode||"-"} />

        </div>
        <TextMediumBase content={"Business:"} otherStyles="my-2" />
        <div className="w-full  border-b-2 flex items-center flex-wrap p-3 pb-6">
        <TempComponent width={"w-4/12"} label={"Industry:"} value={data?.businessIndustry||"-"} />
        <TempComponent width={"w-4/12"} label={"Registration No. :"} value={data?.registrationNo||"-"} />
        <TempComponent width={"w-4/12"} label={"Tax Id :"} value={data?.taxId||"-"} />
        <TempComponent width={"w-4/12"} label={"Currency :"} value={data?.currency||"-"} />
        <TempComponent width={"w-8/12"} label={"Business Desc:"} value={data?.businessDesc||"-"} />
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;



function TempComponent({width,label,value}){
  return(
    <div className={`${width} my-2`}>
    <TextMediumSM content={label} />
    <TextMediumXS content={value} color="#606279" />

  </div>
  )
}
