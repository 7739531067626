import axios from "axios";
import { useEffect, useState } from "react";
import Breadcrumb from "../../../Components/Utils/Breadcrumb";
import { Link } from "react-router-dom";
import { BackButton, CreateButton, EditButton } from "../../../Components/Utils/Buttons";
import NuPills from "../../../Components/Utils/Nupills";
import DecryptData from "../../../Components/Utils/Decrypt";
import axiosInstance from "../../../Services/axiosCommon";

function UserProfile() {
  const userDetails = DecryptData("sLiAtEdReSuEdArT")||"";
console.log(userDetails,'khgh');

  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);

  const getUserDetails = async () => {
    try {
      const res = await axiosInstance.get(`/user/user-details/${userDetails?.id}`); 
            setUser(res?.data?.data || {});
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

 
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between">
        <Breadcrumb items={[{ name: "User Profile" }]} />
        <BackButton />
      </div>
      <div className="w-full h-[calc(100vh-150px)]">
        <h2 className="text-sm font-semibold text-[#4F4768] p-4 mb-4">User Profile View :</h2>
        <div className="w-[98%] mx-auto py-7 h-5/6 lg:h-4/6 flex rounded-md bg-white border-8 border-[#048178]/90 border-r-0 border-y-0 shadow-lg">
          <div className="w-2/6 lg:w-2/6 px-4 flex flex-col justify-center border-r-2 border-solid border-r-gray-50">
            <div className="w-36 h-36 rounded-full bg-[#BFD0FF] flex justify-center items-center mx-auto">
              <div className="w-[124px] h-[124px] rounded-full bg-[#A1B9FF] flex justify-center items-center">
                <div className="w-[110px] h-[110px] rounded-full bg-white border-blue-100 border font-medium text-white text-3xl capitalize flex justify-center items-center overflow-hidden">
                  {user.userImage?.file?.length > 0 ? (
                    <img src={user.userImage?.file} alt="user" className="rounded-full w-full h-full" />
                  ) : (
                    user?.name?.charAt(0)
                  )}
                </div>
              </div>
            </div>
            <div className="text-center bg-white rounded-lg drop-shadow-lg ">
              <ul className="list-none pt-5">
                <li>
                  <p className="text-sm text-mainblack-500 font-semibold">{user?.name || "-"}</p>
                </li>
                <li>
                  <p className="font-medium text-xs text-mainblack-300">{user?.designation || "-"}</p>
                </li>
                <li>
                  <div className="w-3/12 my-3 mx-auto text-center">
                    <NuPills
                      width="w-16"
                      bgColor="bg-[#E7F9E7]"
                      textColor="text-[#39D42B]"
                      fontWight="font-semibold"
                      fontSize="text-[12px]"
                    >
                      {user?.status === 1 ? "Active" : "Inactive"}
                    </NuPills>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className=" w-full p-4 overflow-y-scroll">
        <div className=" w-full flex flex-wrap  border border-gary  rounded-lg overflow-y-scroll">
         
          <div className=" w-full grid grid-cols-2 gap-x-8 ">
          <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
              Email
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {user?.email || '-'}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
              Phone
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {user?.phone?.display || "-"}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
              Division
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {user?.division || "-"}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
              Country
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {user?.country || "-"}
              </p>
            </div> 
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
              State
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {user?.state || "-"}
              </p>
            </div> 
           
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
              District
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {user?.district || "-"}
              </p>
              </div>
              <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
              City
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {user?.city || "-"}
              </p>
              </div>
               <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
              Street
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {user?.streetName || "-"}
              </p>
            </div>
           
             <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
              Postal Code
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {user?.postalCode || "-"}
              </p>
           
            
       </div>
        
</div>
</div>
<div className="flex gap-4 mt-1">
          <div className="w-[50%] ">
        <p className="  text-xs font-semibold text-[#111537] p-1">
         Address 1
            </p>
        <div className="  border border-gary  h-28 overflow-y-scroll rounded-lg items-center">
           
            <p className="  text-xs p-2 break-words text-[#606279]">
              {user.address1 || "-"}
            </p>
          </div>
         </div>
          <div className="w-[50%] ">
          <p className=" p-1 text-xs font-semibold text-[#111537]">
           Address 2
            </p>
          <div className=" border border-gary  overflow-y-scroll h-28 rounded-lg p-2">
          <p className="  text-xs p-2 break-words text-[#606279]">
              {user.address2 || "-"}
            </p>
    </div>
    </div>
    </div>
         
        </div>
        </div>

        <div className="w-full p-4">
          <h2 className="text-sm text-[#4F4768] font-semibold mt-4">Settings :</h2>
          <div className="w-full flex items-center gap-6 p-4">
            <div className="flex justify-between gap-4 items-center my-2">
              <p className="font-normal text-xs text-[#4F4768]">Profile</p>
              <Link to={`/profile/editProfile/${userDetails?.id}`} state={{data:user , from: "/profile" }}>
                <EditButton className="text-xs">Edit</EditButton>
              </Link>
            </div>
            <div className="flex justify-between gap-4 items-center my-2">
              <p className="font-normal text-[#4F4768] text-xs">Change Password</p>
              <Link to={`/profile/PasswordProfile/${userDetails?.id}`}>
                <CreateButton label="Change" />
              </Link>
            </div>
            {/* <div className="flex justify-between gap-4 items-center my-2">
              <p className="font-normal text-[#4F4768] text-xs">Email & Notification</p>
              <CreateButton className="text-xs" onClick={() => setOpen(true)} label="Change" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
