import { useState } from "react";
import { useToast } from "../../../../Components/Others/ToastServices";
// import { useFormik } from "formik";
// import * as yup from 'yup';
import axiosInstance from "../../../../Services/axiosCommon";
import EditIcon from "../../../../Components/Icons/Edit";
import DeleteIcon from "../../../../Components/Icons/Delete";
import ModalContainer from "../../../../Components/Others/ModalContainer";
// import FormikSelectTwo from "../../../../Components/Utils/Select";
// import { CancelButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import { useDeleteData } from "../../../../Components/Hooks/useDeleteData";
import { Link } from "react-router-dom";
// import EmailModal from "../../../../Components/Utils/EmailModel";
import Chat from "../../../../Components/Icons/Chat";
import { GetAccess } from "../../../../Components/Utils/roles";
import ViewIcon from "../../../../Components/Icons/ViewIcon";
// import Email from "../../../../Components/Icons/Email";
import CircleCheckIcon from "../../../../Components/Icons/CircleCheckIcon";
import CircleXIcon from "../../../../Components/Icons/CircleXIcon";


const ViewMore = ({
  data,
  fetcher,
  id,
  removevalue,
  url,
  moduleName,
  from,
  viewLink,
  editLink,
  EmailRequired,
  EditAccess="",
  DeteleAccess=""
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const { handle } = useDeleteData(fetcher);
  const handleDelete = async () => handle(`/user/remove-user/${data?.userId}`,setIsModalOpen);
 
  console.log("ssss",fetcher);
  // const userAccess = JSON.parse(localStorage.getItem("userAccess"));
  let Edit = editLink ? true : null
  let Comment = editLink ? false : null

  let EditCondition = editLink?.includes("{id}") ? true : false
  let editableLink = EditCondition ? editLink.replace("{id}", `${id}`) :""

  let Delete = true

  const toast = useToast();
  const [status, setStatus] = useState(data?.status || 0); 
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  
  const handleStatusChange = () => {
    setStatusModalOpen(true);
  };

  const handleConfirmStatusChange = async () => {
    console.log("ID being used in API call:", data?.userId);
    try {
       await axiosInstance.put(`/user/update-status/${data?.userId}`, {
          status: status === 1 ? 0 : 1,
       });
       
       setStatus((prevStatus) => (prevStatus === 1 ? 0 : 1));
 
       toast.open({
          type: "success",
          message: "Success",
          description: "Status updated successfully.",
       });
 
       setStatusModalOpen(false);
       fetcher();
    } catch (err) {
       toast.open({
          type: "error",
          message: "Error",
          description: err.response?.data?.error || err.response?.data?.message || "Network error.",
       });
    }
 };
 

  return (
      <>
          <div className=' w-full flex  justify-start items-center'>
              <div className=' w-full flex justify-start '> 
              {/* { EmailRequired && <div className=' w-[25%] overflow-hidden flex justify-center items-center cursor-pointer ' onClick={() => {
                  setModalOpen(true);
                  }}>
                  <Email height={"3"} width={"3"} color='#BF2EF0' /> 
                  </div>} */}
<div
            className="w-[25%] overflow-hidden flex justify-center items-center cursor-pointer"
            onClick={handleStatusChange}
          >
            {status === 1 ? (
              <CircleCheckIcon color="green" /> 
            ) : (
              <CircleXIcon color="red" /> 
            )}
          </div>
                  <div className=' w-[25%] overflow-hidden flex justify-center items-center'>
                   <Link className=' text-black hover:text-black border-none' to={`/settings/user/view/${data?.userId}`} state={{ data: data }} >
                    <ViewIcon  color='#048178'/>
                   </Link>
                  </div>
                 { Edit && GetAccess(EditAccess) && <div className=' w-[25%] overflow-hidden flex justify-center items-center '>
                  <Link className=' text-black hover:text-black border-none' to={EditCondition? editableLink:editLink + `${data?.userId}`} state={{ data: data, from: from }} >
                    <EditIcon color='skyblue' width={"12"} height={"12"}/>
                  </Link>
                  </div>}
                  
                  
                  { Delete && GetAccess(DeteleAccess) && <div className=' w-[25%] overflow-hidden flex justify-center items-center cursor-pointer ' onClick={() => {
                  setIsModalOpen(true);
                  }}>
                  <DeleteIcon height={"3"} width={"3"} color='lightcoral' /> 
                  </div>}
                 
                 {
                  Comment && <div className=' w-[25%] overflow-hidden flex justify-center items-center  cursor-pointer '>
                  <Chat width={"11"}  height={"11"}/>
                  </div>
                 } 
              </div>
          </div>
          <ModalContainer
              visiable={isModalOpen}
              title="Warning"
              content={`Are you sure, ${removevalue} will removed from User!`}
              handleClick={() => handleDelete()}
              closeModal={() => setIsModalOpen(false)}
          />
          <ModalContainer
        visiable={statusModalOpen}
        title="Alert"
        content={`Are you sure you want to ${
          // status===1?"In-Active":"Active"
          status === 1 ? "Deactivate" : "Activate"
        } this user?`}
        handleClick={handleConfirmStatusChange}
        closeModal={() => setStatusModalOpen(false)}
      />
          {/* <ModalContainer
              visiable={modalOpen}
              title=""
              hasContent={true}
              content={<><EmailModal onClose={() => setModalOpen(false)} /></>}
              // handleClick={() => {}}
              hasButton={false}
              outSideClick={false}
              closeModal={() => setModalOpen(false)}
          /> */}
           {/* <EmailModal isOpen={modalOpen} onClose={() => setModalOpen(false)} /> */}
      </>
  );
};
        
export default ViewMore;
