// import React from 'react'
// import { TextMediumBase, TextMediumSM, TextMediumXS } from '../../../../Components/Text/MediumText'
// import UseGetDashBoardData from '../../../../Components/Hooks/useDashboardData';

// function QuotestoCustomersDashboard() {
//   const { data, loading } = UseGetDashBoardData(`/dashboard/quoteComparison`);
//   console.log("fff",data);
//   return (
//     <div className="col-span-3 row-span-2 col-start-7 row-start-2 bg-white max-h-[35vh] p-2 rounded-lg drop-shadow-lg">
//       <div className=" w-full bg-emerald-500 px-1 py-[2px] rounded-md">
//       <TextMediumSM content={"Quotes to Customers"} color='text-white'/>
//       </div>
//         {loading == true ? (
//         <>
//           <div className=" p-4 max-w-sm w-full flex flex-col justify-center items-center h-full">
//             <div className="animate-pulse flex space-x-4 w-full">
//               <div className="flex-1 space-y-3 py-1">
//                 <div className="h-3 bg-slate-200 rounded"></div>
//                 <div className="space-y-3">
//                   <div className="grid grid-cols-3 gap-4">
//                     <div className="h-3 bg-slate-200 rounded col-span-2"></div>
//                     <div className="h-3 bg-slate-200 rounded col-span-1"></div>
//                   </div>
//                   <div className="h-3 bg-slate-200 rounded"></div>
//                   <div className="grid grid-cols-3 gap-4">
//                     <div className="h-3 bg-slate-200 rounded col-span-1"></div>
//                     <div className="h-3 bg-slate-200 rounded col-span-2"></div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </>
//       ) : (
//         <>
//           <div className=" w-full  h-[90%]">
//             <div className=" w-full my-2">
//               <TextMediumXS content={"Customers Category Wise :"} />
//               {data?.customer_category_wise?.map((item)=>{
//                 return(
//                   <div className='flex justify-evenly my-1'>
//                       <TextMediumXS
//                         align="center"
//                         content={`Category: ${item?.category || 0}`}
//                       />
//                       <TextMediumXS
//                         align="center"
//                         content={`Count: ${item?.count || 0}`}
//                       />
//                   </div>
//                 )
//               })}
//             </div>
//             <div className=" w-full">
//               <div className=" text-center flex gap-2 w-full my-2">
//                 <TextMediumXS content={"Growth Percentage Quotes :"} />
//                 <TextMediumXS
//                   content={data?.growth_percentage_quotes || 0}
//                 />
//               </div>
//               <div className=" text-center flex gap-2 w-full my-2">
//                 <TextMediumXS content={"Growth Percentage Value"} />
//                 <TextMediumXS
//                   align="center"
//                   content={data?.growth_percentage_value || 0}
//                 />
//               </div>
//               <div className=" text-center flex gap-2 w-full my-2">
//                 <TextMediumXS content={"Total Quotes :"} />
//                 <TextMediumXS
//                   align="center"
//                   content={data?.total_quotes || 0}
//                 />
//               </div>
//               <div className=" text-center flex gap-2 w-full my-2">
//                 <TextMediumXS content={"Total Value :"} />
//                 <TextMediumXS
//                   align="center"
//                   content={data?.total_value || 0}
//                 />
//               </div>
//             </div>
//           </div>
//         </>
//       )}
//     </div>
//   )
// }

// export default QuotestoCustomersDashboard


import React from 'react';
import { TextMediumSM, TextMediumXS } from '../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../Components/Hooks/useDashboardData';
import NuAmount from '../../../../Components/Utils/NuAmount';

function QuotestoCustomersDashboard() {
  const { data, loading } = UseGetDashBoardData(`/dashboard/quoteComparison`);
  
  return (
    <div className=" h-[40vh] w-full bg-white p-2 pb-10 rounded-lg drop-shadow-lg overflow-hidden mb-4">
    <div className="w-full px-1 py-[2px] rounded-md flex justify-between items-center p-4">
         <TextMediumSM content={"Quotes to Customers"} color='text-[#4F4768]' />
         <div className="bg-[#95D2B3] px-2 py-0.5 rounded-md">


         <p className='text-[#333333] text-[12px] font-semibold '><NuAmount value={data?.total_price || 0}/> </p>
      </div>
      </div>
        {/* <TextMediumSM content={data?.total_quotes || 0}  className="font-bold text-[20px] text-[#333333]" /> */}

      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>

      {loading ? (
        <div className="p-4 max-w-sm w-full flex flex-col justify-center items-center h-full">
          <div className="animate-pulse flex space-x-4 w-full">
            <div className="flex-1 space-y-3 py-1">
              <div className="h-3 bg-slate-200 rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                  <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                </div>
                <div className="h-3 bg-slate-200 rounded"></div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                  <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="  h-[96%]  overflow-y-scroll">
<div className='grid'>
        {/* <div className="flex flex-col items-center h-full p-1"> */}
          {/* <div className="flex justify-around w-full text-center items-center"> */}
          {/* {data?.customer_category_wise?.map((item, index) => (
              <div style={{ borderRight: "1px solid #f0f0f0" }} className="flex flex-col items-center p-2" key={index}>
                 <p className='text-[#333333] text-base  2xl:text-[22px] font-semibold '>{`${item.count || 0}`}</p>
          <p className='text-xs 2xl:text-sm'>{`Category ${item.category || ""}`}</p>
          <p className='text-[#ACACAC] text-[10px] 2xl:text-xs font-light'> {item.currency || ""} <span>{item.price}</span></p>

          <p className=' text-xs 2xl:text-sm'>{item.difference||""}</p> */}

          {data?.customer_category_wise?.map((item, index) => (
              <div key={index} className="flex  justify-evenly p-2">
                <div>
          {/* <p className='text-xs 2xl:text-sm'>{` ${item.category || ""}`}</p> */}
          <p className='text-xs 2xl:text-sm'>
  {item.category ? `${item.category.charAt(0).toUpperCase() + item.category.slice(1).toLowerCase()}` : ""}
</p>
          <p className='text-[#048178] text-[10px] 2xl:text-xs text-start'>{item.difference||""}</p>
              </div>
              <div>
              <p className='text-[#333333] text-base  2xl:text-[22px] font-semibold text-center'>{`${item.count || 0}`}</p>
          <p className='text-[#ACACAC] text-[10px] 2xl:text-xs font-light'>  <NuAmount currency={item.currency || ""} value={item.price || 0}/></p>
          </div>
                </div>              
            ))}
          </div>
          </div>
          // </div>
        // <div className="w-full h-[90%]">
        //   <div className="w-full my-2">
        //     <TextMediumXS content={"Customers Category Wise :"} />
        //     {data?.customer_category_wise?.map((item, index) => (
        //       <div className='flex justify-evenly my-1' key={index}>
        //         <TextMediumXS align="center" content={`Category: ${item?.category || 0}`} />
        //         <TextMediumXS align="center" content={`Count: ${item?.count || 0}`} />
        //       </div>
        //     ))}
        //   </div>
        //   <div className="w-full">
        //     <div className="text-center flex gap-2 w-full my-2">
        //       <TextMediumXS content={"Growth Percentage Quotes :"} />
        //       <TextMediumXS content={data?.growth_percentage_quotes || 0} />
        //     </div>
        //     <div className="text-center flex gap-2 w-full my-2">
        //       <TextMediumXS content={"Growth Percentage Value"} />
        //       <TextMediumXS align="center" content={data?.growth_percentage_value || 0} />
        //     </div>
        //     <div className="text-center flex gap-2 w-full my-2">
        //       <TextMediumXS content={"Total Quotes :"} />
        //       <TextMediumXS align="center" content={data?.total_quotes || 0} />
        //     </div>
        //     <div className="text-center flex gap-2 w-full my-2">
        //       <TextMediumXS content={"Total Value :"} />
        //       <TextMediumXS align="center" content={data?.total_value || 0} />
        //     </div>
        //   </div>
        // </div>
      )}
    </div>
  );
}

export default QuotestoCustomersDashboard;
