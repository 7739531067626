import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useEffect, useState } from "react";
import Input, { NuDate, NuInput, NuSelect, NuTextArea, NuUpload } from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import axiosInstance from "../../../../Services/axiosCommon";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";

const CreateReceivables = () => {
  const currentDate = new Date().toISOString().split('T')[0];
  const {value:Category ,getData:refCategory} = useFetchMataData(`/dropdown/dropdown-details/`,'Category_Recevable')
  const {value:PaymentMethod ,getData:refPaymentMethod} = useFetchMataData(`/dropdown/dropdown-details/`,'PaymentMethod_Recevable')
  const {value:AccountType ,getData:refAccountType} = useFetchMataData(`/dropdown/dropdown-details/`,'AccountType__Recevable')
  const [loading, setLoading] = useState(false);


  const { Oncancel, addFormData, isLoading } = useAddFormData();
  const [createNew, setCreateNew] = useState({
    description: "",
    amount: "",
    category: "",
    accountType: "",
    receiveFrom: "",
    // daysElapsed: "",
    dueDate: currentDate || "",
    receivedAmount: "",
    // balanceAmount: "",
    paymentMethod: "",
    receivedOn: currentDate || "",
    comments: "",
    invoiceId: "",
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      setLoading(true); 
      addFormData("/receivable/new-receivable", value, resetForm);
      setLoading(false)
    },
  });
  const { data: invoiceOutgoing } = UseDropDown('/invoices-outgoing/drop-down');
  const { data: customer } = UseDropDown('/customers/drop-down');
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
       <Breadcrumb
         items={
          [
            { name: "Payment",
            link: "/payments/receivables" },  
          {
            name: "Receivables",
            link: "/payments/receivables"
          },
          {
            name: "Create Receivables"
          }]
         }
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full py-2 flex justify-start content-start flex-wrap  overflow-y-auto">
          <FormikSelectTwo
              label="Invoice"
              placeholder="Choose"
              Options={invoiceOutgoing}
              formik={formik}
              name="invoiceId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Amount"
              placeholder="amount"
              formik={formik}
              name="amount"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Category"
                placeholder="Choose"
                Options={Category}
                formik={formik}
                name="category"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refCategory}
                displayProperty={"Category"}
                propertyName={"Category_Recevable"}
              />
           
             <DropDownAddMetaData
                label="Account Type"
                placeholder="Choose"
                Options={AccountType}
                formik={formik}
                name="accountType"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refAccountType}
                displayProperty={"Account Type"}
                propertyName={"AccountType__Recevable"}
              />
          
            <FormikSelectTwo
              label="received From"
              placeholder="Choose"
              Options={customer}
              formik={formik}
              name="receiveFrom"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <NuInput
              type="number"
              label="Days Elapsed"
              placeholder="daysElapsed"
              formik={formik}
              name="daysElapsed"
               width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuDate
              label="Due Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="dueDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Received Amount"
              placeholder="receivedAmount"
              formik={formik}
              name="receivedAmount"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <NuInput
              type="number"
              label="Balance Amount"
              placeholder="Balance Amount"
              formik={formik}
              name="balanceAmount"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuInput
              type="text"
              label="Description"
              placeholder="description"
              formik={formik}
              name="description"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {formik?.values?.receivedAmount === "" ? 
            null 
            :
            <div className="w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">
               <DropDownAddMetaData
                label="Payment Method"
                placeholder="Choose"
                Options={PaymentMethod}
                formik={formik}
                name="paymentMethod"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refPaymentMethod}
                displayProperty={"Payment Method"}
                propertyName={"PaymentMethod_Recevable"}
              />
           
            <NuDate
              label="Received On"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="receivedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            </div>
            }
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{Oncancel(-1)}} />
                <SubmitButton isLoading={loading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateReceivables;
