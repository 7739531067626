import React, { useEffect, useState } from "react";
import {
  NormalHeading,
  SmallHeading,
} from "../../../../Components/Utils/Heading";
import { BackButton, EditButton } from "../../../../Components/Utils/Buttons";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import Modal from "../../../../Components/Utils/AttachmentView";
import Carousel from "./ImageSlider";

function ViewItems() {
  const { itemsViewId } = useSearchParams();
  const location = useLocation();
  const data = location.state.data;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [filePathList, setFilePathList] = useState([]);

  useEffect(() => {
    if (
      data?.productImage !== null &&
      data?.productImage !== "None" &&
      data?.productImage !== "[]"
    ) {
      setFilePathList(data?.productImage);
      // console.log('image',createNew?.attachments);
      // setRemoveFilePathList(data?.productImage);
      // console.log('images',filePathList);
    }
  }, [data]);

  function UpdateAttachmentList(item) {
    let filterList = filePathList.filter((itemList) => itemList.file !== item);
    console.log("llll", filterList);
    setFilePathList(filterList);
  }

  function handleImageClick(img) {
    setSelectedImage(img);
    setIsModalOpen(true);
  }
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            // { name: "Product" },

            { name: "Product", link: "/products/items" },

            { name: "View" },
          ]}
        />
        <div className=" flex gap-2">
          <Link to={"/products/items"}>
            <BackButton link={true} />
          </Link>
          <Link
            to={`/products/items/edit/${data.productId}`}
            state={{
              data: data,
              from: `/products/items/view/${data.productId}`,
            }}
          >
            <EditButton height="8" />
          </Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-145px)]  rounded-md mt-4 overflow-hidden overflow-y-auto pb-3">
        {/* <h2 className='text-base font-normal text-[#111537] p-3'>Cataglogues</h2> */}
       

        <div className="flex gap-4 w-full h-full">
          {/* <div className="w-full  flex "> */}
           {/* { data.productImage.length > 0 && (  */}
            {/* <div className="  ">  */}
              <div className="w-[60%]   bg-white rounded-lg drop-shadow-lg  overflow-y-auto  px-4  flex  flex-col">
              <div className="py-4">
              <NormalHeading title= {data?.productName || "-"} />
              </div>
              {/* <Carousel images={filePathList} onClick={handleImageClick} /> */}
              {/* <div className="odd:bg-white even:bg-slate-50 w-full flex px-2 ">
                <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                  Product Name
                </p>
                <p className="w-1/2 py-2 pl-5 text-xs  text-[#606279]">
                  {data?.productName || "-"}
                </p>
              </div> */}
              <div className="odd:bg-white even:bg-slate-50 w-full flex px-2 ">
                <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                  Item Code{" "}
                </p>
                <p className="w-1/2 py-2 pl-5 text-xs  text-[#606279]">
                  {data?.itemCode || "-"}
                </p>
              </div>
              <div className="odd:bg-white even:bg-slate-50 w-full flex px-2 ">
                <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                  Unit
                </p>
                <p className="w-1/2 py-2 pl-5 text-xs  text-[#606279]">
                  {data?.unit || "-"}
                </p>
              </div>
              <div className="odd:bg-white even:bg-slate-50 w-full flex px-2 ">
                <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">Category</p>
                <p className="w-1/2 py-2 pl-5 text-xs  text-[#606279]">{data?.category || "-"}</p>
              </div>
              <div className="odd:bg-white even:bg-slate-50 w-full flex px-2 ">
                <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                  Sub-Category
                </p>
                <p className="w-1/2 py-2 pl-5 text-xs  text-[#606279]">
                  {data?.subCategory || "-"}
                </p>
              </div>
              {/* <div className="odd:bg-white even:bg-slate-50 w-full flex px-2 ">
                <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                  Under Promotion?
                </p>
                <p className="w-1/2 py-2 pl-5 text-xs  text-[#606279]">
                  {data.isPromotable ? "Yes" : "No" || "-"}
                </p>
              </div> */}
              {/* {data?.isPromotable === true &&
              <>
              <div className="odd:bg-white even:bg-slate-50 w-full flex px-2 ">
                <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Supplier
                </p>
                <p className="w-1/2 py-2 pl-5 text-xs  text-[#606279]">
                  {data?.vendorName || "-"}
                </p>
              </div>
              </>
} */}

<div className="odd:bg-white even:bg-slate-50 w-full flex px-2 ">
                <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                  Brand
                </p>
                <p className="w-1/2 py-2 pl-5 text-xs  text-[#606279]">
                  {data?.brand || "-"}
                </p>
              </div>
              <div className="odd:bg-white even:bg-slate-50 w-full flex px-2 ">
                <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                  Manufacturer
                </p>
                <p className="w-1/2 py-2 pl-5 text-xs  text-[#606279]">
                  {data?.manufaturer || "-"}
                </p>
              </div>

              <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
                <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                  Comments
                </p>
                <p className="w-1/2 py-2 pl-5 text-xs  text-[#606279]">
                  {data?.comments || "-"}
                </p>
              </div>
              
            </div>
            {/* </div> */}
            {/* <div className="border-r border-[1px] border-slate-200/60 border-solid" /> */}

            <div className="py-4 bg-white rounded-lg drop-shadow-lg w-[40%]  px-4">
                  <SmallHeading title={"Product Image"} />
                  { data.productImage.length > 0 && (
              <Carousel images={filePathList} onClick={handleImageClick} />
                  )}
                  {/* <div className="md:flex justify-between mt-5 px-3 w-full">
                    <div className="w-full flex gap-2 items-center flex-wrap">
                      {data?.productImage &&
                        filePathList.map((imgs, idx) => (
                          <div
                            className="w-28 h-11 relative flex justify-center items-center rounded-lg"
                            key={idx}
                            style={{
                              border: "1px solid gray",
                              borderColor:
                                "rgb(148 163 184 / var(--tw-bg-opacity))",
                            }}
                          >
                            <div
                              className="w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                              onClick={() => handleImageClick(imgs)}
                            >
                              <img src={imgs?.file} alt={imgs?.file} />
                            </div>
                          </div>
                        ))}
                    </div>
                  </div> */}
                </div>
             
            {/* )} */}
            {/* {!data.productImage.length > 0 && (
              <div className="w-full rounded-[20px]  bg-white py-4  flex  flex-col  ">
                <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
                  <p className="w-1/4 py-2 text-xs 2xl:text-xs font-medium text-[#111537]">
                    Product Name
                  </p>
                  <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-xs  text-[#606279]">
                    {data?.productName || "-"}
                  </p>
                </div>
                <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
                  <p className="w-1/4 py-2 text-xs 2xl:text-xs font-medium text-[#111537]">
                    Category
                  </p>
                  <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-xs  text-[#606279]">
                    {data?.category || "-"}
                  </p>
                </div>
                <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
                  <p className="w-1/4 py-2 text-xs 2xl:text-xs font-medium text-[#111537]">
                    Sub-Category
                  </p>
                  <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-xs  text-[#606279]">
                    {data?.subCategory || "-"}
                  </p>
                </div>
                <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
                  <p className="w-1/4 py-2 text-xs 2xl:text-xs font-medium text-[#111537]">
                    Item Code{" "}
                  </p>
                  <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-xs  text-[#606279]">
                    {data?.itemCode || "-"}
                  </p>
                </div>
                <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
                  <p className="w-1/4 py-2 text-xs 2xl:text-xs font-medium text-[#111537]">
                    Under Promotion
                  </p>
                  <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-xs  text-[#606279]">
                    {data.isPromotable ? "Yes" : "No" || "-"}
                  </p>
                </div>
                <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
                  <p className="w-1/4 py-2 text-xs 2xl:text-xs font-medium text-[#111537]">
                    Unit
                  </p>
                  <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-xs  text-[#606279]">
                    {data?.unit || "-"}
                  </p>
                </div>
                <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
                  <p className="w-1/4 py-2 text-xs 2xl:text-xs font-medium text-[#111537]">
                    Manufacturer
                  </p>
                  <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-xs  text-[#606279]">
                    {data?.manufaturer || "-"}
                  </p>
                </div>
                <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
                  <p className="w-1/4 py-2 text-xs 2xl:text-xs font-medium text-[#111537]">
                    Brand
                  </p>
                  <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-xs  text-[#606279]">
                    {data?.brand || "-"}
                  </p>
                </div>
                <div className="odd:bg-white even:bg-slate-50 w-full flex px-2">
                  <p className="w-1/4 py-2 text-xs 2xl:text-xs font-medium text-[#111537]">
                    Comments
                  </p>
                  <p className="w-1/4 py-2 pl-5 text-xs 2xl:text-xs  text-[#606279]">
                    {data?.comments || "-"}
                  </p>
                </div>
              </div>
            )} */}

            {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div> */}
            {/* <div className="border-r border-[1px] border-slate-200/60 border-solid" />

            <div>
              <SmallHeading title={"Item Attachement"} />
              <div className="md:flex justify-between mt-5 px-3 w-full">
                <div className="w-full flex gap-2 items-center flex-wrap">
                  {data?.productImage &&
                    filePathList.map((imgs, idx) => {
                      console.log("img", imgs);
                      return (
                        <div
                          className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                          key={idx}
                          style={{
                            border: "1px solid gray",
                            borderColor:
                              "rgb(148 163 184 / var(--tw-bg-opacity))",
                          }}
                        >
                          <div
                            className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                            onClick={() => handleImageClick(imgs)}
                          >
                            <img src={imgs?.file} alt={imgs?.file} />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div> */}
          {/* </div> */}
        </div>
        {/* <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className="w-full h-[calc(100vh-100px)] overflow-y-scroll">
            <img src={selectedImage?.file} width={"100%"} alt={selectedImage?.file} />
          </div>
        </Modal> */}
         <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <img src={selectedImage?.file} alt={selectedImage?.file} />
      </Modal>
      </div>
    </div>
  );
}

export default ViewItems;
