import React, { useEffect, useMemo, useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import Dashboard from "../../../../Components/Icons/Dasboard";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import useEditFormData from "../../../../Components/Hooks/useEditFormData";
import * as yup from "yup";
import {
  NuDate,
  NuInput,
  NuMobileNumber,
  NuTextArea,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import axiosInstance from "../../../../Services/axiosCommon";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";

function EditInvoiceIncoming() {
  const { value: Tax } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Tax_invoicesIncoming"
  );
  const { value: ModeOfPayment } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "ModeOfPayment_invoicesIncoming"
  );
  const { value: Division } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Division_invoicesIncoming"
  );
  const { value: Status,getData:refStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Status_invoicesIncoming"
  );
  const {value:ApprovalStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'ApprovalStatus_incoming')
  const { data: PR } = UseDropDown('/PR/drop-down');

  const { data: PO } = UseDropDown("/PO/drop-down");

  const [image, setImage] = useState({ preview: "", raw: "" });
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  // console.log(location.state,'state');
  // const [division, setDivision] = useState([]);
  const [supplierName, setSupName] = useState(null);
  const [supplierEmail, setSupEmail] = useState(null);
  const { Oncancel, editFormData, contextHolder, isLoading } =
    useEditFormData();
  const { data: customer } = UseDropDown("/customers/drop-down");
  const [loading, setLoading] = useState(false);

  const [createNew, setCreateNew] = useState({
    date: data?.date,
    poId: data?.poId,
    amount: data?.amount,
    tax: String(data?.tax),
    totalAmount: data?.totalAmount,
    modeOfPayment: data?.modeOfPayment,
    paymentReleasedOn: data?.paymentReleasedOn,
    daysElapsed: data?.daysElapsed,
    comments: data?.comments,
    customerId: data?.customerId,
    division: data?.division,
    ref: data?.ref,
    paymentDueDate: data?.paymentDueDate,
    approvalStatus: data?.approvalStatus || "",
    status: data?.status,
    taxAmount:data?.taxAmount,
    prId:data?.prId
  });
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
    setCreateNew((prevState) => ({ ...createNew, image: "" }));
    formik.values.image = "";
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      poId: yup.string().required('Purchase Order is requred!'),
      ref: yup.string().required('Invoice Reference is requred!')
    }),
    onSubmit: async (value, { resetForm }) => {
      setLoading(true); 
      editFormData(
        `/invoices-incoming/edit-invoice/${data.invoiceInId}`,
        value,
        resetForm,
        from
      );
      setLoading(false)
    },
  });

  useMemo(() => {
    if (formik.values.tax != 0 || formik.values.tax != "") {
      let finalAmount = (formik.values.amount / 100) * formik.values.tax;
      formik.values.totalAmount = formik.values.amount + finalAmount;
    } else {
      formik.values.totalAmount = formik.values.amount;
    }
  }, [formik.values.amount, formik.values.tax]);

  const [polist, setPolist] = useState([]);

  const getPo = (poId) => {
    if (poId !== "") {
      axiosInstance
        .get(`/PO/po-products/${poId}`)
        .then((res) => {
          let data = res.data.data;
          let name = res.data?.supplierName;
          let email = res.data?.supplierEmail;
          setPolist(data);
          name && setSupName(name);
          email && setSupEmail(email);
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };

  useEffect(() => {
    if (formik.values.poId) {
      getPo(formik.values.poId);
    }
  }, [formik.values.poId]);

  useEffect(() => {
    const amount = parseFloat(formik.values.amount || 0);
    const tax = parseFloat(formik.values.tax || 0);
    
    const taxAmount = ((amount)* tax/100);  
    
    formik.setFieldValue("taxAmount", taxAmount.toFixed(2));
    formik.setFieldValue("totalAmount", (amount + taxAmount).toFixed(2));
  }, [formik.values.amount, formik.values.tax]);
  


  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            {
              name: "Invoice-Incoming",
              link: "/sales/invoice_incoming",
            },
            {
              name: "Edit",
            },
          ]}
        />

        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
          <FormikSelectTwo
                type="text"
                label="Purchase Request"
                placeholder="Purchase Request "
                Options={PR}
                formik={formik}
                name="prId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
                disabled={true}
              />
            <FormikSelectTwo
              type="text"
              label="Purchase Order"
              placeholder="Purchase Order"
              Options={PO}
              formik={formik}
              name="poId"
              disabled={true}
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            {/* <FormikSelectTwo
              type="text"
              label="Customer"
              placeholder="Customer"
              Options={customer}
              formik={formik}
              name="customerId"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuInput
              type="text"
              label="Invoice Reference "
              placeholder=" Invoice Reference"
              formik={formik}
              name="ref"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <NuDate
              label="Invoice Date"
              placeholder="Invoice Date"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <NuDate
              label="Received Date"
              placeholder=" Received Date"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuInput
              type="number"
              label="Amount"
              placeholder=" Amount"
              formik={formik}
              name="amount"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              type="text"
              label="Tax (%)"
              placeholder="Choose"
              Options={Tax}
              formik={formik}
              name="tax"
              width="w-full md:w-1/2 lg:w-1/3"
            />
<NuInput
  type="text"
  label="Tax Amount"
  placeholder="Tax Amount"
  formik={formik}
  name="taxAmount"
  width="w-full md:w-1/2 lg:w-1/3"
  disabled={true}
/>
            <NuInput
              type="number"
              label="Total Amount"
              placeholder="Total Amount"
              formik={formik}
              name="totalAmount"
              disabled={true}
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <NuDate
              label="Payment DueDate"
              placeholder="Payment DueDate"
              formik={formik}
              name="paymentDueDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Payment ReleasedOn"
              placeholder="Payment ReleasedOn"
              formik={formik}
              name="paymentReleasedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Mode Of Payment"
              placeholder="Mode Of Payment"
              formik={formik}
              Options={ModeOfPayment}
              name="modeOfPayment"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            {/* <FormikSelectTwo
              type="text"
              label="Division"
              placeholder="Choose"
              Options={Division}
              formik={formik}
              name="division"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
  {/* <FormikSelectTwo
            type="text"
            label="Approval Status"
            placeholder="Approval Status"
            Options={ApprovalStatus}
           formik={formik}
           name="approvalStatus"
           width="w-full md:w-1/2 lg:w-1/3"
          /> */}
           <DropDownAddMetaData
                label="Status"
                placeholder="Choose"
                Options={Status}
                formik={formik}
                name="status"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refStatus}
                displayProperty={"Status"}
                propertyName={"Status_invoicesIncoming"}
              />
            {/* <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Choose"
              Options={Status}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <div className="w-full md:w-1/2 lg:w-1/3 flex px-6"/>
            <div className="w-full md:w-1/2 lg:w-1/3 flex px-6">
              <p className="w-1/2 py-3 text-sm font-medium text-[#111537]">
                Supplier Name
              </p>
              <p className="w-1/2 py-3 pl-5  text-sm text-[#606279]">
                {supplierName}
              </p>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 flex px-6">
              <p className="w-1/2 py-3 text-sm font-medium text-[#111537]">
                Supplier Email
              </p>
              <p className="w-1/2 py-3 pl-5  text-sm text-[#606279]">
                {supplierEmail}
              </p>
            </div>
            <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
            <div className="w-full bg-white overflow-y-scroll mt-5">
              <div className="w-full py-3 bg-[#048178] flex justify-between rounded-lg px-6">
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

                <p className="w-[40%] text-left text-white text-xs font-medium">
                  Product
                </p>
                <p className="w-[10%] text-center text-white text-xs font-medium">
                  Unit
                </p>
                <p className="w-[20%] text-center text-white text-xs font-medium">
                  Ordered Qty
                </p>
                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Price
                </p>
                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Total
                </p>
              </div>
              {polist?.length>0?
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">

              {polist.map((List, index) => (
                <div
                  key={index}
                  className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-50"
                >
                   <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                  <p className="w-[40%] text-left text-[#606279] text-xs font-light">
                    {List?.product_name}
                  </p>
                  <p className="w-[10%] text-center text-[#606279] text-xs font-light">
                    {List?.product_unit}
                  </p>
                  <p className="w-[20%] text-center text-[#606279] text-xs font-light">
                    {List?.orderedQty}
                  </p>
                  <p className="w-[15%] text-center text-[#606279] text-xs font-light">
                    {List?.price}
                  </p>
                  <p className=" w-[15%] text-center text-[#606279] text-xs font-light">
                  {(List.price * List.orderedQty ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
                  
                  </p>
                </div>
              ))}
            </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

            </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={loading}/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditInvoiceIncoming;
